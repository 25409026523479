<template>
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.2997 8.14644C14.7434 8.14743 14.2011 8.32077 13.7475 8.64263C13.2938 8.96448 12.951 9.41902 12.7662 9.94371C10.0708 9.93111 8.03234 9.82761 6.71296 9.14632C5.94474 8.77211 5.27272 8.22645 4.74874 7.55142C4.22476 6.8764 3.86282 6.09004 3.69083 5.253C4.28218 5.02259 4.77378 4.59172 5.07975 4.03569C5.38572 3.47965 5.4866 2.83379 5.36478 2.21094C5.24295 1.58808 4.90616 1.02782 4.41325 0.628043C3.92033 0.228261 3.30262 0.0143691 2.66803 0.0237324C2.03345 0.0330957 1.42232 0.265119 0.941411 0.679269C0.460505 1.09342 0.140393 1.66337 0.0369978 2.28955C-0.0663973 2.91573 0.0534972 3.55833 0.375737 4.1051C0.697976 4.65186 1.20207 5.06803 1.79997 5.2809V13.7119C1.19949 13.9242 0.693392 14.342 0.371129 14.8913C0.0488668 15.4407 -0.0688133 16.0863 0.0388887 16.714C0.146591 17.3418 0.47274 17.9112 0.959691 18.3217C1.44664 18.7322 2.06304 18.9574 2.69995 18.9574C3.33685 18.9574 3.95325 18.7322 4.44021 18.3217C4.92716 17.9112 5.25331 17.3418 5.36101 16.714C5.46871 16.0863 5.35103 15.4407 5.02877 14.8913C4.70651 14.342 4.20041 13.9242 3.59993 13.7119V8.98073C4.24463 9.70763 5.02042 10.3067 5.88678 10.7465C7.54905 11.6033 9.8107 11.7293 12.7644 11.7437C12.9298 12.2094 13.22 12.6207 13.6033 12.9326C13.9867 13.2446 14.4484 13.4451 14.9381 13.5123C15.4277 13.5795 15.9264 13.5108 16.3796 13.3137C16.8328 13.1166 17.2231 12.7986 17.5078 12.3947C17.7925 11.9907 17.9607 11.5162 17.9939 11.0231C18.0271 10.53 17.9242 10.0373 17.6962 9.59876C17.4683 9.16024 17.1242 8.79283 16.7015 8.53669C16.2789 8.28055 15.7939 8.14555 15.2997 8.14644Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "mergeBlockIcon"
    }
</script>
<style scoped></style>