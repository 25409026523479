<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 7H15V5H21V7ZM5 21V5C5 4.45 5.196 3.979 5.588 3.587C5.97933 3.19567 6.45 3 7 3H13V5H7V17.95L12 15.8L17 17.95V11H19V21L12 18L5 21ZM7 5H13H12H7Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "tagRemovedIcon"
    }
</script>
<style scoped></style>