<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.64233 1.97315L6.9574 1.96975C7.4157 1.96734 7.85976 2.14931 8.18225 2.47168L14.4987 8.78772C15.1671 9.45606 15.1671 10.5434 14.4987 11.2117L10.2423 15.4678C9.91853 15.7916 9.48809 15.9697 9.03032 15.9697C8.57256 15.9697 8.14212 15.7914 7.81831 15.4678L7.14181 14.7913C7.01895 14.6685 7.01895 14.4693 7.14181 14.3464C7.26445 14.2238 7.46388 14.2238 7.58652 14.3464L8.26301 15.0231C8.46792 15.228 8.7404 15.3409 9.0301 15.3409C9.31979 15.3409 9.59249 15.228 9.79741 15.0231L14.0541 10.7667C14.259 10.5619 14.3718 10.2894 14.3718 9.99949C14.3718 9.7097 14.2591 9.43733 14.0541 9.23246L7.7376 2.91641C7.53346 2.71228 7.25296 2.59153 6.96007 2.59866L2.64275 2.60195C2.08869 2.60228 1.63708 3.05353 1.63618 3.60755L1.62894 7.9316C1.62675 8.22029 1.74259 8.50286 1.94672 8.70697L2.56913 9.32934C2.69199 9.45219 2.69199 9.65139 2.56913 9.77424C2.44649 9.89687 2.24705 9.89687 2.12442 9.77424L1.50201 9.15187C1.17952 8.8294 0.996539 8.38296 1.00005 7.92873L1.00696 3.60665C1.0086 2.70655 1.74214 1.97374 2.64217 1.97318L2.64233 1.97315Z" fill="white"/>
    <path d="M3 5.21973C3 4.53039 3.56086 3.96973 4.25 3.96973C4.93924 3.96973 5.5 4.53039 5.5 5.21973C5.5 5.90906 4.93914 6.46973 4.25 6.46973C3.56086 6.46973 3 5.90906 3 5.21973ZM4.94447 5.21973C4.94447 4.83682 4.6329 4.52525 4.25 4.52525C3.8671 4.52525 3.55553 4.83682 3.55553 5.21973C3.55553 5.60263 3.8671 5.9142 4.25 5.9142C4.63298 5.9142 4.94447 5.60263 4.94447 5.21973Z" fill="white"/>
    <path d="M7 12.7197C7 14.2362 5.76633 15.4697 4.25 15.4697C2.73355 15.4697 1.5 14.2361 1.5 12.7197C1.5 11.2033 2.73367 9.96973 4.25 9.96973C5.76644 9.96973 7 11.2033 7 12.7197ZM4.25 10.6167C3.09045 10.6167 2.14701 11.5602 2.14701 12.7197C2.14701 13.8793 3.09045 14.8227 4.25 14.8227C5.40955 14.8227 6.35299 13.8793 6.35299 12.7197C6.35299 11.5602 5.40955 10.6167 4.25 10.6167Z" fill="white"/>
    <path d="M5.22224 12.9975H4.52776V13.692C4.52776 13.8453 4.40327 13.9697 4.25 13.9697C4.09663 13.9697 3.97224 13.8452 3.97224 13.692V12.9975H3.27776C3.1244 12.9975 3 12.873 3 12.7197C3 12.5664 3.1245 12.442 3.27776 12.442H3.97224V11.7475C3.97224 11.5941 4.09673 11.4697 4.25 11.4697C4.40337 11.4697 4.52776 11.5942 4.52776 11.7475V12.442H5.22224C5.37561 12.442 5.5 12.5665 5.5 12.7197C5.5001 12.873 5.3756 12.9975 5.22224 12.9975Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        name: "tagAssignedBoxIcon"
    }
</script>
<style scoped></style>