<template>
    <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 21C9.182 21 8.4 20.67 6.837 20.012C2.946 18.373 1 17.554 1 16.175V6.542M10 21C10.818 21 11.6 20.67 13.163 20.012C17.054 18.373 19 17.554 19 16.175V6.542M10 21V11.03M1 6.542C1 7.155 1.802 7.542 3.405 8.315L6.325 9.725C8.129 10.595 9.03 11.03 10 11.03M1 6.542C1 5.93 1.802 5.543 3.405 4.77L5 4M19 6.542C19 7.155 18.198 7.542 16.595 8.315L13.675 9.725C11.871 10.595 10.97 11.03 10 11.03M19 6.542C19 5.93 18.198 5.543 16.595 4.77L15 4M4 12.026L6 13.023M8 1L10 3M10 3L12 5M10 3L8 5M10 3L12 1" stroke="#6C707D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
</template>
<script>
    export default {
        name: "lowStockDropIcon"
    }
</script>
<style scoped></style>