<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'triggerBoxIcon'"></component>
      </span>
      <span class="me-auto">Price Drop</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p v-html="stateMessage"></p>
    </div>
    <teleport to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            Price Drop
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <div class="pb-14">
              <p class="font-12 l-height-24 fw-medium m-0">Price Drop Amount</p>
              <dropdownlist
                :class="'h-38 drop-icon-2 mb-0'"
                :style="{ width: '100%' }"
                :data-items="amountStatus"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="priceDropConfiguration?.amountType"
                @change="handleDropdownChange($event.value, 'amountType')"
              >
              </dropdownlist>

              <div
                v-if="priceDropConfiguration?.amountType.id == 1"
                class="d-flex justify-content-between price-input-with-toggle mt-10"
              >
                <span class="d-flex piwt-price w-100--85">
                  <k-input
                    v-model="priceDropConfiguration.customAmount"
                    type="number"
                    onkeydown="return event.keyCode !== 69"
                    :class="'w-100'"
                  />
                  <span class="piwt-price-info">
                    {{
                      priceDropConfiguration.customAmountType == "dollar"
                        ? "$"
                        : "%"
                    }}
                  </span>
                </span>

                <div class="f-flex piwt-toggle w-88 text-right">
                  <kbutton
                    :class="'h-38 w-38 font-16 p-0'"
                    :theme-color="
                      priceDropConfiguration.customAmountType == 'dollar'
                        ? 'primary'
                        : 'secondary'
                    "
                    @click="handleTabChange('dollar')"
                  >
                    $
                  </kbutton>
                  <kbutton
                    :class="'h-38 w-38 font-16 p-0'"
                    :theme-color="
                      priceDropConfiguration.customAmountType == 'percentage'
                        ? 'primary'
                        : 'secondary'
                    "
                    @click="handleTabChange('percentage')"
                  >
                    %
                  </kbutton>
                </div>
              </div>
            </div>
            <div class="border-bottom-normal-1 mx--15"></div>
            <div class="pt-10 pb-14">
              <p class="font-12 l-height-24 fw-medium m-0">Select Products</p>
              <dropdownlist
                :class="'h-38 drop-icon-2 mb-10'"
                :style="{ width: '100%' }"
                :data-items="productStatus"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="priceDropConfiguration.selectProductType"
                @change="
                  handleDropdownChange($event.value, 'selectProductType')
                "
              >
              </dropdownlist>
              <div v-if="priceDropConfiguration.selectProductType?.id == 1">
                <div
                  v-if="this.priceDropConfiguration.productsList?.length"
                  class="product-selected position-relative mb-10"
                >
                  <div>
                    {{ this.priceDropConfiguration.productsList?.length }}
                    Products selected
                  </div>
                  <kbutton
                    :theme-color="'primary'"
                    :fill-mode="'outline'"
                    :class="'border-none p-0 h-14 w-14'"
                    @click="resetProductSelecetion()"
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0 7C0 3.13437 3.13437 0 7 0C10.8656 0 14 3.13437 14 7C14 10.8656 10.8656 14 7 14C3.13437 14 0 10.8656 0 7ZM9.58438 11L11 9.58438L8.41562 7L11 4.41563L9.58438 3L7 5.58437L4.41563 3L3 4.41563L5.58437 7L3 9.58438L4.41563 11L7 8.41562L9.58438 11Z"
                        fill="#24243A"
                      />
                    </svg>
                  </kbutton>
                </div>

                <kbutton
                  :theme-color="'primary'"
                  :fill-mode="'outline'"
                  :class="'w-100 radius-4'"
                  @click="addProductModalVisibilityHandler(true)"
                >
                  {{
                    this.priceDropConfiguration.productsList?.length
                      ? "Add More Products"
                      : "Add Products"
                  }}
                </kbutton>
              </div>
            </div>
            <div class="border-bottom-normal-1 mx--15"></div>
            <div class="py-16">
              <p class="font-13 l-height-24 font-w-500 mx-0 mt-0 mb-10">
                Target customers who interacted
              </p>
              <div class="d-flex justify-content-between mb-14">
                <radiogroup
                  :data-items="dataItems"
                  v-model="priceDropConfiguration.targetCustomersType"
                />
              </div>
              <div
                class="d-flex justify-content-start align-items-center gap-2 pl-21"
              >
                <p class="font-14 m-0">For</p>
                <k-input
                  type="number"
                  onkeydown="return event.keyCode !== 69"
                  placeholder="30"
                  aria-required="true"
                  :class="'w-50 h-30 m-0 radius-4'"
                  v-model="priceDropConfiguration.targetCustomersTimePeriod"
                >
                </k-input>
                <p class="font-14 m-0">Days period</p>
              </div>
            </div>
            <div class="border-top-normal-1"></div>
            <div class="mb-4">
              <div class="py-14">
                <div class="d-flex">
                  <div class="w-42">
                    <k-switch
                      :class="'w-34 h-18 switch-34-18'"
                      :size="'small'"
                      v-model="
                        priceDropConfiguration.exlude_customers_who_purchased
                      "
                    />
                  </div>
                  <p class="font-14 font-w-500 m-0">
                    Exclude customers who purchased
                  </p>
                </div>
                <div
                  v-if="priceDropConfiguration.exlude_customers_who_purchased"
                  class="d-flex justify-content-start align-items-center gap-2 mt-10 mb-2px"
                >
                  <p class="font-14 m-0">In last</p>
                  <k-input
                    type="number"
                    onkeydown="return event.keyCode !== 69"
                    placeholder="30"
                    aria-required="true"
                    :class="'w-50 h-30 m-0 radius-4'"
                    v-model="priceDropConfiguration.exclusdeTimeInDays"
                  >
                  </k-input>
                  <p class="font-14 m-0">Days</p>
                </div>
              </div>
              <div class="border-bottom-normal-1"></div>
              <div class="d-flex py-14">
                <div class="w-42">
                  <k-switch
                    :class="'w-34 h-18 switch-34-18'"
                    :size="'small'"
                    v-model="
                      priceDropConfiguration.excluse_out_of_stock_products
                    "
                  />
                </div>
                <p class="font-14 font-w-500 m-0">
                  Exclude out-of-stock products
                </p>
              </div>
              <div class="border-bottom-normal-1 mx--15"></div>
            </div>
            <div>
              <configureFlowFilterComponent
                :onConfirm="onConfirmFlowFilter"
                :additionalFilterData="additionalFilterData"
              />
            </div>
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <!-- <kbutton @click="drawerClose" class="ms-2">Cancel</kbutton> -->
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler"
              class="ms-2"
              theme-color="primary"
              >Apply Trigger
            </kbutton>
          </div>
        </div>
      </div>
    </teleport>
  </div>
  <teleport to="#teleportContainer">
    <browseProductModal
      v-if="addProductModalVisibility"
      @addStaticProducts="addStaticProducts"
      @close="addProductModalVisibilityHandler(false)"
      :widgetElement="this.priceDropConfiguration"
    />
  </teleport>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button, DropDownButton } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import triggerBoxIcon from "@/components/svg-icons/triggerBoxIcon.vue";
import configureFlowFilterComponent from "../../common/configureFlowFilterComponent.vue";
import { useStore } from "vuex";
import { filterBy } from "@progress/kendo-data-query";
import NoDataRender from "@/components/common/NoDataRender/NoDataRender.vue";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import { Switch } from "@progress/kendo-vue-inputs";
import browseProductModal from "@/components/emailTemplateEditor/widgets/product/browseProductModal/browseProductModal.vue";

export default {
  components: {
    "k-input": Input,
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    triggerBoxIcon,
    configureFlowFilterComponent,
    custom: NoDataRender,
    multiselect: MultiSelect,
    radiogroup: RadioGroup,
    "k-switch": Switch,
    DropDownButton,
    browseProductModal,
  },
  data() {
    return {
      addProductModalVisibility: false,
      priceDropConfiguration: {
        amountType: { text: "Any Amount", id: 0 },
        customAmount: 0,
        customAmountType: "dollar",
        selectProductType: { text: "Any Product", id: 0 },

        productsList: [],
        productIds: [],

        targetCustomersType: "viewed_the_product",
        targetCustomersTimePeriod: 1,

        exlude_customers_who_purchased: false,
        exclusdeTimeInDays: 1,
        excluse_out_of_stock_products: false,
      },

      dataItems: [
        {
          label: "Viewed the Product",
          value: "viewed_the_product",
        },
        {
          label: "Abandoned Cart",
          value: "abandoned_cart",
        },
      ],
      amountStatus: [
        { text: "Any Amount", id: 0 },
        { text: "Custom", id: 1 },
      ],
      productStatus: [
        { text: "Any Product", id: 0 },
        { text: "Specific Product", id: 1 },
      ],

      selectedTriggerValue: {},
      additionalFilterData: null,
    };
  },
  methods: {
    resetProductSelecetion() {
      this.priceDropConfiguration.productsList = [];
      this.priceDropConfiguration.productIds = [];
    },
    addStaticProducts(productsList, productIds) {
      this.priceDropConfiguration.productsList = productsList;
      this.priceDropConfiguration.productIds = productIds;
    },
    addProductModalVisibilityHandler(value) {
      this.addProductModalVisibility = value;
    },
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    onConfirmFlowFilter(data) {
      this.additionalFilterData = data;
      this.updateDataHandler();
    },
    handleTabChange(tab) {
      this.priceDropConfiguration.customAmountType = tab;
    },
    handleDropdownChange(value, field) {
      this.priceDropConfiguration[field] = value;
      if (field === "amountType" && value.text === "Any Amount") {
        this.priceDropConfiguration.customAmount = 0;
        this.priceDropConfiguration.customAmountType = null;
      }
    },
    onConfirmDrawerHandler() {
      this.updateDataHandler();
      this.drawerCloseHandler();
    },
    updateDataHandler() {
      const payload = {
        id: this.nodeId,
        selectedTriggerValue: { id: 1, text: "Price drop" },
        priceDropConfiguration: this.priceDropConfiguration,
        additionalFilterData: this.additionalFilterData,
      };
      this.updateNodeData(payload);
    },
    beforeCloseHandler() {
      this.onConfirmDrawerHandler();
    },
    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        this.selectedTriggerValue = nodeData.selectedTriggerValue;
        this.additionalFilterData = nodeData.additionalFilterData;
        this.priceDropConfiguration = nodeData.priceDropConfiguration;
      }
    },
  },
  computed: {
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
    stateMessage() {
      const config = this.priceDropConfiguration;
      let stateDropType = "";
      if (config.amountType.text === "Custom") {
        stateDropType =
          config.customAmountType === "dollar"
            ? `$${config.customAmount}.`
            : `${config.customAmount}%.`;
      } else if (config.amountType.text === "Any Amount") {
        stateDropType = "Any Amount.";
      }
      let stateProductType =
        config.selectProductType.text === "Specific Product"
          ? `<strong>Specific Product</strong> drops by <strong>${stateDropType}</strong>`
          : `<strong>Any Product </strong> drops by <strong>${stateDropType}</strong>`;

      return `Triggered when the price of ${stateProductType}`;
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  mounted() {
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
      this.updateDataHandler();
    }, 100);
  },

  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
