<template>
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8C0 6.53333 0.296 5.146 0.888 3.838C1.47933 2.52933 2.325 1.4 3.425 0.45L4.85 1.85C3.95 2.63333 3.25 3.554 2.75 4.612C2.25 5.67067 2 6.8 2 8H0ZM18 8C18 6.8 17.75 5.67067 17.25 4.612C16.75 3.554 16.05 2.63333 15.15 1.85L16.575 0.45C17.675 1.4 18.521 2.52933 19.113 3.838C19.7043 5.146 20 6.53333 20 8H18ZM2 17V15H4V8C4 6.61667 4.41667 5.38733 5.25 4.312C6.08333 3.23733 7.16667 2.53333 8.5 2.2V1.5C8.5 1.08333 8.646 0.729333 8.938 0.438C9.22933 0.146 9.58333 0 10 0C10.4167 0 10.7707 0.146 11.062 0.438C11.354 0.729333 11.5 1.08333 11.5 1.5V2.2C12.8333 2.53333 13.9167 3.23733 14.75 4.312C15.5833 5.38733 16 6.61667 16 8V15H18V17H2ZM10 20C9.45 20 8.97933 19.8043 8.588 19.413C8.196 19.021 8 18.55 8 18H12C12 18.55 11.8043 19.021 11.413 19.413C11.021 19.8043 10.55 20 10 20ZM6 15H14V8C14 6.9 13.6083 5.95833 12.825 5.175C12.0417 4.39167 11.1 4 10 4C8.9 4 7.95833 4.39167 7.175 5.175C6.39167 5.95833 6 6.9 6 8V15Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        name: "actionAlertBoxIcon"
    }
</script>
<style scoped></style>