 const fontList = [
  {
    text: "Arial",
    value: "Arial, 'Helvetica Neue', Helvetica, sans-serif",
  },
  {
    text: "Arial Black",
    value: "'Arial Black', 'Arial Bold', Gadget, sans-serif",
  },
  {
    text: "Comic Sans MS",
    value: "'Comic Sans MS', 'Comic Sans', cursive",
  },
  {
    text: "Courier",
    value: "courier, monospace",
  },
  {
    text: "Courier New",
    value: "courier new, courier, monospace",
  },
  {
    text: "Georgia",
    value: "Georgia, Times, 'Times New Roman', serif",
  },
  {
    text: "Geneva",
    value: "Geneva, Tahoma, Verdana, sans-serif",
  },
  {
    text: "Helvetica",
    value: "helvetica, arial, sans-serif",
  },
  {
    text: "Lucida",
    value:
      "Lucida, 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, sans-serif",
  },
  {
    text: "Lucida Grande",
    value:
      "'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Geneva, Verdana, sans-serif",
  },
  {
    text: "Lucida Sans",
    value: "'Lucida Sans', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
  },
  {
    text: "MS Serif",
    value: "serif",
  },
  {
    text: "New York",
    value:
      "'New York', TimesNewRoman, 'Times New Roman', Times, Baskerville, Georgia, serif",
  },
  {
    text: "Palatino",
    value:
      "Palatino, 'Palatino Linotype', 'Palatino LT STD', 'Book Antiqua', Georgia, serif",
  },
  {
    text: "Palatino Linotype",
    value:
      "'Palatino Linotype', 'Palatino LT STD', 'Book Antiqua', Georgia, serif",
  },
  {
    text: "Tahoma",
    value: "Tahoma, Verdana, Segoe, sans-serif",
  },
  {
    text: "Times New Roman",
    value: "Times New Roman, Times Roman,	TimesNR, times, serif",
  },
  {
    text: "Trebuchet MS",
    value:
      "'Trebuchet MS', 'Lucida Grande', 'Lucida Sans Unicode', 'Lucida Sans', Tahoma, sans-serif",
  },
  {
    text: "Verdana",
    value: "Verdana, Geneva, sans-serif",
  },

  {
    text: "Redbird",
    value: "Redbird, 'Arial'",
  },
  {
    text: "Didot",
    value: "GFS Didot, 'Arial'",
  },
  {
    text: "Oswald",
    value: "Oswald, 'Arial'",
  },
  {
    text: "Montserrat",
    value: "Montserrat, 'Arial'",
  },
];

export default fontList;
