<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 13L4 8V18H13V20H4C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V13H20V8L12 13ZM12 11L20 6H4L12 11ZM19 23L17.6 21.6L19.175 20H15V18H19.175L17.575 16.4L19 15L23 19L19 23ZM4 8V19V13V13.075V6V8Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "emailActionIcon"
    }
</script>
<style scoped></style>