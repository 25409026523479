<template>
  <div ref="el" class="">
    <AppLoader v-if="isLoading" />
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'profileUpdateBoxIcon'"></component>
      </span>
      <span class="me-auto">Profile Update</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p class="mb-2" v-html="formattedMessages"></p>
      <nodeStatusDropdown
        :actionStatus="actionStatus"
        :onConfirm="onConfirmActionStatus"
      />
    </div>

    <teleport to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            Profile Update
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <div class="mb-24">
              <p class="font-14 l-height-21 mb-20">
                Add, update, or remove properties for a profile when they enter
                this stage in the flow.
              </p>
              <div
                class="profile-step-wrap position-relative mb-20 px-12 pb-6 pt-16 pb-6 border-normal-1 bg-gray-6 radius-8"
                v-for="(step, index) in profileUpdateSteps"
                :key="index"
              >
                <kbutton
                  :class="'h-24 w-24 p-0 border-normal-1 radius-100 position-absolute close-step'"
                  :theme-color="'primary'"
                  :fill-mode="'outline'"
                  @click="profileUpdateSteps.splice(index, 1)"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="11.5"
                      fill="white"
                    />
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="11.5"
                      stroke="#ADB2BD"
                    />
                    <path
                      d="M17 8.41562L13.4156 12L17 15.5844L15.5844 17L12 13.4156L8.41562 17L7 15.5844L10.5844 12L7 8.41562L8.41562 7L12 10.5844L15.5844 7L17 8.41562Z"
                      fill="#24243A"
                    />
                  </svg>
                </kbutton>
                <AppDropdownList
                  :searchable="false"
                  :data-items="dropdownListData"
                  :textField="'text'"
                  :dataItemKey="'id'"
                  v-model="step.actionType"
                  :class="'mb-6 w-100 h-38'"
                  @updateDropdownValue="
                    (value) => handleDropdownChange(value, index, 'actionType')
                  "
                />
                <k-input
                  :type="'text'"
                  :class="'mb-6 w-100 h-38'"
                  placeholder="Field Label"
                  v-if="[2]?.includes(step.actionType?.id)"
                  v-model="step.fieldLabel"
                ></k-input>
                <AppDropdownList
                  v-if="[1, 2, 3]?.includes(step.actionType?.id)"
                  :searchable="true"
                  :data-items="
                    generateProfileUpdateFields(
                      index,
                      step?.actionType?.id == 1 ? 'IsProfileUdpate' : 'IsProfileDelete'
                    )
                  "
                  :textField="'alterText'"
                  :dataItemKey="'id'"
                  v-model="step.fieldType"
                  :class="'mb-6 w-100 h-38'"
                  @updateDropdownValue="
                    (value) => handleDropdownChange(value, index, 'fieldType')
                  "
                />
                <k-input
                  :type="step.fieldType?.fieldType == 1 ? 'text' : 'number'"
                  :class="'mb-6 w-100 h-38'"
                  placeholder="Property Value"
                  v-if="
                    [1, 2]?.includes(step.actionType?.id) &&
                    step.fieldType.id != 0 &&
                    [1, 3]?.includes(step.fieldType.fieldType)
                  "
                  v-model="step.value"
                ></k-input>
                <template
                  v-if="
                    [1, 2]?.includes(step.actionType?.id) &&
                    step.fieldType.id != 0 &&
                    [4]?.includes(step.fieldType.fieldType)
                  "
                >
                  <DatePicker
                    :class="'mb-6 w-100 h-38'"
                    :value="step.value ? new Date(step.value) : step.value"
                    @change="($event) => (step.value = $event.value)"
                    :disabled="step?.applyCurrentDate"
                  ></DatePicker>
                  <Checkbox
                    v-model="step.applyCurrentDate"
                    :class="'font-12 cursor-pointer d-flex align-items-start pt-2 profile-update-date-checkbox'"
                    :label="'Apply the current date when this step executed'"
                  />
                </template>
                <AppDropdownList
                  v-if="
                    [1, 2]?.includes(step.actionType?.id) &&
                    step.fieldType.id != 0 &&
                    [2]?.includes(step.fieldType.fieldType) &&
                    step?.valueDropdown?.length > 0
                  "
                  :searchable="true"
                  :data-items="step.valueDropdown"
                  :textField="'label'"
                  :dataItemKey="'id'"
                  v-model="step.value"
                  :class="'mb-6 w-100 h-38 drop-icon-2'"
                  :defaultText="'Select Value'"
                  @updateDropdownValue="
                    (value) => handleDropdownChange(value, index, 'value')
                  "
                />
              </div>
              <kbutton
                :class="'mb-6 h-38'"
                :theme-color="'primary'"
                :fill-mode="'outline'"
                @click="addStep"
                >Add Step
              </kbutton>
            </div>
            <additionalFilter
              :onConfirm="onConfirmAdditionalFilter"
              :additionalFilterData="additionalFilterData"
            />
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <!-- <kbutton @click="drawerClose" class="ms-2">Cancel</kbutton> -->
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler"
              class="ms-2"
              theme-color="primary"
              >Done</kbutton
            >
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { Checkbox, Input } from "@progress/kendo-vue-inputs";
import additionalFilter from "@/components/workflowAutomation/common/additionalFilter.vue";
import nodeStatusDropdown from "../../common/nodeStatusDropdown.vue";
import profileUpdateBoxIcon from "@/components/svg-icons/profileUpdateBoxIcon.vue";
import { useStore } from "vuex";
import AppDropdownList from "@/components/common/AppDropdownList/AppDropdownList.vue";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { getSegmentDropdownList } from "@/services/clubDetails/segments.service";
import AppLoader from "@/components/common/AppLoader/AppLoader.vue";

export default {
  components: {
    kbutton: Button,
    "k-input": Input,
    additionalFilter,
    nodeStatusDropdown,
    profileUpdateBoxIcon,
    AppDropdownList,
    DatePicker,
    AppLoader,
    Checkbox,
  },
  data() {
    return {
      listNoDataRender: "noDataFoundTemplate",
      additionalFilterData: null,
      optionsModalVisibility: false,
      isLoading: false,

      profileUpdateSteps: [
        {
          actionType: { text: "Update existing property", id: 1 },
          fieldType: { alterText: "Select a property", id: 0 },
          value: null,
        },
      ],

      actionStatus: "Draft",
      fieldTypes: [
        {
          id: 1,
          alterText: "Text",
          fieldType: 1,
        },
        {
          id: 2,
          alterText: "Dropdown",
          fieldType: 2,
        },
        {
          id: 3,
          alterText: "Numeric",
          fieldType: 3,
        },
        {
          id: 4,
          alterText: "Date",
          fieldType: 4,
        },
      ],
      dropdownListData: [
        { text: "Update existing property", id: 1 },
        // { text: "Create new property", id: 2 },
        { text: "Delete existing property", id: 3 },
      ],
    };
  },
  methods: {
    generateProfileUpdateFields(index, fieldName) {
      const selectedFieldTypeIds = [];

      this.profileUpdateSteps?.map((step, sIndex) => {
        if (sIndex != index) {
          selectedFieldTypeIds.push(step?.fieldType?.id);
        }
      });

      if (this.profileUpdateFields?.length > 0) {
        return this.profileUpdateFields.filter((field, fieldIndex) => {
          if (selectedFieldTypeIds.includes(field.id) || !field[fieldName]) {
            return false;
          }
          return true;
        });
      }

      return this.profileUpdateFields;
    },
    addStep() {
      this.profileUpdateSteps.push({
        actionType: { text: "Update existing property", id: 1 },
        fieldType: { alterText: "Select a property", id: 0 },
        value: null,
      });
    },
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    handleDropdownChange(value, index, field) {
      this.profileUpdateSteps[index][field] = value;

      if (field === "actionType") {
        this.profileUpdateSteps[index].fieldType = {
          alterText: "Select a property",
          id: 0,
        };
        this.profileUpdateSteps[index].value = null;
      }

      if (field === "fieldType") {
        this.profileUpdateSteps[index].value = null;
        if (this.profileUpdateSteps[index][field].fieldType == 2) {
          this.getSelectedFieldConditions(
            index,
            this.profileUpdateSteps[index][field].id
          );
        }
      }
    },
    getSelectedFieldConditions(index, fieldId) {
      const payload = {
        clubId: this.$route.params.id,
        id: fieldId,
        entityType: 2,
      };
      this.isLoading = true;
      getSegmentDropdownList(payload)
        .then((res) => {
          this.isLoading = false;
          const data = res.data?.data?.[0]?.options;
          this.profileUpdateSteps[index].valueDropdown = data;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    onConfirmActionStatus(data) {
      this.actionStatus = data;
      this.updateDataHandler();
    },
    onConfirmAdditionalFilter(data) {
      this.additionalFilterData = data;
      this.$store.commit("SET_SEGMENT_MODALOPENCLOSE", false);
    },
    reloadToDefaultState() {},
    drawerClose() {
      this.cancelClick();
    },
    onConfirmDrawerHandler() {
      this.updateDataHandler();
      this.drawerCloseHandler();
    },
    updateDataHandler() {
      this.updateNodeData({
        id: 1,
        profileUpdateSteps: this.profileUpdateSteps,
        additionalFilterData: this.additionalFilterData,
        actionStatus: this.actionStatus,
      });
    },
    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        this.profileUpdateSteps = nodeData.profileUpdateSteps;
        this.additionalFilterData = nodeData.additionalFilterData;
        this.actionStatus = nodeData.actionStatus;
      }
    },
  },
  computed: {
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
    profileUpdateFields() {
      return this.$store.state?.flowAutomation?.profileUpdateFields;
    },
    formattedMessages() {
      if (this.profileUpdateSteps.length == 0) {
        return `Update contact profile information. Choose which properties to update.`;
      }

      const actions = {
        create: "created as",
        update: "updated to",
        delete: "removed",
      };

      const messages = this.profileUpdateSteps.map((step) => {
        const propertyName = step?.fieldType?.alterText || "Property";

        let value = step.value ? step.value : "No Value";

        if (step.value && step.value.id) {
          value = step.value?.label ? step.value?.label : step.value;
        }

        if (step.value && step.value instanceof Date) {
          value = step.value.toDateString();
        }

        switch (step.actionType.text) {
          // case "Create new property":
          //   return `Configured to update contact profiles with:  '${propertyName}' ${actions.create} ${
          //     step.fieldType.alterText
          //   }, set to ${value?.label ? value?.label : value}.`;
          case "Update existing property":
            return `Configured to update contact profiles with <strong>'${propertyName}'</strong> ${
              actions.update
            } <strong>${value?.label ? value?.label : value}</strong>.`;
          case "Delete existing property":
            return `Configured to update contact profiles with <strong>'${propertyName}' ${actions.delete}</strong>.`;
          default:
            return "";
        }
      });

      if (messages.length > 1) {
        return `${messages[0]} <strong>+More</strong>`;
      } else {
        return (
          messages[0] ||
          "Update contact profile information. Choose which properties to update."
        );
      }
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  mounted() {
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
      this.onConfirmDrawerHandler();
    }, 100);
  },
  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    const generateNodeId = () => {
      return nodeId.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      generateNodeId,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
