<template>
  <div>
    <MultiSelect :filterable="true" @filterchange="onFilterChange" :data-items="groupedData" :value="multiselectValue"
      @change="onChange" :text-field="'label'" :data-item-key="'id'" placeholder="Select options"
      :group-field="'groupLabel'" :style="{}" />
  </div>
</template>

<script>
// import Multiselect from "@vueform/multiselect";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { filterBy } from '@progress/kendo-data-query';
export default {
  name: "AppDropdownGroup",
  components: {
    MultiSelect,
  },
  props: {
    label: {
      type: String,
      default: "",
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    closeOnSelect: {
      type: Boolean,
      default: false,
    },
    canDeselect: {
      type: Boolean,
      default: false,
    },
    canClear: {
      type: Boolean,
      default: false,
    },
    mode: String,
    placeholder: String,
    disabled: Boolean,
    className: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      default: {},
    },
  },
  created() {
    this.groupedData = [];
    this.generateGroupedData();
  },
  watch: {
    options: {
      handler() {
        // this.groupedData = [];
        this.generateGroupedData();
      },
      deep: true,
    },
    modelValue: {
      handler() {
        this.generateGroupedData();
      },
      deep: true,
    },
  },
  destroyed() { },
  mounted() { },
  data: function () {
    return {
      groupedData: [],
      groupedDataTemp: [],
      multiselectValue: [],
    };
  },
  computed: {},
  methods: {
    generateGroupedData() {
      if (this.groupedData?.length == 0) {
        this.options?.map((option) => {
          // if (option?.label) {
          option?.options?.map((opt) => {
            opt.groupLabel = option?.label;
            this.groupedData?.push({
              ...opt,
            });
          });
          // }
        });
      }

      if (Array.isArray(this.modelValue)) {
        this.multiselectValue = [];
        this.modelValue?.map((item) => {
          if (item != null || item != "") {
            let valueItem = this.groupedData.find((data) => data?.id == item);
            if (valueItem) {
              this.multiselectValue.push(valueItem);
            }
          }
        });
      } else {
        let item = this.groupedData.find((data) => data?.id == this.modelValue);
        if (item) {
          this.multiselectValue = [item];
        }
      }
      this.groupedDataTemp = this.groupedData;
    },
    onFilterChange(event) {
      this.groupedData = filterBy(this.groupedDataTemp.slice(), event.filter);
    },
    onChange(event) {
      let value = event.target.value;
      this.multiselectValue = value;
      const ids = value?.map((item) => item?.id);
      this.$emit("change", ids);
      // this.$emit("update:modelValue", ids);
    },
  },
};
</script>

<style>
.k-multiselect .k-chip-label {
  white-space: normal;
  text-overflow: normal;
  word-break: break-word;
}
</style>
