<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'triggerBoxIcon'"></component>
      </span>
      <span class="me-auto">Email Action</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p
        v-if="selectedCampaignType?.id == 1 && selectedTriggerValue?.id != 0"
        v-html="
          generateSpecificEmailCampaignHtml(configureContactFunctionality?.id)
        "
      ></p>
      <p
        v-else-if="selectedCampaignType?.id == 2"
        v-html="forAnyEmailCampaignHtml[configureContactFunctionality?.id]"
      ></p>
      <p v-else>Email action not defined.</p>
    </div>
    <teleport to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            EMAIL ACTION
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <div>
              <div class="mb-24">
                <p class="font-14 l-height-21 mb-8">
                  Email Action: This workflow will be triggered for contacts
                  based on their email response.
                </p>
                <p class="font-16 mb-8">Choose</p>
                <div
                  v-for="(item, index) in emailCampaignTypes"
                  :key="index"
                  class="mt-0"
                >
                  <radiobutton
                    :class="'mb-2'"
                    :name="item.label"
                    :value="item"
                    :checked="item.id === selectedCampaignType.id"
                    @change="handleChooseEmailCampaignTypes"
                    :label="item.label"
                  />
                </div>
              </div>
              <div v-if="selectedCampaignType?.id == 1" class="mb-24">
                <p class="font-16 mb-6">Select an Email</p>
                <dropdownlist
                  :style="{}"
                  :class="'w-100'"
                  @open="onOpenSelectCampaignDropDown"
                  :data-items="campaignListData"
                  :text-field="'campaignName'"
                  :data-item-key="'id'"
                  @change="handleSelectCampaign"
                  :value="selectedTriggerValue"
                  :list-no-data-render="listNoDataRender"
                  :filterable="true"
                  @filterchange="filterChange"
                >
                  <template v-slot:noDataFoundTemplate="">
                    <custom
                      :messageShow="'No campaigns were created or found.'"
                    />
                  </template>
                </dropdownlist>
              </div>
              <div class="mb-24">
                <p class="font-16 mb-6">Select Response</p>
                <dropdownlist
                  :style="{}"
                  :class="'w-100'"
                  :data-items="emailResponses"
                  :text-field="'label'"
                  :data-item-key="'id'"
                  @change="handleSelectEmailResponse"
                  :value="configureContactFunctionality"
                  :list-no-data-render="listNoDataRender"
                >
                </dropdownlist>
              </div>
              <div v-if="configureContactFunctionality?.id == 9" class="mb-24">
                <p class="font-16 mb-6">Select Link</p>
                <dropdownlist
                  :style="{}"
                  :class="'w-100'"
                  :data-items="urlLinkValues"
                  :text-field="'url'"
                  :data-item-key="'id'"
                  @change="handleUrlLinkValues"
                  :value="selectedUrlLinkValue"
                  :list-no-data-render="listNoDataRender"
                >
                  <template v-slot:noDataFoundTemplate="">
                    <custom
                      :messageShow="
                        !selectedTriggerValue?.id
                          ? 'Please select a campaign first'
                          : 'No link Clicked'
                      "
                    />
                  </template>
                </dropdownlist>
              </div>
            </div>
            <div class="mb-24">
              <div class="checkbox-text-flex font-14-child-label">
                <checkbox
                  v-model="allowRepeatingAction"
                  label="Allow entry of all contacts who've already recieved the selected email and performed the selected responses above"
                />
              </div>
            </div>

            <configureFlowFilterComponent
              :onConfirm="onConfirmFlowFilter"
              :additionalFilterData="additionalFilterData"
            />
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler"
              class="ms-2"
              theme-color="primary"
              >Apply Trigger
            </kbutton>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import triggerBoxIcon from "@/components/svg-icons/triggerBoxIcon.vue";
import configureFlowFilterComponent from "../../common/configureFlowFilterComponent.vue";
import { useStore } from "vuex";
import { RadioButton } from "@progress/kendo-vue-inputs";
import { filterBy } from "@progress/kendo-data-query";
import NoDataRender from "@/components/common/NoDataRender/NoDataRender.vue";
import { getCampaignUrlServices } from "@/services/clubDetails/emailCampaign.service";

export default {
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    triggerBoxIcon,
    configureFlowFilterComponent,
    radiobutton: RadioButton,
    custom: NoDataRender,
  },
  data() {
    return {
      forAnyEmailCampaignHtml: {
        3: "<span>Contacts <strong>who were sent </strong>any email campaign.</span>",
        4: "<span>Contacts <strong>who opened </strong>any email campaign.</span>",
        5: "<span>Contacts <strong>who clicked </strong>any email campaign.</span>",
        6: "<span>Contacts <strong>who recieved </strong>any email campaign.</span>",
      },
      listNoDataRender: "noDataFoundTemplate",
      configureContactFunctionality: { id: 4, label: "Opened", value: true },
      emailResponses: [
        // { id: 1, label: "Not Sent", value: true },
        // { id: 2, label: "Sending", value: true },
        { id: 3, label: "Sent", value: true },
        { id: 4, label: "Opened", value: true },
        { id: 5, label: "Clicked", value: true },
        { id: 6, label: "Delivered", value: true },
        { id: 9, label: "Specific Link Clicked", value: true },
        // { id: 7, label: "Bounced", value: true },
        // { id: 8, label: "Sceduled", value: true },
      ],
      emailCampaignTypes: [
        { id: 1, label: "Specific Email Campaign" },
        { id: 2, label: "Any Email Campaign" },
      ],
      selectedCampaignType: { id: 1, label: "Specific Email Campaign" },
      selectedSegmentValue: { id: 0, text: "Select Segment" },
      additionalFilterData: null,

      campaignListData: [],
      selectedTriggerValue: { id: null, campaignName: "Select Campaign" },
      allowRepeatingAction: true,

      selectedUrlLinkValue: { id: null, url: "Select Link" },
      urlLinkValues: [],
    };
  },
  methods: {
    generateSpecificEmailCampaignHtml(responseId) {
      if (responseId == 3) {
        return `<span>Contacts <strong>who were sent </strong>campaign <strong>${this.selectedTriggerValue.campaignName}</strong>.</span>`;
      }
      if (responseId == 4) {
        return `<span>Contacts <strong>who opened </strong>campaign <strong>${this.selectedTriggerValue.campaignName}</strong>.</span>`;
      }
      if (responseId == 5) {
        return `<span>Contacts <strong>who clicked </strong>campaign <strong>${this.selectedTriggerValue.campaignName}</strong>.</span>`;
      }
      if (responseId == 6) {
        return `<span>Contacts <strong>who recieved </strong>campaign <strong>${this.selectedTriggerValue.campaignName}</strong>.</span>`;
      }
      if (responseId == 9) {
        return `<span>Contacts <strong>who clicked a specific link </strong>on a campaign <strong>${this.selectedTriggerValue.campaignName}</strong>.</span>`;
      }
    },
    handleSelectEmailResponse(event) {
      this.configureContactFunctionality = event.value;
      if (
        this.configureContactFunctionality.id == 9 &&
        this.selectedTriggerValue.id
      ) {
        this.urlLinkValues = [];
        this.selectedUrlLinkValue = { id: null, url: "Select Link" };
        this.getCampaignUrlList({ campaignId: this.selectedTriggerValue.id });
      }
    },
    handleUrlLinkValues(event) {
      this.selectedUrlLinkValue = event.value;
    },
    getCampaignUrlList(payload) {
      payload = {
        ...payload,
        clubId: this.$route.params.id,
      };
      getCampaignUrlServices(payload)
        .then((res) => {
          this.urlLinkValues = res.data?.data;
        })
        .catch((err) => {});
    },
    listItemsModifier() {
      const dropdownlist = structuredClone(this.basicEmailCampaignList);
      if (dropdownlist?.length == 0) return [];
      dropdownlist.unshift({ id: null, campaignName: "Select Campaign" });
      return dropdownlist;
    },
    filterChange(event) {
      this.campaignListData = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.listItemsModifier().slice();
      return filterBy(data, filter);
    },
    handleSelectCampaign(event) {
      this.selectedTriggerValue = event.value;
      if (
        this.selectedTriggerValue.id != null &&
        this.configureContactFunctionality.id == 9
      ) {
        this.urlLinkValues = [];
        this.selectedUrlLinkValue = { id: null, url: "Select Link" };
        this.getCampaignUrlList({ campaignId: this.selectedTriggerValue.id });
      }
    },
    onOpenSelectCampaignDropDown() {
      this.campaignListData = this.listItemsModifier();
    },
    handleChooseEmailCampaignTypes(event) {
      this.selectedCampaignType = event.value;
      this.selectedTriggerValue = { id: null, campaignName: "Select Campaign" };
      this.selectedUrlLinkValue = { id: null, label: "Select Link" };
      this.configureContactFunctionality = {
        id: 4,
        label: "Opened",
        value: true,
      };

      if (this.selectedCampaignType.id == 2) {
        //delete id 9 from emailResponses
        this.emailResponses = this.emailResponses.filter(
          (item) => item.id != 9
        );
      }
      if (this.selectedCampaignType.id == 1) {
        //Add id 9 to emailResponses
        this.emailResponses = [
          ...this.emailResponses,
          { id: 9, label: "Specific Link Clicked", value: true },
        ];
      }
    },
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    onConfirmFlowFilter(data) {
      this.additionalFilterData = data;
    },
    onConfirmDrawerHandler() {
      this.updateDataHandler();
      this.drawerCloseHandler();
    },
    updateDataHandler() {
      this.updateNodeData({
        id: 1,
        selectedTriggerValue: this.selectedTriggerValue,
        selectedUrlLinkValue: this.selectedUrlLinkValue,
        urlLinkValues: this.urlLinkValues,
        configureContactFunctionality: [
          {
            ...this.configureContactFunctionality,
            allowRepeatingAction: this.allowRepeatingAction,
          },
        ],
        additionalFilterData: this.additionalFilterData,
        selectedCampaignType: this.selectedCampaignType,
      });
    },

    beforeCloseHandler() {
      this.onConfirmDrawerHandler();
    },

    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        this.selectedTriggerValue = nodeData.selectedTriggerValue;
        this.selectedUrlLinkValue = nodeData.selectedUrlLinkValue;
        this.urlLinkValues = nodeData.urlLinkValues;
        this.selectedCampaignType = nodeData.selectedCampaignType;
        this.additionalFilterData = nodeData.additionalFilterData;
        this.configureContactFunctionality = {
          id: nodeData.configureContactFunctionality[0].id,
          label: nodeData.configureContactFunctionality[0].label,
          value: nodeData.configureContactFunctionality[0].value,
        };
        this.allowRepeatingAction =
          nodeData.configureContactFunctionality[0].allowRepeatingAction;
        this.getCampaignUrlList({ campaignId: this.selectedTriggerValue.id });
      }
    },
  },
  computed: {
    basicEmailCampaignList() {
      return this.$store.state?.flowAutomation?.basicEmailCampaignList;
    },
    segmentsData() {
      return this.$store.state.segments.segmentsLists;
    },
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  mounted() {
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
      this.updateDataHandler();
    }, 100);
  },

  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
