<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 17C8.28333 17 8.521 16.904 8.713 16.712C8.90433 16.5207 9 16.2833 9 16C9 15.7167 8.90433 15.479 8.713 15.287C8.521 15.0957 8.28333 15 8 15C7.71667 15 7.479 15.0957 7.287 15.287C7.09567 15.479 7 15.7167 7 16C7 16.2833 7.09567 16.5207 7.287 16.712C7.479 16.904 7.71667 17 8 17ZM8 13C8.28333 13 8.521 12.904 8.713 12.712C8.90433 12.5207 9 12.2833 9 12C9 11.7167 8.90433 11.479 8.713 11.287C8.521 11.0957 8.28333 11 8 11C7.71667 11 7.479 11.0957 7.287 11.287C7.09567 11.479 7 11.7167 7 12C7 12.2833 7.09567 12.5207 7.287 12.712C7.479 12.904 7.71667 13 8 13ZM8 9C8.28333 9 8.521 8.904 8.713 8.712C8.90433 8.52067 9 8.28333 9 8C9 7.71667 8.90433 7.479 8.713 7.287C8.521 7.09567 8.28333 7 8 7C7.71667 7 7.479 7.09567 7.287 7.287C7.09567 7.479 7 7.71667 7 8C7 8.28333 7.09567 8.52067 7.287 8.712C7.479 8.904 7.71667 9 8 9ZM11 17H17V15H11V17ZM11 13H17V11H11V13ZM11 9H17V7H11V9ZM5 21C4.45 21 3.979 20.8043 3.587 20.413C3.19567 20.021 3 19.55 3 19V5C3 4.45 3.19567 3.979 3.587 3.587C3.979 3.19567 4.45 3 5 3H19C19.55 3 20.021 3.19567 20.413 3.587C20.8043 3.979 21 4.45 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.021 20.8043 19.55 21 19 21H5ZM5 19H19V5H5V19ZM5 5V19V5Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "addToListIcon"
    }
</script>
<style scoped></style>