<template>
  <div class="col-md-12" :class="isInEditModeBool ? 'disabled' : ''">
    <!-- <select name="draft" id="draft">
      <option value="draft">Draft</option>
      <option value="pause">Pause</option>
      <option value="live">Live</option>
    </select> -->
    <div class="position-relative">
      <div @click="toggleStatusPopup"
        class="custon-add-to-list-popup-input d-flex justify-content-between align-items-center h-32 bg-white radius-4">
        <p class="d-flex align-items-center">
          <span class="dot m-0 me-2" :class="selectedStatus == 'Pause'
              ? 'yellow'
              : selectedStatus == 'Live'
                ? 'green'
                : ''
            "></span>{{ selectedStatus }}
        </p>
        <p>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 11L4 5H12L8 11Z" fill="#24243A"></path>
          </svg>
        </p>
      </div>
      <div v-click-outside="onCloseStatusPopup" v-if="nodeStatusPopupVisibility" class="custon-add-to-list-popup-wrap">
        <p v-for="item in statusArray" :key="item?.id" @click="SelectStatusHandler(item?.name)" class="add-to-list-box"
          :class="item?.name == selectedStatus ? 'selected' : ''">
          <span :class="item?.iconName" class="dot m-0 me-2"></span>
          {{ item?.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Popup } from "@progress/kendo-vue-popup";
import vClickOutside from "click-outside-vue3";
import { ref, getCurrentInstance } from "vue";
import { useStore } from "vuex";

export default {
  name: "nodeStatusDropdown",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    kbutton: Button,
    Popup,
  },
  props: {
    onConfirm: {
      type: Function,
      default: () => { },
    },
    actionStatus: {
      type: String,
      default: "Draft",
    },
  },
  computed: {
    isInEditModeBool() {
      return this.$store.state?.flowAutomation?.isInEditMode;
    },
  },
  data() {
    return {
      nodeStatusPopupVisibility: false,

      selectedStatus: this.actionStatus,
      statusArray: [
        {
          name: "Draft",
          iconName: "",
          id: 3,
        },
        {
          name: "Pause",
          iconName: "yellow",
          id: 2,
        },
        {
          name: "Live",
          iconName: "green",
          id: 1,
        },
      ],
    };
  },
  methods: {
    SelectStatusHandler(status) {
      this.selectedStatus = status;
      this.nodeStatusPopupVisibility = false;
      this.onConfirm(status);
    },
    toggleStatusPopup() {
      this.nodeStatusPopupVisibility = !this.nodeStatusPopupVisibility;
    },

    onCloseStatusPopup() {
      this.nodeStatusPopupVisibility = false;
    },
  },
  mounted() { },
  watch: {
    removedNodeId: function (val) {
      this.onCloseStatusPopup();
    },
    $route(to, from) {
      this.onCloseStatusPopup();
    },
    actionStatus: {
      handler(val) {
        this.selectedStatus = this.actionStatus;
      },
      deep: true,
      immediate: false,
    },
  },
  setup() {
    let df = null;
    let removedNodeId = ref(null);
    df = getCurrentInstance().appContext.config.globalProperties.$df.value;
    const store = useStore();

    df.on("nodeRemoved", function (id) {
      removedNodeId.value = id;
      store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
        drawerName: id,
        drawerStatus: false,
      });
    });

    return {
      removedNodeId,
    };
  },
};
</script>
<style scoped></style>
