<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 4V11.6L6 16.6V14H4V20H10V18H7.4L13 12.4V4H11ZM14.85 13.4L13.4 14.85L16.6 18H14V20H20V14H18V16.6L14.85 13.4Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "conditionalSplitIcon"
    }
</script>
<style scoped></style>