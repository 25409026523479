import { DELETE, GET, POST, PUT } from "@/service";

export const getWebCommanderCategoryService = (payload: any) => {
  return GET(`api/v1/campaign/${payload?.clubId}/get-webcommander-category`, {
    params: payload,
  });
};

export const getWebCommanderProductService = (payload: any) => {
  return GET(`api/v1/campaign/${payload?.clubId}/get-webcommander-products`, {
    params: payload,
  });
};

export const getWebCommanderProductMetricsService = (payload: any) => {
  return GET(`api/v1/campaign/${payload?.clubId}/get-product-metrics`, {
    params: payload,
  });
};

export const getProductWidgetDynamicQueriesListService = (payload: any) => {
  return GET(`api/v1/campaign/get-product-widget-dynamic-queries`, {
    params: payload,
  });
};

export const getProductWidgetDynamicQueryService = (payload: any) => {
  return GET(`api/v1/campaign/get-product-widget-dynamic-query`, {
    params: payload,
  });
};

export const saveProductWidgetDynamicQueryService = (payload: any) => {
  return POST(`api/v1/campaign/save-product-widget-dynamic-query`, payload);
};

export const updateProductWidgetDynamicQueryService = (payload: any) => {
  return PUT(`api/v1/campaign/update-product-widget-dynamic-query`, payload);
};

export const deleteProductWidgetDynamicQueryService = (payload: any) => {
  return DELETE(`api/v1/campaign/delete-product-widget-dynamic-query`, {
    data: payload,
  });
};
