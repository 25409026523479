<template>
    <ColorPicker @change="handleChange" @activecolorclick="handleChange" :view="'gradient'"
        :gradient-settings="{opacity: false,}" :title="title" />
</template>
<script>
import { applyInlineStyle } from '@progress/kendo-editor-common';
import { ColorPicker } from '@progress/kendo-vue-inputs';
export default {
    name: 'customColorPicker',
    components: {
        ColorPicker,
    },
    emits: {
        change: null,
        activecolorclick: null,
    },
    props: {
        view: Object,
        color: String,
        colorPickerProps: Object,
        commandName: String,
        settings: Object,
    },
    computed: {
        title() {
            if (this.settings.colorPickerProps.title === 'editor.foregroundColor') {
                return 'Color';
            }
            else {
                return 'Background Color';
            }
        }
    },
    methods: {
        handleChange(event) {
            const { view } = this.$props;
            const { color, colorPickerProps, commandName } = this.$props.settings;

            if (view) {
                applyInlineStyle({ style: color, value: event.value }, commandName)(
                    view.state,
                    view.dispatch
                );
            }
        },
    },
};
</script>
