<template>
  <!-- <AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar> -->
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'triggerBoxIcon'"></component>
      </span>
      <span class="me-auto">Metrics</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div v-if="!selectedMetricsType" class="box-content">
      <p>No metrics defined</p>
    </div>
    <div
      v-if="selectedMetricsType && selectedMetricsType?.id != 0"
      class="box-content"
    >
      <p>
        People <b>will enter this flow</b> when they
        <strong>{{ selectedMetricsType?.alterText }}</strong
        >.
      </p>
    </div>
    <teleport v-if="isLoaded" to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            METRICS
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <div>
              <div class="mb-24">
                <p class="font-16 mb-6">What action will trigger this flow?</p>

                <!-- <dropdownlist :style="{}" :class="'w-100'" :data-items="metricsTypeList" :text-field="'alterText'"
                  :data-item-key="'id'" @change="handleMetricsTypeChange" :default-item="defaultItem"
                  :value="selectedMetricsType">
                </dropdownlist> -->

                <dropdownlist
                  :style="{}"
                  :class="'w-100'"
                  :data-items="copyMetricsTypeList"
                  :text-field="'alterText'"
                  :data-item-key="'id'"
                  @change="handleMetricsTypeChange"
                  :default-item="defaultItem"
                  :value="selectedMetricsType"
                  @open="onOpenActionTrigger"
                  :filterable="true"
                  @filterchange="filterChangeActionTrigger"
                >
                </dropdownlist>
              </div>
            </div>
            <div v-if="selectedMetricsType && selectedMetricsType?.id != 0">
              <h4 class="font-16 text-uppercase mb-12">Trigger Filters</h4>
              <p class="font-14 l-height-24 text-color mt-0 ms-0 me-0 mb-12">
                If you would like only certain X events to trigger this flow,
                add a Trigger Filter here.
              </p>
              <div class="mb-24">
                <p class="font-16 mb-6">Contacts Match</p>
                <dropdownlist
                  :style="{}"
                  :class="'w-100'"
                  :data-items="contactMatchValues"
                  :text-field="'text'"
                  :data-item-key="'id'"
                  @change="handleContactsMatchChange"
                  :value="selectedContactsMatch"
                >
                </dropdownlist>
              </div>
              <p>of the following conditions :</p>
              <div
                class="metric-condition-box"
                v-for="(condition, index) in metricsConditions"
                :key="index"
              >
                <div class="text-right mb-10">
                  <kbutton
                    v-if="index != 0"
                    :fill-mode="'flat'"
                    @click="deleteConditionHandler(index)"
                    :class="'metric-condition-close p-0'"
                    type="primary"
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 4.41562L9.41563 8L13 11.5844L11.5844 13L8 9.41563L4.41562 13L3 11.5844L6.58437 8L3 4.41562L4.41562 3L8 6.58437L11.5844 3L13 4.41562Z"
                        fill="#24243A"
                      />
                    </svg>
                  </kbutton>
                </div>
                <div v-if="index != 0" class="text-center and-or-title mb-18">
                  <span class="d-inline-block font-16 text-uppercase">
                    {{ condition?.typeOfCondition }}
                  </span>
                </div>
                <div class="mb-24">
                  <dropdownlist
                    :style="{}"
                    :class="'w-100'"
                    :data-items="copyAttributesArray"
                    :text-field="'label'"
                    :data-item-key="'id'"
                    @change="
                      handleDropdownChange(
                        $event.target.value,
                        index,
                        'attribute'
                      )
                    "
                    :default-item="condition?.defaultAttribute"
                    :value="condition?.selectedAttribute"
                    @open="
                      onAttributeDropdownOpenHandler(condition?.attributesArray)
                    "
                    :filterable="true"
                    @filterchange="filterChangeAttribute($event, index)"
                  >
                  </dropdownlist>
                </div>
                <div class="mb-24">
                  <dropdownlist
                    :disabled="true"
                    :style="{}"
                    :class="'w-100'"
                    :data-items="attributeTypesArray"
                    :text-field="'text'"
                    :data-item-key="'id'"
                    :default-item="condition?.defaultAttributeType"
                    :value="condition?.selectedAttributeType"
                  >
                  </dropdownlist>
                </div>
                <div class="mb-24">
                  <AppDropdownList
                    :searchable="true"
                    :disabled="condition?.operatorsArray?.length == 0"
                    :data-items="condition?.operatorsArray"
                    :textField="'alterText'"
                    :dataItemKey="'id'"
                    v-model="condition.selectedOperator"
                    :class="'w-100'"
                    :default-item="condition?.defaultOperator"
                    @updateDropdownValue="
                      handleDropdownChange($event, index, 'operator')
                    "
                  />
                </div>
                <div v-if="condition?.selectedAttribute?.fieldType == 1">
                  <kinput
                    v-if="
                      condition?.selectedOperator &&
                      condition?.selectedOperator?.firstColumn
                    "
                    placeholder="Attribute value"
                    type="text"
                    v-model="condition.firstValue"
                  >
                  </kinput>
                  <kinput
                    v-if="
                      condition?.selectedOperator &&
                      condition?.selectedOperator?.secondColumn
                    "
                    placeholder="Attribute value"
                    type="text"
                    v-model="condition.lastValue"
                  >
                  </kinput>
                </div>
                <div v-if="condition?.selectedAttribute?.fieldType == 3">
                  <knumeric
                    v-if="
                      condition?.selectedOperator &&
                      condition?.selectedOperator?.firstColumn
                    "
                    placeholder="Attribute value"
                    v-model="condition.firstValue"
                  >
                  </knumeric>
                  <knumeric
                    v-if="
                      condition?.selectedOperator &&
                      condition?.selectedOperator?.secondColumn
                    "
                    placeholder="Attribute value"
                    v-model="condition.lastValue"
                  >
                  </knumeric>
                </div>
                <div v-if="condition?.selectedAttribute?.fieldType == 4">
                  <kdatetime
                    v-if="
                      condition?.selectedOperator &&
                      condition?.selectedOperator?.firstColumn
                    "
                    :default-value="currentTime"
                    :value="condition.firstValue"
                    @change="handleDateChange($event, index, 'firstValue')"
                  >
                  </kdatetime>
                  <kdatetime
                    v-if="
                      condition?.selectedOperator &&
                      condition?.selectedOperator?.secondColumn
                    "
                    :default-value="currentTime"
                    :value="condition.lastValue"
                    @change="handleDateChange($event, index, 'lastValue')"
                  >
                  </kdatetime>
                </div>
              </div>
              <div class="mb-24">
                <kbutton
                  type="primary"
                  @click="addConditionHandler"
                  :theme-color="'primary'"
                  :fill-mode="'outline'"
                  >+ Add Condition</kbutton
                >
              </div>
            </div>
            <configureFlowFilterComponent
              :onConfirm="onConfirmFlowFilter"
              :additionalFilterData="additionalFilterData"
            />
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler"
              class="ms-2"
              theme-color="primary"
              >Apply Trigger
            </kbutton>
          </div>
        </div>
      </div>
      <div id="flowContainer"></div>
    </teleport>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import triggerBoxIcon from "@/components/svg-icons/triggerBoxIcon.vue";
import configureFlowFilterComponent from "../../common/configureFlowFilterComponent.vue";
import { useStore } from "vuex";
import { getMetricsCustomFieldsService } from "@/services/clubDetails/flowAutomation.service";
import { getSegmentConfigConditions } from "@/services/clubDetails/segments.service";
import { Input, NumericTextBox } from "@progress/kendo-vue-inputs";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import AppSnackBar from "@/components/common/AppSnackBar/AppSnackBar.vue";
import { filterBy } from "@progress/kendo-data-query";
import AppDropdownList from "@/components/common/AppDropdownList/AppDropdownList.vue";

export default {
  inject: ["editModeEnabled"],
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    triggerBoxIcon,
    configureFlowFilterComponent,
    kinput: Input,
    knumeric: NumericTextBox,
    kdatetime: DateTimePicker,
    AppSnackBar,
    AppDropdownList,
  },
  data() {
    return {
      copyMetricsTypeList: [],

      isDateError: false,

      notifyMessage: "",
      notifyType: "",
      isNotify: false,

      currentTime: new Date(),
      defaultItem: {
        id: 0,
        alterText: "Select an action",
      },

      additionalFilterData: null,

      selectedMetricsType: null,
      selectedContactsMatch: { id: 1, text: "All" },
      contactMatchValues: [
        { id: 1, text: "All" },
        { id: 2, text: "Any" },
      ],

      attributesArray: [],
      copyAttributesArray: [],
      atrrubutesArrayClone: [],

      metricsConditions: [
        {
          typeOfCondition: "AND",
          attributesArray: [],
          defaultAttribute: {
            id: 0,
            label: "Select attribute",
          },
          selectedAttribute: null,
          defaultAttributeType: {
            id: 0,
            text: "Attribute type",
          },
          selectedAttributeType: null,

          operatorsArray: [],
          defaultOperator: {
            id: 0,
            alterText: "Operators",
          },
          selectedOperator: null,

          firstValue: null,
          lastValue: null,
        },
      ],

      defaultCondition: {
        typeOfCondition: "AND",
        attributesArray: [],
        defaultAttribute: {
          id: 0,
          label: "Select attribute",
        },
        selectedAttribute: null,
        defaultAttributeType: {
          id: 0,
          text: "Attribute type",
        },
        selectedAttributeType: null,

        operatorsArray: [],
        defaultOperator: {
          id: 0,
          alterText: "Operators",
        },
        selectedOperator: null,

        firstValue: null,
        lastValue: null,
      },

      attributeTypesArray: [
        {
          id: 1,
          text: "Text",
        },
        {
          id: 3,
          text: "Numeric",
        },
        {
          id: 4,
          text: "Date",
        },
      ],
    };
  },
  methods: {
    onOpenActionTrigger() {
      this.copyMetricsTypeList = structuredClone(this.metricsTypeList);
    },
    filterChangeActionTrigger(event) {
      this.copyMetricsTypeList = this.filterDataActionTrigger(event.filter);
    },
    filterDataActionTrigger(filter) {
      const data = this.metricsTypeList.slice();
      return filterBy(data, filter);
    },
    onAttributeDropdownOpenHandler(value) {
      this.attributesArrayClone = structuredClone(value);
      this.copyAttributesArray = structuredClone(value);
    },
    filterChangeAttribute(event, index) {
      this.copyAttributesArray = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.attributesArrayClone.slice();
      return filterBy(data, filter);
    },
    showNotification(message, type) {
      this.$store.commit("SET_NOTIFICATION_OBJECT", {
        notifyMessage: message,
        notifyType: type,
        isNotify: true,
      });
    },
    deleteConditionHandler(index) {
      if (this.metricsConditions.length > 1) {
        this.metricsConditions.splice(index, 1);
      }
    },
    handleDateChange(event, index, type) {
      if (type == "firstValue") {
        this.metricsConditions[index].firstValue = event?.target?.value;
      } else if (type == "lastValue") {
        this.metricsConditions[index].lastValue = event?.target?.value;
      }
    },
    addConditionHandler() {
      this.metricsConditions.push({
        ...this.defaultCondition,
        attributesArray: this.attributesArray,
        typeOfCondition: this.selectedContactsMatch.id == 1 ? "AND" : "OR",
      });
    },
    handleDropdownChange(value, index, type) {
      if (type == "attribute") {
        this.metricsConditions[index].selectedAttribute = value;
        this.metricsConditions[index].firstValue = null;
        this.metricsConditions[index].lastValue = null;

        this.metricsConditions[index].selectedAttributeType =
          this.attributeTypesArray.find((item) => item.id == value.fieldType);
        let payload = {
          clubId: this.$route.params.id,
          configGroup: value.fieldType,
        };
        this.getOperators(payload, index);
        this.metricsConditions[index].selectedOperator = null;
      } else if (type == "operator") {
        this.metricsConditions[index].selectedOperator = value;
        this.metricsConditions[index].firstValue = null;
        this.metricsConditions[index].lastValue = null;
      }
    },
    getOperators(payload, index = 0) {
      getSegmentConfigConditions(payload)
        .then((res) => {
          this.metricsConditions[index].operatorsArray = res?.data?.data;
        })
        .catch((err) => {});
    },
    handleContactsMatchChange(e) {
      this.selectedContactsMatch = e.target.value;
      const index = this.metricsConditions.length - 1;
      this.metricsConditions[index].typeOfCondition =
        e.target.value.id == 1 ? "AND" : "OR";
    },
    handleMetricsTypeChange(e) {
      this.selectedMetricsType = e.target.value;
      this.$store.commit("SET_CUSTOM_EVENT_TYPE_ID", e.target.value?.id);
      if (e.target.value?.id != 0) {
        this.metricsConditions = [];
        this.metricsConditions.push({ ...this.defaultCondition });
        const payload = {
          clubId: this.$route.params.id,
          entityType: 2,
          customEventTypeId: e.target.value.id,
        };
        this.getMetricsCustomFields(payload);
      }
    },
    getMetricsCustomFields(payload, index = 0) {
      getMetricsCustomFieldsService(payload)
        .then((res) => {
          this.metricsConditions[index].attributesArray = res?.data?.data;
          this.attributesArray = res?.data?.data;
        })
        .catch((err) => {});
    },
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    onConfirmFlowFilter(data) {
      this.additionalFilterData = data;
    },
    reloadToDefaultState() {},
    drawerClose() {
      this.cancelClick();
      // this.reloadToDefaultState();
    },
    dataValidation() {
      let isValid = false;
      for (const item of this.metricsConditions) {
        isValid = isValid ? false : isValid;

        if (
          item?.selectedAttribute?.id &&
          item?.selectedOperator?.id &&
          item?.selectedAttributeType?.id
        ) {
          isValid = true;
          if (item?.selectedOperator?.firstColumn) {
            if (!item?.firstValue) {
              if (item?.firstValue != 0) {
                isValid = false;
              }
            }
          }
          if (item?.selectedOperator?.secondColumn) {
            if (!item?.lastValue) {
              if (item?.lastValue != 0) {
                isValid = false;
              }
            }
          }
        }

        if (
          (item?.selectedAttributeType?.id == 4 ||
            item?.selectedAttributeType?.text == "Date") &&
          item?.selectedOperator?.secondColumn
        ) {
          //check first value time has to be less than last value time
          if (item?.firstValue && item?.lastValue) {
            if (new Date(item?.firstValue) > new Date(item?.lastValue)) {
              isValid = false;
              this.isDateError = true;
            } else {
              this.isDateError = false;
              isValid = true;
            }
          }
        }

        if (!isValid) {
          break;
        }
      }
      return isValid;
    },
    onConfirmDrawerHandler() {
      if (this.dataValidation()) {
        this.updateDataHandler();
        this.drawerCloseHandler();
      } else {
        if (this.isDateError) {
          this.showNotification("Please input valid time range.", "warning");
        } else {
          this.showNotification("Please configure trigger properly", "warning");
        }
      }
    },

    updateDataHandler() {
      this.updateNodeData({
        id: 1,
        selectedTriggerValue: this.selectedMetricsType,
        metricsData: {
          attributesArray: this.attributesArray,
          selectedContactsMatch: this.selectedContactsMatch,
          configureMetricsConditions: this.metricsConditions,
        },
        additionalFilterData: this.additionalFilterData,
      });
    },

    beforeCloseHandler() {
      this.onConfirmDrawerHandler();
    },

    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        // change configureMetricsConditions dates
        let conditions = structuredClone(
          nodeData?.metricsData?.configureMetricsConditions
        );
        conditions = conditions.map((item) => {
          if (item.selectedAttributeType?.text == "Date") {
            item.firstValue = new Date(item.firstValue);
            if (item.lastValue) {
              item.lastValue = new Date(item.lastValue);
            }
            return item;
          } else {
            return item;
          }
        });
        this.additionalFilterData = nodeData.additionalFilterData;
        this.selectedMetricsType = nodeData?.selectedTriggerValue;
        this.attributesArray = nodeData?.metricsData?.attributesArray;
        this.selectedContactsMatch =
          nodeData?.metricsData?.selectedContactsMatch;
        this.metricsConditions = conditions;
        this.$store.commit(
          "SET_CUSTOM_EVENT_TYPE_ID",
          this.selectedMetricsType?.id
        );
      }
    },
  },
  computed: {
    metricsTypeList() {
      return this.$store.state?.flowAutomation?.metricsTypeList;
    },
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          // this.onConfirmDrawerHandler()
          // this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  mounted() {
    console.log("m67", this.editModeEnabled);
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
      this.updateDataHandler();
    }, 200);
  },

  setup() {
    const isLoaded = ref(false);
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      isLoaded.value = true;
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      isLoaded,
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
