<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'triggerBoxIcon'"></component>
      </span>
      <span class="me-auto">Segment</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p v-if="selectedSegmentValue?.id == 0">
        Decide on who should enter this workflow by configuring a trigger.
      </p>

      <p
        v-if="
          configureContactFunctionality?.[0]?.value == false &&
          configureContactFunctionality?.[1]?.value == false &&
          configureContactFunctionality?.[2]?.value == false &&
          selectedSegmentValue?.id != 0
        "
      >
        Configure how your contacts will function in the flow.
      </p>

      <p
        v-if="
          configureContactFunctionality?.[0]?.value == true &&
          selectedSegmentValue?.id != 0 &&
          configureContactFunctionality?.[1]?.value == false &&
          configureContactFunctionality?.[2]?.value == false
        "
      >
        Only <strong>contacts who are present</strong> in the segment
        <strong>{{ selectedSegmentValue?.text }}</strong
        >.
      </p>
      <p
        v-if="
          configureContactFunctionality?.[0]?.value == false &&
          selectedSegmentValue?.id != 0 &&
          configureContactFunctionality?.[1]?.value == true &&
          configureContactFunctionality?.[2]?.value == false
        "
      >
        Only <strong>new contacts who enter</strong> the segment
        <strong>{{ selectedSegmentValue?.text }}</strong
        >.
      </p>

      <p
        v-if="
          configureContactFunctionality?.[0]?.value == false &&
          selectedSegmentValue?.id != 0 &&
          configureContactFunctionality?.[1]?.value == false &&
          configureContactFunctionality?.[2]?.value == true
        "
      >
        Only contacts <strong>who are currently present</strong> in the segment
        <strong>{{ selectedSegmentValue?.text }}</strong
        >.
      </p>

      <p
        v-if="
          configureContactFunctionality?.[0]?.value == true &&
          selectedSegmentValue?.id != 0 &&
          configureContactFunctionality?.[1]?.value == true &&
          configureContactFunctionality?.[2]?.value == false
        "
      >
        <strong>Contacts who are present</strong> and
        <strong>newly entered contacts</strong> in the segment
        <strong>{{ selectedSegmentValue?.text }}</strong
        >.
      </p>

      <p
        v-if="
          configureContactFunctionality?.[0]?.value == true &&
          selectedSegmentValue?.id != 0 &&
          configureContactFunctionality?.[1]?.value == false &&
          configureContactFunctionality?.[2]?.value == true
        "
      >
        <strong>Contacts who are present</strong> in the segment and
        <strong>remove contacts if they are removed</strong> from the segment
        <strong>{{ selectedSegmentValue?.text }}</strong
        >.
      </p>

      <p
        v-if="
          configureContactFunctionality?.[0]?.value == false &&
          selectedSegmentValue?.id != 0 &&
          configureContactFunctionality?.[1]?.value == true &&
          configureContactFunctionality?.[2]?.value == true
        "
      >
        <strong>Newly entered contacts</strong> in the segment and
        <strong>remove contacts if they are removed</strong> from the segment
        <strong>{{ selectedSegmentValue?.text }}</strong
        >.
      </p>

      <p
        v-if="
          configureContactFunctionality?.[0]?.value == true &&
          selectedSegmentValue?.id != 0 &&
          configureContactFunctionality?.[1]?.value == true &&
          configureContactFunctionality?.[2]?.value == true
        "
      >
        <strong>Contacts who are present</strong> and
        <strong>newly entered contacts</strong> in the segment and
        <strong>remove contacts if they are removed</strong> from the segment
        <strong>{{ selectedSegmentValue?.text }}</strong
        >.
      </p>
    </div>
    <teleport to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            ENTER A SEGMENT
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <div>
              <div class="mb-24">
                <p class="font-16 mb-6">Select Segment</p>
                <dropdownlist
                  :style="{}"
                  :class="'w-100'"
                  :data-items="segmentsList"
                  :text-field="'text'"
                  :data-item-key="'id'"
                  @change="handleSegmentStatusValue"
                  :value="selectedSegmentValue"
                  :list-no-data-render="listNoDataRender"
                  @open="segmentDataRearrangeFunction"
                >
                  <template v-slot:noDataFoundTemplate="">
                    <custom
                      :messageShow="'No segments were created or found.'"
                    />
                  </template>
                </dropdownlist>
              </div>
            </div>
            <div>
              <p class="mb-20">
                Configure how your contacts will function in the flow
              </p>

              <div
                v-for="(item, index) in configureContactFunctionality"
                :key="index"
                class="mb-16 checkbox-text-flex font-14-child-label"
              >
                <checkbox v-model="item.value" :label="item.label" />
              </div>
            </div>

            <configureFlowFilterComponent
              :onConfirm="onConfirmFlowFilter"
              :additionalFilterData="additionalFilterData"
            />
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <!-- <kbutton @click="drawerClose" class="ms-2">Cancel</kbutton> -->
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler"
              class="ms-2"
              theme-color="primary"
              >Apply Trigger
            </kbutton>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import triggerBoxIcon from "@/components/svg-icons/triggerBoxIcon.vue";
import configureFlowFilterComponent from "../../common/configureFlowFilterComponent.vue";
import { useStore } from "vuex";
import NoDataRender from "@/components/common/NoDataRender/NoDataRender.vue";

export default {
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    triggerBoxIcon,
    configureFlowFilterComponent,
    custom: NoDataRender,
  },
  data() {
    return {
      listNoDataRender: "noDataFoundTemplate",
      segmentsList: [{ id: 0, text: "Select Segment" }],
      configureContactFunctionality: [
        {
          label: "Contacts already in the segment.",
          id: 1,
          value: false,
          allowRepeatingAction: false,
        },
        {
          label: "New contacts who entered the selected segment.",
          id: 2,
          value: false,
          allowRepeatingAction: false,
        },
        {
          label:
            "Remove contacts from the flow if they are removed from the segment at anytime after entering the flow.",
          id: 3,
          value: false,
          allowRepeatingAction: false,
        },
      ],
      selectedSegmentValue: { id: 0, text: "Select Segment" },
      additionalFilterData: null,
    };
  },
  methods: {
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    onConfirmFlowFilter(data) {
      this.additionalFilterData = data;
    },
    segmentDataRearrangeFunction() {
      const dropdownSegments = [{ id: 0, text: "Select Segment" }];
      if (this?.segmentListForDropdown?.length === 0) return [];

      for (const i in this.segmentListForDropdown) {
        let object = {
          id: this.segmentListForDropdown[i].id,
          text: this.segmentListForDropdown[i].segmentName,
        };
        dropdownSegments.push(object);
      }
      this.segmentsList = dropdownSegments;
    },
    handleSegmentStatusValue(e) {
      this.selectedSegmentValue = e.value;
    },
    reloadToDefaultState() {
      this.selectedSegmentValue = { id: 0, text: "Select Segment" };
      this.configureContactFunctionality =
        this.configureContactFunctionality.map((item) => {
          item.value = false;
          return item;
        });
    },
    drawerClose() {
      this.cancelClick();
      // this.reloadToDefaultState();
    },
    onConfirmDrawerHandler() {
      this.updateDataHandler();
      this.drawerCloseHandler();
    },

    updateDataHandler() {
      this.updateNodeData({
        id: 1,
        selectedTriggerValue: this.selectedSegmentValue,
        configureContactFunctionality: this.configureContactFunctionality,
        additionalFilterData: this.additionalFilterData,
      });
    },

    beforeCloseHandler() {
      this.onConfirmDrawerHandler();
    },

    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        this.selectedSegmentValue = nodeData.selectedTriggerValue;
        this.additionalFilterData = nodeData.additionalFilterData;
        this.configureContactFunctionality =
          nodeData.configureContactFunctionality;
      }
    },
  },
  computed: {
    segmentsData() {
      return this.$store.state.segments.segmentsLists;
    },
    segmentListForDropdown() {
      return this.$store.state.segments.segmentListForDropdown;
    },
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  mounted() {
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
      this.updateDataHandler();
    }, 100);
  },

  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
