export default class AddSegmentModel {
    public id: number = 0;
    public clubId: number = 0;
    public entityType: number = 0;
    public segmentName: string = "";
    public conditionType: string = "And";
    public finalSQL: string = "";
    public segmentGroupList: segmentGroupListObj[] = []
}

export class segmentGroupListObj{
    public id: number = 0;
    public conditionType: string = "And";
    public segmentConditionList: segmentConditions[] = []
}

export class segmentConditions {
    public id: number = 0;
    public segmentId: number = 0;
    public segmentConfigId: number = 0;
    public segmentConfigurationConditionId: number = 0;
    public condition: string = "";
    public conditionType: string = "And";
    public nextCondition: string = "";
    public firstValue: string | [] = "";
    public secondValue: string = "";
    public parentId: number = 0;
    public configGroup: number = 0;
    public isFirstColumn: boolean = false;
    public isSecondColumn: boolean = false;
    public userDefinedDropdownList: [] = [];
    public segmentConfigConditionDDL: [] = [];
    public segmentChildCondition: segmentChildCondition = new segmentChildCondition();
}
export class segmentChildCondition {
    public id: number = 0;
    public segmentConditionId: number = 0;
    public segmentConfigConditionGroupId: number = 0;
    public groupCondition: string = "";
    public groupNextCondition: string = "";
    public groupFirstValue: string = "";
    public groupSecondValue: string = "";
    public segmentConfigConditionWhereId: number = 0;
    public whereCondition: string = "";
    public whereNextCondition: string = "";
    public whereFirstValue: string = "";
    public whereSecondValue: string = "";
    public whereConditionType: string = "";
    public segmentConfigConditionGroupDDL: [] = [];
    public segmentConfigConditionGroup: [] = [];
    public segmentConfigConditionWhereDDL: [] = [];
    public segmentConfigConditionWhere: [] = [];
    public isGroupFirstColumn: boolean = false;
    public isGroupSecondColumn: boolean = false;
    public isWhereFirstColumn: boolean = false;
    public isWhereSecondColumn: boolean = false;
}