<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'removeFromListWaitBoxIcon'"></component>
      </span>
      <span class="me-auto">Wait Time</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p v-if="waitTimeData?.waitTimeCount == 0" class="mb-2">
        Time delay is not configured.
      </p>
      <p
        v-if="waitTimeData?.waitTimeCount != 0"
        class="mb-2 bg-gray-2 p-10 radius-4 text-center break-word"
      >
        Wait
        <strong
          >{{
            ` ${waitTimeData?.waitTimeCount} ${waitTimeData?.selectedWaitTimeType?.value}`
          }}.</strong
        >
      </p>
      <NodeStatusDropdown
        :actionStatus="actionStatus"
        :onConfirm="onConfirmActionStatus"
      />
    </div>
    <teleport to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            Add wait Time
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <p class="font-14 l-height-24 mb-24">
              This action lets your contacts wait for a specific amount of time
              before they move into further processes in the workflow.
            </p>
            <p class="font-16 m-0 mb-8">Enter Wait TIme For</p>
            <div class="d-flex justify-content-between mb-24">
              <k-input
                :class="'pt-0 mb-0 me-2'"
                type="number"
                placeholder="Enter Receiver Email"
                v-model="waitTimeData.waitTimeCount"
              >
              </k-input>
              <dropdownlist
                :style="{}"
                :class="'w-100 ms-2'"
                :data-items="waitTimeData.waitTimeTypes"
                :text-field="'text'"
                :data-item-key="'id'"
                @change="waitTimeTypeHandler"
                :value="waitTimeData.selectedWaitTimeType"
              >
              </dropdownlist>
            </div>

            <div class="mb-24">
              <p class="font-16 mb-0">Choose when to resume workflow</p>
              <div
                v-for="(item, index) in waitTimeData.resumeWorkflowTimeTypes"
                :key="index"
                class="mt-24"
              >
                <div
                  @click="handleResumeWorkflowTimeType(item)"
                  class="styled-radio"
                >
                  <input
                    :class="'mb-2'"
                    type="radio"
                    :checked="
                      item.id == waitTimeData.selectedResumeWorkflowTimeType?.id
                    "
                  />
                  <label for="">{{ item.text }}</label>
                </div>

                <!-- <radiobutton  :name="item.text" :value="item" :checked="true"
                  @change="handleResumeWorkflowTimeType" :label="item.text" /> -->
              </div>

              <div
                v-if="waitTimeData.selectedResumeWorkflowTimeType.id === 2"
                class="d-flex justify-content-between align-items-center mb-4"
              >
                <div>
                  <TimePicker
                    :class="'mb-0'"
                    :style="{}"
                    :size="size"
                    :fill-mode="fillMode"
                    :rounded="rounded"
                    :value="waitTimeData.resumeWorkflowStartTime"
                    @change="handleResumeWorkflowStartTime"
                  />
                </div>
                <div class="ms-2 me-2">to</div>
                <div>
                  <TimePicker
                    :class="'mb-0'"
                    :style="{}"
                    :size="size"
                    :fill-mode="fillMode"
                    :rounded="rounded"
                    :value="waitTimeData.resumeWorkflowEndTime"
                    @change="handleResumeWorkflowEndTime"
                  />
                </div>
              </div>
            </div>

            <div class="mb-24">
              <p class="font-16 m-0 mb-2">Time Zone</p>
              <dropdownlist
                :data-items="this.timeData"
                :text-field="'name'"
                @open="onDropdownOpenHandler"
                :data-item-key="'id'"
                :value="waitTimeData.timeZoneValue"
                @change="this.handleTimeZoneChangeValue"
                :filterable="true"
                @filterchange="filterChange"
              >
              </dropdownlist>
            </div>

            <div class="mb-24">
              <label for="">Select when to perform this action</label>
              <div
                :class="'mb-2'"
                v-for="(item, index) in waitTimeData.dayDropdownList"
                :key="index"
              >
                <checkbox v-model="item.value" :label="item.label" />
              </div>
            </div>
          </div>
          <AdditionalFilter
            :onConfirm="onConfirmAdditionalFilter"
            :additionalFilterData="additionalFilterData"
          />
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <!-- <kbutton @click="drawerClose" class="ms-2">Cancel</kbutton> -->
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler"
              class="ms-2"
              theme-color="primary"
              >Done</kbutton
            >
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>
<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox, Input } from "@progress/kendo-vue-inputs";
import { RadioButton } from "@progress/kendo-vue-inputs";
import { TimePicker } from "@progress/kendo-vue-dateinputs";
import NodeStatusDropdown from "../../common/nodeStatusDropdown.vue";
import AdditionalFilter from "../../common/additionalFilter.vue";
import removeFromListWaitBoxIcon from "@/components/svg-icons/removeFromListWaitBoxIcon.vue";
import { useStore } from "vuex";
import { filterBy } from "@progress/kendo-data-query";

export default {
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    "k-input": Input,
    radiobutton: RadioButton,
    TimePicker,
    NodeStatusDropdown,
    AdditionalFilter,
    removeFromListWaitBoxIcon,
  },
  data() {
    const nowDate = new Date();
    nowDate.setHours(nowDate.getHours() + 1);
    return {
      size: "medium",
      rounded: "medium",
      fillMode: "solid",
      waitTimeData: {
        waitTimeCount: 7,
        waitTimeTypes: [
          { id: 1, text: "Day(s)", value: "Days" },
          { id: 2, text: "Hour(s)", value: "Hours" },
          { id: 3, text: "Minute(s)", value: "Minutes" },
        ],
        selectedWaitTimeType: { id: 1, text: "Day(s)", value: "Days" },

        resumeWorkflowTimeTypes: [
          { id: 1, text: "Immediately after the above wait duration" },
          { id: 2, text: "Between" },
        ],
        selectedResumeWorkflowTimeType: {
          id: 1,
          text: "Immediately after the above wait duration",
        },

        resumeWorkflowStartTime: new Date(),
        resumeWorkflowEndTime: nowDate,

        timeZoneValue: "America/New_York",

        dayDropdownList: [
          {
            label: "Sat",
            id: 1,
            value: true,
          },
          {
            label: "Sun",
            id: 2,
            value: true,
          },
          {
            label: "Mon",
            id: 3,
            value: true,
          },
          {
            label: "Tue",
            id: 4,
            value: true,
          },
          {
            label: "Wed",
            id: 5,
            value: true,
          },
          {
            label: "Thu",
            id: 6,
            value: true,
          },
          {
            label: "Fri",
            id: 7,
            value: true,
          },
        ],
      },
      additionalFilterData: null,
      actionStatus: "Draft",
      timeData: [],
    };
  },
  computed: {
    timeZonesList() {
      return this.$store.state.commonEmailCampaign.timeZoneList;
    },
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  methods: {
    filterChange(event) {
      this.timeData = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.timeZonesList.slice();
      return filterBy(data, filter);
    },
    onDropdownOpenHandler() {
      this.timeData = structuredClone(this.timeZonesList);
    },
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    onConfirmAdditionalFilter(data) {
      this.additionalFilterData = data;
      this.$store.commit("SET_SEGMENT_MODALOPENCLOSE", false);
    },
    onConfirmActionStatus(data) {
      this.actionStatus = data;
      this.updateDataHandler();
    },
    handleTimeZoneChangeValue(e) {
      this.waitTimeData.timeZoneValue = e.value;
    },
    waitTimeTypeHandler(e) {
      this.waitTimeData.selectedWaitTimeType = e.value;
    },

    handleResumeWorkflowStartTime(e) {
      this.waitTimeData.resumeWorkflowStartTime = e.value;
    },

    handleResumeWorkflowEndTime(e) {
      this.waitTimeData.resumeWorkflowEndTime = e.value;
    },

    handleResumeWorkflowTimeType(item) {
      this.waitTimeData.selectedResumeWorkflowTimeType = item;
    },

    reloadToDefaultState() {},
    drawerClose() {
      this.cancelClick();
      // this.reloadToDefaultState();
    },
    onConfirmDrawerHandler() {
      this.updateDataHandler();
      this.drawerCloseHandler();
    },

    updateDataHandler() {
      this.updateNodeData({
        id: 1,
        ...this.waitTimeData,
        additionalFilterData: this.additionalFilterData,
        actionStatus: this.actionStatus,
      });
    },

    beforeCloseHandler() {
      this.onConfirmDrawerHandler();
    },

    stateReloaderFunction(nodeData) {
      const { actionStatus, additionalFilterData, ...waitTimeData } = nodeData;
      // ;

      if (nodeData?.id) {
        this.waitTimeData = {
          ...waitTimeData,
          resumeWorkflowStartTime: new Date(
            waitTimeData.resumeWorkflowStartTime
          ),
          resumeWorkflowEndTime: new Date(waitTimeData.resumeWorkflowEndTime),
        };
        this.additionalFilterData = nodeData.additionalFilterData;
        this.actionStatus = nodeData.actionStatus;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      if (nodeData?.id) {
        this.stateReloaderFunction(nodeData);
      }
      this.updateDataHandler();
    }, 100);

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    this.waitTimeData.timeZoneValue = this.timeZonesList.find((item) => {
      return item.name === timeZone;
    });
  },
  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
