<template>
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.2 5.43096C9.8292 5.43162 9.46768 5.54718 9.16522 5.76175C8.86276 5.97632 8.63423 6.27935 8.51107 6.62914C6.71411 6.62074 5.35513 6.55174 4.47555 6.09755C3.9634 5.84807 3.51539 5.4843 3.16607 5.03428C2.81675 4.58426 2.57546 4.06003 2.4608 3.502C2.85503 3.34839 3.18277 3.06115 3.38674 2.69046C3.59072 2.31977 3.65798 1.8892 3.57676 1.47396C3.49554 1.05872 3.27102 0.685217 2.94241 0.418695C2.6138 0.152174 2.20199 0.00957938 1.77893 0.0158216C1.35587 0.0220638 0.948456 0.176746 0.627851 0.452846C0.307247 0.728947 0.0938395 1.10891 0.0249094 1.52637C-0.0440207 1.94382 0.035909 2.37222 0.250735 2.73673C0.465561 3.10124 0.801627 3.37869 1.20022 3.5206V9.14128C0.799903 9.28282 0.462506 9.56132 0.247664 9.92756C0.032822 10.2938 -0.0456314 10.7242 0.0261699 11.1427C0.0979712 11.5612 0.315404 11.9408 0.640038 12.2145C0.964673 12.4882 1.37561 12.6383 1.80021 12.6383C2.22481 12.6383 2.63575 12.4882 2.96038 12.2145C3.28502 11.9408 3.50245 11.5612 3.57425 11.1427C3.64605 10.7242 3.5676 10.2938 3.35276 9.92756C3.13791 9.56132 2.80052 9.28282 2.4002 9.14128V5.98715C2.82999 6.47176 3.34719 6.8711 3.92477 7.16433C5.03294 7.73551 6.54071 7.81951 8.50987 7.82911C8.6201 8.1396 8.81357 8.41382 9.06914 8.62176C9.32471 8.82971 9.63252 8.9634 9.95894 9.00819C10.2854 9.05299 10.6178 9.00719 10.92 8.87578C11.2221 8.74438 11.4823 8.53243 11.6721 8.26312C11.8619 7.9938 11.974 7.67749 11.9962 7.34875C12.0183 7.02002 11.9497 6.69152 11.7977 6.39918C11.6458 6.10683 11.4164 5.86189 11.1346 5.69113C10.8528 5.52037 10.5295 5.43037 10.2 5.43096Z" fill="#ffffff"/>
    </svg>
</template>
<script>
    export default {
        name: "mergeBoxIcon"
    }
</script>
<style scoped></style>