<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 19.5L3 12M3 12L10.5 4.5M3 12H21" stroke="#24243A" stroke-width="1.5" stroke-linecap="round"
        stroke-linejoin="round" />
    </svg>
</template>
<script>
    export default {
        name: "BackPageIcon"
    }
</script>
<style scoped></style>