<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.636 7.26918C13.636 8.2336 13.2529 9.15852 12.5709 9.84047C11.889 10.5224 10.9641 10.9055 9.99964 10.9055C9.03522 10.9055 8.1103 10.5224 7.42835 9.84047C6.7464 9.15852 6.36328 8.2336 6.36328 7.26918C6.36328 6.30475 6.7464 5.37983 7.42835 4.69788C8.1103 4.01593 9.03522 3.63281 9.99964 3.63281C10.9641 3.63281 11.889 4.01593 12.5709 4.69788C13.2529 5.37983 13.636 6.30475 13.636 7.26918ZM11.8178 7.26918C11.8178 7.75139 11.6263 8.21385 11.2853 8.55482C10.9443 8.8958 10.4819 9.08736 9.99964 9.08736C9.51743 9.08736 9.05497 8.8958 8.714 8.55482C8.37302 8.21385 8.18146 7.75139 8.18146 7.26918C8.18146 6.78696 8.37302 6.3245 8.714 5.98353C9.05497 5.64255 9.51743 5.45099 9.99964 5.45099C10.4819 5.45099 10.9443 5.64255 11.2853 5.98353C11.6263 6.3245 11.8178 6.78696 11.8178 7.26918Z" fill="#6C707D"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47727 0 0 4.47727 0 10C0 15.5227 4.47727 20 10 20C15.5227 20 20 15.5227 20 10C20 4.47727 15.5227 0 10 0ZM1.81818 10C1.81818 11.9 2.46636 13.6491 3.55273 15.0382C4.31566 14.0363 5.29991 13.2243 6.42858 12.6657C7.55724 12.1072 8.79977 11.8171 10.0591 11.8182C11.3021 11.817 12.529 12.0996 13.6463 12.6443C14.7636 13.1891 15.7418 13.9817 16.5064 14.9618C17.294 13.9287 17.8244 12.7229 18.0535 11.4442C18.2827 10.1654 18.204 8.85049 17.8241 7.60817C17.4441 6.36585 16.7738 5.23186 15.8686 4.30003C14.9634 3.36821 13.8493 2.66533 12.6185 2.24955C11.3877 1.83378 10.0756 1.71707 8.79078 1.90907C7.50593 2.10107 6.28526 2.59627 5.22979 3.35369C4.17432 4.11111 3.31437 5.10897 2.72112 6.26472C2.12786 7.42047 1.81835 8.70088 1.81818 10ZM10 18.1818C8.12177 18.1846 6.30024 17.5385 4.84364 16.3527C5.42994 15.5134 6.21031 14.8281 7.11836 14.3552C8.02641 13.8822 9.03526 13.6356 10.0591 13.6364C11.0701 13.6356 12.0668 13.876 12.9663 14.3377C13.8658 14.7993 14.6422 15.469 15.2309 16.2909C13.763 17.5152 11.9114 18.1845 10 18.1818Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "profileUpdateIcon"
    }
</script>
<style scoped></style>