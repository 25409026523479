<template>
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.636 9.26918C15.636 10.2336 15.2529 11.1585 14.5709 11.8405C13.889 12.5224 12.9641 12.9055 11.9996 12.9055C11.0352 12.9055 10.1103 12.5224 9.42835 11.8405C8.7464 11.1585 8.36328 10.2336 8.36328 9.26918C8.36328 8.30475 8.7464 7.37983 9.42835 6.69788C10.1103 6.01593 11.0352 5.63281 11.9996 5.63281C12.9641 5.63281 13.889 6.01593 14.5709 6.69788C15.2529 7.37983 15.636 8.30475 15.636 9.26918ZM13.8178 9.26918C13.8178 9.75139 13.6263 10.2138 13.2853 10.5548C12.9443 10.8958 12.4819 11.0874 11.9996 11.0874C11.5174 11.0874 11.055 10.8958 10.714 10.5548C10.373 10.2138 10.1815 9.75139 10.1815 9.26918C10.1815 8.78696 10.373 8.3245 10.714 7.98353C11.055 7.64255 11.5174 7.45099 11.9996 7.45099C12.4819 7.45099 12.9443 7.64255 13.2853 7.98353C13.6263 8.3245 13.8178 8.78696 13.8178 9.26918Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.47727 2 2 6.47727 2 12C2 17.5227 6.47727 22 12 22C17.5227 22 22 17.5227 22 12C22 6.47727 17.5227 2 12 2ZM3.81818 12C3.81818 13.9 4.46636 15.6491 5.55273 17.0382C6.31566 16.0363 7.29991 15.2243 8.42858 14.6657C9.55724 14.1072 10.7998 13.8171 12.0591 13.8182C13.3021 13.817 14.529 14.0996 15.6463 14.6443C16.7636 15.1891 17.7418 15.9817 18.5064 16.9618C19.294 15.9287 19.8244 14.7229 20.0535 13.4442C20.2827 12.1654 20.204 10.8505 19.8241 9.60817C19.4441 8.36585 18.7738 7.23186 17.8686 6.30003C16.9634 5.36821 15.8493 4.66533 14.6185 4.24955C13.3877 3.83378 12.0756 3.71707 10.7908 3.90907C9.50593 4.10107 8.28526 4.59627 7.22979 5.35369C6.17432 6.11111 5.31437 7.10897 4.72112 8.26472C4.12786 9.42047 3.81835 10.7009 3.81818 12ZM12 20.1818C10.1218 20.1846 8.30024 19.5385 6.84364 18.3527C7.42994 17.5134 8.21031 16.8281 9.11836 16.3552C10.0264 15.8822 11.0353 15.6356 12.0591 15.6364C13.0701 15.6356 14.0668 15.876 14.9663 16.3377C15.8658 16.7993 16.6422 17.469 17.2309 18.2909C15.763 19.5152 13.9114 20.1845 12 20.1818Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        name: "profileUpdateBoxIcon"
    } 
</script>
<style scoped></style>