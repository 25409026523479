<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 13V9C11 8.45 10.55 8 10 8H6V6H11V4H8.5V3H6.5V4H5C4.45 4 4 4.45 4 5V9C4 9.55 4.45 10 5 10H9V12H4V14H6.5V15H8.5V14H10C10.55 14 11 13.55 11 13ZM19.59 12.52L13.93 18.17L11.1 15.34L9.69 16.76L13.93 21L21 13.93L19.59 12.52Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "priceDropIcon"
    }
</script>
<style scoped></style>