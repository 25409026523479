<template>
    <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 9H4V11H6V9ZM10 9H8V11H10V9ZM14 9H12V11H14V9ZM18 2H15V0H13V2H5V0H3V2H0V20H18V2ZM16 18H2V7H16V18Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "dateAttributeDropIcon"
    }
</script>
<style scoped></style>