<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1 2.5C1 1.67188 1.67188 1 2.5 1C3.32812 1 4 1.67188 4 2.5C4 3.32812 3.32812 4 2.5 4C1.67188 4 1 3.32812 1 2.5ZM15 2H6V3H15V2ZM15 7H6V8H15V7ZM6 12H15V13H6V12ZM2.5 6C1.67188 6 1 6.67188 1 7.5C1 8.32812 1.67188 9 2.5 9C3.32812 9 4 8.32812 4 7.5C4 6.67188 3.32812 6 2.5 6ZM1 12.5C1 11.6719 1.67188 11 2.5 11C3.32812 11 4 11.6719 4 12.5C4 13.3281 3.32812 14 2.5 14C1.67188 14 1 13.3281 1 12.5Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        name: "tagRemovedBoxIcon"
    }
</script>
<style scoped></style>