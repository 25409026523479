<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'triggerBoxIcon'"></component>
      </span>
      <span class="me-auto">Date Attribute</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p v-html="stateMessage"></p>
    </div>
    <teleport to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            Date Attribute
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <div class="pb-14">
              <p class="font-12 l-height-24 fw-medium m-0">Date Attribute</p>
              <dropdownlist
                :class="'h-38 drop-icon-2'"
                :style="{ width: '100%' }"
                :data-items="dateAttributeFields"
                :text-field="'alterText'"
                :data-item-key="'id'"
                :value="dateAttributeConfiguration.selectedDateAttributeStatus"
                :default-item="{
                  id: null,
                  alterText: 'Select Date Attribute',
                }"
                @change="
                  handleDropdownChange(
                    $event.value,
                    'selectedDateAttributeStatus'
                  )
                "
              >
              </dropdownlist>
            </div>
            <div class="border-bottom-normal-1 mx--15"></div>
            <div class="py-16">
              <p class="font-13 l-height-24 font-w-500 mx-0 mt-0 mb-10">
                Set when your flow starts
              </p>
              <div class="d-flex justify-content-between mb-14">
                <radiogroup
                  :data-items="dataItems"
                  v-model="dateAttributeConfiguration.flowStarts"
                />
              </div>
              <div
                v-if="dateAttributeConfiguration.flowStarts != 'on_the_date'"
                class="d-flex justify-content-start align-items-center gap-2 pl-21"
              >
                <p class="font-14 m-0">For</p>
                <k-input
                  type="number"
                  onkeydown="return event.keyCode !== 69"
                  placeholder="30"
                  aria-required="true"
                  :class="'w-50 h-30 m-0 radius-4'"
                  v-model="dateAttributeConfiguration.flowStartTimePeriod"
                >
                </k-input>
                <p class="font-14 m-0">days period</p>
              </div>
            </div>
            <div class="border-top-normal-1"></div>
            <div class="mb-4">
              <div class="border-bottom-normal-1">
                <div class="d-flex pt-14 pb-10">
                  <div class="w-42">
                    <k-switch
                      :class="'w-34 h-18 switch-34-18'"
                      :size="'small'"
                      v-model="
                        dateAttributeConfiguration.desired_time_for_flow_execution
                      "
                    />
                  </div>
                  <p class="font-14 font-w-500 m-0">
                    Choose desired time for flow execution
                  </p>
                </div>
                <div
                  v-if="
                    dateAttributeConfiguration.desired_time_for_flow_execution
                  "
                  class="pb-14"
                >
                  <div class="mb-4 w-100">
                    <p class="font-12 l-height-24 font-w-500 m-0">Time</p>
                    <TimePicker
                      :class="'mb-0 w-100 k-input-mb-0'"
                      :style="{}"
                      :size="size"
                      :fill-mode="fillMode"
                      :rounded="rounded"
                      :value="
                        new Date(
                          dateAttributeConfiguration.resumeWorkflowStartTime
                        )
                      "
                      @change="handleResumeWorkflowStartTime"
                    />
                  </div>
                  <div class="mb-0 w-100">
                    <p class="font-12 l-height-24 font-w-500 m-0">Time Zone</p>
                    <dropdownlist
                      :data-items="this.timeData"
                      :text-field="'name'"
                      @open="onDropdownOpenHandler"
                      :data-item-key="'id'"
                      :value="this.dateAttributeConfiguration.timeZoneValue"
                      @change="this.handleTimeZoneChangeValue"
                      :filterable="true"
                      @filterchange="filterChange"
                    >
                    </dropdownlist>
                  </div>
                </div>
              </div>
              <div>
                <div class="d-flex py-14">
                  <div class="w-42">
                    <k-switch
                      :class="'w-34 h-18 switch-34-18'"
                      :size="'small'"
                      v-model="
                        dateAttributeConfiguration.specific_days_the_week
                      "
                    />
                  </div>
                  <p class="font-14 font-w-500 m-0">
                    Schedule for specific days of the week
                  </p>
                </div>
                <div v-if="dateAttributeConfiguration.specific_days_the_week">
                  <div
                    v-for="(item, index) in this.dateAttributeConfiguration
                      .dayDropdownList"
                    :key="index"
                  >
                    <checkbox
                      :class="'font-14 cursor-pointer checkbox-style-2 mb-14'"
                      v-model="item.value"
                      :label="item.label"
                    />
                  </div>
                </div>
              </div>
              <div class="pb-14">
                <p class="font-12 l-height-24 fw-medium m-0">Flow Repetition Options</p>

                <dropdownlist
                  :class="'h-38 drop-icon-2'"
                  :style="{ width: '100%' }"
                  :data-items="receptionStatus"
                  :text-field="'text'"
                  :data-item-key="'id'"
                  :value="dateAttributeConfiguration.selectedFlowRepetition"
                  @change="
                    handleDropdownChange($event.value, 'selectedFlowRepetition')
                  "
                >
                </dropdownlist>

                <div
                  v-if="
                    dateAttributeConfiguration.selectedFlowRepetition.id == 3
                  "
                  class="pt-14"
                >
                  <p class="font-12 l-height-24 fw-medium m-0">Every</p>
                  <div class="d-flex gap-2">
                    <k-input
                      :placeholder="'Enter value (e.g., 10)'"
                      @input="
                        validateNumber(
                          $event,
                          dateAttributeConfiguration,
                          'flowRepetitionCount'
                        )
                      "
                      :value="dateAttributeConfiguration.flowRepetitionCount"
                      :class="'w-50-p h-38 mb-0'"
                    />
                    <dropdownlist
                      :class="'h-38 drop-icon-2 w-50-p ms-auto'"
                      :style="{ width: '100%' }"
                      :data-items="customDayStatus"
                      :text-field="'text'"
                      :data-item-key="'id'"
                      :value="dateAttributeConfiguration.flowRepetitionType"
                      @change="
                        handleDropdownChange($event.value, 'flowRepetitionType')
                      "
                    >
                    </dropdownlist>
                  </div>
                </div>
              </div>
              <div class="border-bottom-normal-1 mx--15"></div>
            </div>
            <configureFlowFilterComponent
              :onConfirm="onConfirmFlowFilter"
              :additionalFilterData="additionalFilterData"
            />
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <!-- <kbutton @click="drawerClose" class="ms-2">Cancel</kbutton> -->
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler"
              class="ms-2"
              theme-color="primary"
              >Apply Trigger
            </kbutton>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button, DropDownButton } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import triggerBoxIcon from "@/components/svg-icons/triggerBoxIcon.vue";
import configureFlowFilterComponent from "../../common/configureFlowFilterComponent.vue";
import { TimePicker } from "@progress/kendo-vue-dateinputs";
import { useStore } from "vuex";
import { filterBy } from "@progress/kendo-data-query";
import NoDataRender from "@/components/common/NoDataRender/NoDataRender.vue";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import { Switch } from "@progress/kendo-vue-inputs";
import moment from "moment";

export default {
  components: {
    "k-input": Input,
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    triggerBoxIcon,
    configureFlowFilterComponent,
    custom: NoDataRender,
    multiselect: MultiSelect,
    radiogroup: RadioGroup,
    "k-switch": Switch,
    DropDownButton,
    TimePicker,
  },
  data() {
    return {
      dataItems: [
        {
          label: "On the date",
          value: "on_the_date",
        },
        {
          label: "Before the date",
          value: "before_the_date",
        },
      ],
      dateAttributeConfiguration: {
        selectedDateAttributeStatus: null,
        selectedFlowRepetition: { text: "Custom", id: 3 },
        flowRepetitionType: { text: "Day(s)", id: 0 },
        flowStarts: "on_the_date",
        currentDate: new Date(),
        flowStartTimePeriod: 1,
        flowRepetitionCount: null,

        desired_time_for_flow_execution: false,
        specific_days_the_week: false,
        resumeWorkflowStartTime: new Date(),
        formattedResumeWorkflowStartTime: null,
        timeZoneValue: "America/New_York",
        clubTimeZoneValue: null,

        dayDropdownList: [
          {
            label: "Monday",
            shortName: "Mon",
            id: 3,
            value: false,
          },
          {
            label: "Tuesday",
            shortName: "Tue",
            id: 4,
            value: false,
          },
          {
            label: "Wednesday",
            shortName: "Wed",
            id: 5,
            value: false,
          },
          {
            label: "Thursday",
            shortName: "Thu",
            id: 6,
            value: false,
          },
          {
            label: "Friday",
            shortName: "Fri",
            id: 7,
            value: false,
          },
          {
            label: "Saturday",
            shortName: "Sat",
            id: 1,
            value: false,
          },
          {
            label: "Sunday",
            shortName: "Sun",
            id: 2,
            value: false,
          },
        ],
      },

      isConfigureFlowFilterComponentAccordion: true,
      receptionStatus: [
        { text: "Custom", id: 3 },
        { text: "Month(s)", id: 0 },
        { text: "Year(s)", id: 1 },
        { text: "Never", id: 2 },
      ],
      customDayStatus: [
        { text: "Day(s)", id: 0 },
        { text: "Month(s)", id: 1 },
        { text: "Year(s)", id: 2 },
      ],
      selectedTriggerValue: {},
      additionalFilterData: null,
      timeData: [],
    };
  },
  methods: {
    validateNumber(event, condition, jsonvalue) {
      let value = event.target.value;
      const numericValue = value.replace(/[^0-9]/g, "");
      condition[jsonvalue] = numericValue;
    },
    filterChange(event) {
      this.timeData = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = this.timeZonesList.slice();
      return filterBy(data, filter);
    },
    onDropdownOpenHandler() {
      this.timeData = structuredClone(this.timeZonesList);
    },
    handleTimeZoneChangeValue(e) {
      this.dateAttributeConfiguration.timeZoneValue = e.value;
    },
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    onConfirmFlowFilter(data) {
      this.additionalFilterData = data;
      this.updateDataHandler();
    },
    handleResumeWorkflowStartTime(e) {
      this.dateAttributeConfiguration.resumeWorkflowStartTime = e.value;
      this.dateAttributeConfiguration.formattedResumeWorkflowStartTime =
        this.generateTime(e.value);
    },
    generateTime(date) {
      return moment(date).format("hh:mm A");
    },
    handleDropdownChange(value, field) {
      this.dateAttributeConfiguration[field] = value;
    },
    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        this.selectedTriggerValue = nodeData.selectedTriggerValue;
        this.additionalFilterData = nodeData.additionalFilterData;
        this.dateAttributeConfiguration = JSON.parse(
          JSON.stringify(nodeData.dateAttributeConfiguration)
        );
      }
    },
    drawerClose() {
      this.cancelClick();
    },
    onConfirmDrawerHandler() {
      this.updateDataHandler();
      this.drawerCloseHandler();
    },
    updateDataHandler() {
      const payload = {
        id: this.nodeId,
        selectedTriggerValue: { id: 1, text: "Date Attribute" },
        dateAttributeConfiguration: this.dateAttributeConfiguration,
        additionalFilterData: this.additionalFilterData,
      };
      this.updateNodeData(payload);
    },
    getDateAttributeName(attribute) {
      return (
        this.dateAttributeFields.find((item) => item.id === attribute.id)
          ?.alterText || "Unknown Date Attribute"
      );
    },

    getRepeatFrequency(status) {
      const repetition = {
        0: "Month(s)",
        1: "Year(s)",
        3: "Custom",
      };
      if (status.id == 0) {
        return `Every Month`;
      } else if (status.id == 1) {
        return `Every Year`;
      } else if (status.id == 3) {
        const customValue = this.dateAttributeConfiguration.flowRepetitionType;
        const flowRepetitionCount =
          this.dateAttributeConfiguration.flowRepetitionCount || 0;
        const unit = this.getCustomUnit(customValue);
        return `Custom Interval (${flowRepetitionCount} ${unit})`;
      }

      return repetition[status.id] || "No Repetition";
    },

    getCustomUnit(customValue) {
      if (!customValue) return "";
      if (customValue.id == 0) return "days";
      if (customValue.id == 1) return "Month(s)";
      if (customValue.id == 2) return "Year(s)";
      return "";
    },
    timeZoneInit() {
      let timeZone = this.club.timeZoneId;

      if (timeZone != 999) {
        return this.timeZonesList.find((item) => item.id === timeZone);
      } else {
        return this.timeZonesList.find(
          (item) =>
            item.name === Intl.DateTimeFormat().resolvedOptions().timeZone
        );
      }
    },
  },
  computed: {
    club() {
      return this.$store.state.club.club;
    },
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
    timeZonesList() {
      return this.$store.state.commonEmailCampaign.timeZoneList;
    },
    dateAttributeFields() {
      return this.$store.state?.flowAutomation?.dateAttributeFields;
    },
    stateMessage() {
      const config = this.dateAttributeConfiguration;

      if (!config.selectedDateAttributeStatus || !config.flowStarts) {
        return "Trigger on a specific date or timeframe relative to a date field. Configure the date and timing to continue.";
      }
      const timePeriod = config.flowStartTimePeriod
        ? config.flowStartTimePeriod
        : 0;
      const dateAttributeName = this.getDateAttributeName(
        config.selectedDateAttributeStatus
      );
      const repeatFrequency = this.getRepeatFrequency(
        config.selectedFlowRepetition
      );
      const flowStarts =
        config.flowStarts === "on_the_date"
          ? `Trigger set for <strong>${dateAttributeName}</strong>, with repetition set to <strong>${repeatFrequency}</strong>.`
          : `Trigger set for <strong>${dateAttributeName}</strong>, starting <strong>${timePeriod}</strong> days before the date, with repetition set to <strong>${repeatFrequency}</strong>.`;

      return flowStarts;
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
    timeZonesList: {
      handler: function (val) {
        this.dateAttributeConfiguration.timeZoneValue = this.timeZonesList.find(
          (item) => item.id == 999
        );
      },
      immediate: true,
    },
  },
  mounted() {
    setTimeout(() => {
      this.dateAttributeConfiguration.formattedResumeWorkflowStartTime =
        this.generateTime(new Date());
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
      this.updateDataHandler();
    }, 100);
    this.dateAttributeConfiguration.clubTimeZoneValue = this.timeZoneInit();
  },

  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
