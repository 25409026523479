<template>
  <AppSnackBar
    :message="notifyMessage"
    :notificationType="notifyType"
    v-if="isNotify"
  ></AppSnackBar>
  <AppSnackBar
    :message="notificationObject?.notifyMessage"
    :notificationType="notificationObject?.notifyType"
    v-if="notificationObject?.isNotify"
  ></AppSnackBar>
  <app-loader
    v-if="
      isLoading ||
      loading ||
      isMetricsTypeListLoading ||
      isTimeZoneLoading ||
      isEmailCampaignTemplateMacroListsLoading ||
      isProfileUpdateFieldsLoading ||
      isDateAttributeFieldsLoading
    "
  ></app-loader>
  <el-container class="min-h-100vh">
    <el-header class="header bg-white p-0 h-auto">
      <!-- <h3>Workflow automation</h3>
      <el-button type="primary" @click="exportEditor">Export</el-button> -->
      <div
        class="builder-top-nav bg-white d-flex justify-content-between w-100 py-10"
      >
        <div class="d-flex align-items-center">
          <div class="d-flex justify-content-start align-content-center">
            <kbutton @click="backNavigationHandler" :fill-mode="'flat'">
              <component :is="'BackPageIcon'"></component>
            </kbutton>
            <div class="d-flex justify-content-start align-content-center">
              <h6 class="font-16 mb-0">
                {{ flowName }}
                <kbutton
                  :icon="'pencil'"
                  :fill-mode="'flat'"
                  ref="templateNameRef"
                  @click="nameEditModalHandler"
                >
                </kbutton>
                <!-- @click="nameEditModalHandler" -->
              </h6>
              <Popup
                :anchor="'templateNameRef'"
                :show="nameEditModalVisibility"
                :popup-class="'advance-filter-popup'"
              >
                <div
                  v-click-outside="onCancelNameEditModal"
                  class="advance-filter-popup-wrap"
                >
                  <div
                    class="head d-flex justify-content-between align-items-center p-16"
                  >
                    <h5 class="font-16 m-0">Flow Automation</h5>
                    <kbutton
                      :fill-mode="'flat'"
                      @click="onCancelNameEditModal"
                      :icon="'close'"
                    ></kbutton>
                  </div>
                  <div class="p-24">
                    <div class="k-form-field text-start">
                      <label class="" for="">Flow Automation Name</label>
                      <kinput
                        type="text"
                        v-model="flowNameForEdit"
                        placeholder="Enter Flow Name"
                      >
                      </kinput>
                      <div class="error-msg">
                        {{ this.flowNameError }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="me-1 w-50-p">
                        <kbutton
                          @click="onCancelNameEditModal"
                          :class="'w-100'"
                        >
                          Cancel
                        </kbutton>
                      </div>
                      <div class="ms-1 w-50-p">
                        <kbutton
                          :disabled="!flowNameForEdit"
                          @click="onSaveTemplateName"
                          :theme-color="'primary'"
                          :class="'w-100'"
                        >
                          Save
                        </kbutton>
                      </div>
                    </div>
                  </div>
                </div>
              </Popup>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center pe-3">
          <div class="preview-save d-flex">
            <kbutton
              @click="backNavigationHandler"
              :fill-mode="'flat'"
              :class="'h-38 me-3 '"
              >Cancel & Exit</kbutton
            >
            <kbutton
              :fill-mode="'flat'"
              :class="'h-38 me-3 '"
              @click="onSubmitFlowHandler('draft')"
              >Save as Draft
            </kbutton>
            <kbutton
              @click="onSubmitFlowHandler('live')"
              :theme-color="'primary'"
              :class="'h-38'"
              >Go Live</kbutton
            >
          </div>
        </div>
      </div>
    </el-header>

    <el-container id="teleportContainer" class="">
      <div width="" class="column w-300 flow-sidebar">
        <div class="widget-accordion-wrap">
          <div
            :class="
              flowBuilderAccordions?.triggerAccordionVisibility
                ? 'widget-accordion-item show'
                : 'widget-accordion-item'
            "
          >
            <div @click="triggerAccordion()" class="widget-accordion-head">
              <span>Choose a trigger</span>
              <span class="icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
                    fill="#24243A"
                  />
                </svg>
              </span>
            </div>
            <div class="widget-accordion-body">
              <ul>
                <li
                  v-for="node in triggerNodes"
                  :key="node"
                  draggable="true"
                  :data-node="node.item"
                  @dragstart="drag($event)"
                  class="drag-drawflow"
                >
                  <div
                    class="node d-flex align-items-center justify-content-start"
                    :style="``"
                  >
                    <component :is="node?.iconName" :class="'me-2'"></component>
                    <span>{{ node.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="widget-accordion-wrap">
          <div
            :class="
              flowBuilderAccordions?.actionAccordionVisibility
                ? 'widget-accordion-item show'
                : 'widget-accordion-item'
            "
          >
            <div @click="actionAccordion()" class="widget-accordion-head">
              <span>Actions</span>
              <span class="icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
                    fill="#24243A"
                  />
                </svg>
              </span>
            </div>
            <div class="widget-accordion-body">
              <ul>
                <li
                  v-for="node in actionNodes"
                  :key="node"
                  draggable="true"
                  :data-node="node.item"
                  @dragstart="drag($event)"
                  class="drag-drawflow"
                >
                  <div
                    class="node d-flex align-items-center justify-content-start"
                    :style="``"
                  >
                    <component :is="node?.iconName" :class="'me-2'"></component>
                    <span>{{ node.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="widget-accordion-wrap">
          <div
            :class="
              flowBuilderAccordions?.processAccordionVisibility
                ? 'widget-accordion-item show'
                : 'widget-accordion-item'
            "
          >
            <div @click="processAccordion()" class="widget-accordion-head">
              <span>Process</span>
              <span class="icon">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.06066 11.0607L8.00003 7.12128L11.9394 11.0607L13 10L8.00003 5L3 10L4.06066 11.0607Z"
                    fill="#24243A"
                  />
                </svg>
              </span>
            </div>
            <div class="widget-accordion-body">
              <ul>
                <li
                  v-for="node in processNodes"
                  :key="node"
                  draggable="true"
                  :data-node="node.item"
                  @dragstart="drag($event)"
                  class="drag-drawflow"
                >
                  <div
                    class="node d-flex align-items-center justify-content-start"
                    :style="``"
                  >
                    <component :is="node?.iconName" :class="'me-2'"></component>
                    <span>{{ node.name }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <el-main class="flow-body">
        <div id="drawflow" @drop="drop($event)" @dragover="allowDrop($event)">
          <div class="zoom-in-out-wrap">
            <!-- <p class="measure">100%</p> -->
            <kbutton
              @click="zoomIn"
              :icon="'plus'"
              :class="'h-38 w-38'"
            ></kbutton>
            <kbutton
              @click="zoomOut"
              :icon="'minus'"
              :class="'h-38 w-38'"
            ></kbutton>
          </div>
        </div>
      </el-main>
    </el-container>
  </el-container>
  <el-dialog v-model="dialogVisible" title="Export" width="50%">
    <span>Data:</span>
    <pre><code>{{ dialogData }}</code></pre>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >Confirm</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>
<script>
import Drawflow from "drawflow";
import styleDrawflow from "drawflow/dist/drawflow.min.css";
import style from "./style.scss";

import {
  onMounted,
  shallowRef,
  h,
  getCurrentInstance,
  render,
  readonly,
  ref,
  computed,
} from "vue";
import enterSegmentTrigger from "./components/triggers/enterSegmentTrigger.vue";
import metricsTrigger from "./components/triggers/metricsTrigger.vue";
import tagAssignedTrigger from "./components/triggers/tagAssignedTrigger.vue";
import tagRemovedTrigger from "./components/triggers/tagRemovedTrigger.vue";
import listEntryTrigger from "./components/triggers/listEntryTrigger.vue";
import emailActionTrigger from "./components/triggers/emailActionTrigger.vue";
import priceDropTrigger from "./components/triggers/priceDropTrigger.vue";
import dateAttributeTrigger from "./components/triggers/dateAttributeTrigger.vue";
import lowStockAlertTrigger from "./components/triggers/lowStockAlertTrigger.vue";

import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import sendEmailAction from "./components/actions/sendEmailAction.vue";
import tagAssignedAction from "./components/actions/tagAssignedAction.vue";
import tagRemovedAction from "./components/actions/tagRemovedAction.vue";
import removeFromListAction from "./components/actions/removeFromListAction.vue";
import mergeBlockAction from "./components/actions/mergeBlockAction.vue";
import addToListAction from "./components/actions/addToListAction";
import profileUpdateAction from "./components/actions/profileUpdateAction.vue";
import actionAlertAction from "./components/actions/actionAlertAction.vue";
import waitTimeAction from "./components/actions/waitTimeAction.vue";
import exitFromWorkflowAction from "./components/actions/exitFromWorkflowAction.vue";
import AppSnackBar from "../common/AppSnackBar/AppSnackBar.vue";
import AppLoader from "../common/AppLoader/AppLoader.vue";
import conditionalSplitProcess from "./components/process/conditionalSplitProcess.vue";
import {
  createFlowAutomationService,
  updateFlowAutomationService,
  getFlowDetailsService,
} from "@/services/clubDetails/flowAutomation.service";

import BackPageIcon from "../svg-icons/backPageIcon.vue";
import SendEmailIcon from "../svg-icons/sendEmailIcon.vue";
import formSubmissionIcon from "../svg-icons/formSubmissionIcon.vue";
import listEntryIcon from "../svg-icons/listEntryIcon.vue";
import enterSegmentIcon from "../svg-icons/enterSegmentIcon.vue";
import emailActionIcon from "../svg-icons/emailActionIcon.vue";
import tagAssignedIcon from "../svg-icons/tagAssignedIcon.vue";
import tagRemovedIcon from "../svg-icons/tagRemovedIcon.vue";
import metricsIcon from "../svg-icons/metricsIcon.vue";
import priceDropIcon from "../svg-icons/priceDropIcon.vue";
import dateAttributeDropIcon from "../svg-icons/dateAttributeDropIcon.vue";
import lowStockDropIcon from "../svg-icons/lowStockDropIcon.vue";
import addToListIcon from "../svg-icons/addToListIcon.vue";
import removeFromListIcon from "../svg-icons/removeFromListIcon.vue";
import updateFieldIcon from "../svg-icons/updateFieldIcon.vue";
import internalNotificationEmailIcon from "../svg-icons/internalNotificationEmailIcon.vue";
import exitFromWorkflowIcon from "../svg-icons/exitFromWorkflowIcon.vue";
import mergeBlockIcon from "../svg-icons/mergeBlockIcon.vue";
import profileUpdateIcon from "../svg-icons/profileUpdateIcon.vue";
import actionAlertIcon from "../svg-icons/actionAlertIcon.vue";
import waitTimeIcon from "../svg-icons/waitTimeIcon.vue";
import conditionalSplitIcon from "../svg-icons/conditionalSplitIcon.vue";
import { useRoute } from "vue-router";

import { Popup } from "@progress/kendo-vue-popup";
import vClickOutside from "click-outside-vue3";
import { useStore } from "vuex";
import { verifyAutoFlowNameService } from "@/services/clubDetails/flowAutomation.service";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    kbutton: Button,
    kinput: Input,
    AppSnackBar,
    Popup,
    AppLoader,

    BackPageIcon,
    SendEmailIcon,
    formSubmissionIcon,
    listEntryIcon,
    enterSegmentIcon,
    emailActionIcon,
    tagAssignedIcon,
    tagRemovedIcon,
    metricsIcon,
    priceDropIcon,
    dateAttributeDropIcon,
    lowStockDropIcon,
    addToListIcon,
    removeFromListIcon,
    updateFieldIcon,
    internalNotificationEmailIcon,
    exitFromWorkflowIcon,
    mergeBlockIcon,
    profileUpdateIcon,
    actionAlertIcon,
    waitTimeIcon,
    conditionalSplitIcon,
  },
  name: "emailWorkFlowEditor",
  data() {
    return {
      triggerAccordionVisibility: true,
      actionAccordionVisibility: false,
      processAccordionVisibility: false,

      flowName: "",
      flowIdForEdit: null,
      flowNameForEdit: "",
      nameEditModalVisibility: false,
      flowNameError: "",

      flowType: null,

      isLoading: false,
      isSendEmailAction: "hello!",
    };
  },
  methods: {
    hasFlowCampaignPostPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    hasFlowCampaignPutPermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: "emailCampaign",
        itemCode: "flowAutomation",
        childItemCode: null,
      });
    },
    getsegmentListForDropdown() {
      let payload = {
        clubId: this.$route.params.id,
        entityType: 2,
        limit: 1000,
      };
      this.$store.dispatch("getsegmentListForDropdown", payload);
    },
    dataValidation() {
      if (!this.flowNameForEdit) {
        this.showNotification("Flow name is required.", "warning");
        return false;
      }
      if (this.flowNameForEdit.length > 100) {
        this.showNotification(
          "Flow name should not exceed 100 characters.",
          "warning"
        );
        return false;
      }
      if (this.flowNameForEdit.trim().length === 0) {
        this.showNotification("Flow name should not be empty.", "warning");
        return false;
      }

      return true;
    },
    backNavigationHandler() {
      // ;
      // return;
      if (this.$route.params?.flowId || this.$route.query?.flowId) {
        this.doNavigateToFlowAutomation();
      } else {
        this.doNavigateTCreateFlow();
      }
    },
    doNavigateTCreateFlow() {
      this.$router.push({
        name: "createFlowAutomation",
        params: { id: this.$route.params.id },
      });
    },
    doNavigateToFlowAutomation() {
      this.$router.push({
        name: "flowAutomation",
        params: { id: this.$route.params.id },
      });
    },
    flowDataValidation(flowData) {
      let returnValue = false;
      if (Object.keys(flowData).length === 0) {
        this.showNotification(
          "Please ensure that there is at least one trigger and one action in your flow before going live.",
          "warning"
        );
        return false;
      }

      if (Object.keys(flowData).length != 0) {
        let haAnyNodeInputNotConnected = false;
        let haAnyNodeOutputNotConnected = false;
        let hasAnyNodeWithoutData = false;
        let isAnyActionExist = false;
        Object.keys(flowData)?.forEach((flow) => {
          if (Object.keys(flowData[flow]?.inputs) != 0) {
            Object.keys(flowData[flow]?.inputs).forEach((inputKey) => {
              if (flowData[flow]?.inputs[inputKey]?.connections?.length === 0) {
                haAnyNodeInputNotConnected = true;
              }
            });
          }

          if (Object.keys(flowData[flow]?.outputs) != 0) {
            Object.keys(flowData[flow]?.outputs).forEach((outputKey) => {
              if (
                flowData[flow]?.outputs[outputKey]?.connections?.length === 0
              ) {
                haAnyNodeOutputNotConnected = true;
              }
            });
          }

          //check if any action exist
          if (
            [
              "sendEmailAction",
              "addToListAction",
              "removeFromListAction",
              "mergeBlockAction",
              "tagAssignedAction",
              "tagRemovedAction",
              "waitTimeAction",
              "profileUpdateAction",
              "actionAlertAction",
            ].includes(flowData[flow]?.class)
          ) {
            isAnyActionExist = true;
          }

          if (
            [
              "enterSegmentTrigger",
              "listEntryTrigger",
              "emailActionTrigger",
              "tagAssignedTrigger",
              "tagRemovedTrigger",
              "metricsTrigger",
              "priceDropTrigger",
              "dateAttributeTrigger",
              "lowStockAlertTrigger",
            ].includes(flowData[flow]?.class)
          ) {
            if (
              flowData[flow]?.data?.data?.selectedTriggerValue?.id ||
              flowData[flow]?.data?.data?.selectedTriggerValue?.campaignId ||
              flowData[flow]?.data?.data?.selectedTriggerValue?.[0]?.id
            ) {
              hasAnyNodeWithoutData = false;
            } else {
              hasAnyNodeWithoutData = true;
            }
            if (["emailActionTrigger"].includes(flowData[flow]?.class)) {
              if (flowData[flow]?.data?.data?.selectedCampaignType?.id == 2) {
                hasAnyNodeWithoutData = false;
              }
            }
          }
        });

        if (
          haAnyNodeInputNotConnected ||
          haAnyNodeOutputNotConnected ||
          !isAnyActionExist
        ) {
          this.showNotification(
            "Please complete the necessary steps before going live.",
            "warning"
          );
          return false;
        }

        if (hasAnyNodeWithoutData) {
          this.showNotification(
            "Please set up your trigger before going live.",
            "warning"
          );
          return false;
        }
      }

      if (Object.keys(this.flowAutomationDrawers).length != 0) {
        let isAnyDrawerOpen = false;
        Object.keys(this.flowAutomationDrawers).forEach((drawer) => {
          if (this.flowAutomationDrawers[drawer]) {
            isAnyDrawerOpen = true;
          }
        });
        if (isAnyDrawerOpen) {
          this.showNotification(
            "Please save the configuration before going live.",
            "warning"
          );
          return false;
        }
      }
      return true;
    },
    onSubmitFlowHandler(type) {
      const flowData = this.exportEditorData()?.drawflow?.Home?.data;
      if (type == "draft" || this.flowDataValidation(flowData)) {
        //
        //   ...this.flowDetails,
        //   ...this.flowInfoForSendEmail?.flowDetails,
        //   autoFlowStatus: type == "draft" ? 1 : 2,
        // });
        // return;
        this.isLoading = true;
        if (
          this.$route.params.flowId ||
          this.flowIdForEdit ||
          this.$route.query.flowId
        ) {
          let payload = {
            ...this.flowDetails,
            ...this.flowInfoForSendEmail?.flowDetails,
            autoFlowStatus: type == "draft" ? 1 : 2,
            autoflowName: this.flowName,
            editorData: JSON.stringify(this.exportEditorData()),
          };
          payload.clubId = this.$route.params.id;
          updateFlowAutomationService(payload)
            .then((res) => {
              this.isLoading = false;
              if (payload.autoFlowStatus == 1) {
                this.showNotification(
                  "Your flow automation is saved as draft.",
                  "success"
                );
              }
              if (payload.autoFlowStatus == 2) {
                this.showNotification(
                  "Your flow automation is now live. ",
                  "success"
                );
              }
              setTimeout(() => {
                this.$router.push({
                  name: "flowAutomation",
                  params: { id: this.$route.params.id },
                });
              }, 1000);
            })
            .catch((err) => {
              this.isLoading = false;
              this.showNotification(
                err?.response?.data?.Status?.Message ||
                  "Please complete the necessary steps before going live.",
                "error"
              );
              // this.showNotification(
              //   "Please complete the necessary steps before going live.",
              //   "warning"
              // );
            });
        } else {
          let payload = {
            id: 0,
            clubId: this.$route.params.id,
            flowTemplateId: this.flowType || 1,
            autoflowName: this.flowName,
            autoFlowStatus: type == "draft" ? 1 : 2,
            editorData: JSON.stringify(this.exportEditorData()),
          };
          createFlowAutomationService(payload)
            .then((res) => {
              this.isLoading = false;
              if (payload.autoFlowStatus == 1) {
                this.showNotification(
                  "Your flow automation is saved as draft.",
                  "success"
                );
              }
              if (payload.autoFlowStatus == 2) {
                this.showNotification(
                  "Your flow automation is now live. ",
                  "success"
                );
              }
              setTimeout(() => {
                this.$router.push({
                  name: "flowAutomation",
                  params: { id: this.$route.params.id },
                });
              }, 1000);
            })
            .catch((err) => {
              this.isLoading = false;
              this.showNotification(
                err?.response?.data?.Status?.Message,
                "error"
              );
            });
        }
      }
    },
    nameEditModalHandler() {
      this.nameEditModalVisibility = true;
      this.flowNameForEdit = this.flowName;
    },
    onCancelNameEditModal() {
      this.nameEditModalVisibility = false;
      this.flowNameError = "";
    },
    onSaveTemplateName() {
      if (this.dataValidation()) {
      }

      if (this.dataValidation()) {
        this.isLoading = true;
        const payload = {
          autoflowName: this.flowNameForEdit,
          clubId: this.$route.params.id,
        };
        verifyAutoFlowNameService(payload)
          .then((response) => {
            this.isLoading = false;
            if (!response.data.data) {
              this.flowNameError = "";
              this.nameEditModalVisibility = false;
              this.flowName = this.flowNameForEdit;
              this.$store.commit("SET_FLOW_INFO_FOR_SEND_EMAIL", {
                flowName: this.flowName,
              });
            } else {
              this.flowNameError = "Flow name already exists.";
              return;
            }
          })
          .catch((err) => {
            this.flowNameError = "";
            this.nameEditModalVisibility = false;
            this.isLoading = false;
            this.showNotification("Something went wrong.", "error");
          });
      }
    },
    actionAccordion() {
      this.$store.commit("UPDATE_FLOW_BUILDER_ACCORDIONS", {
        actionAccordionVisibility:
          !this?.flowBuilderAccordions?.actionAccordionVisibility,
      });
    },
    processAccordion() {
      this.$store.commit("UPDATE_FLOW_BUILDER_ACCORDIONS", {
        processAccordionVisibility:
          !this?.flowBuilderAccordions?.processAccordionVisibility,
      });
    },
    triggerAccordion() {
      this.$store.commit("UPDATE_FLOW_BUILDER_ACCORDIONS", {
        triggerAccordionVisibility:
          !this?.flowBuilderAccordions?.triggerAccordionVisibility,
      });
    },
  },
  setup() {
    const triggerNodes = readonly([
      {
        name: "List Entry",
        item: "listEntryTrigger",
        input: 0,
        output: 1,
        iconName: "listEntryIcon",
      },
      {
        name: "Enter a Segment",
        item: "enterSegmentTrigger",
        input: 0,
        output: 1,
        iconName: "enterSegmentIcon",
      },
      {
        name: "Email Action",
        item: "emailActionTrigger",
        input: 0,
        output: 1,
        iconName: "emailActionIcon",
      },
      {
        name: "Tag Assigned",
        item: "tagAssignedTrigger",
        input: 0,
        output: 1,
        iconName: "tagAssignedIcon",
      },
      {
        name: "Tag Removed",
        item: "tagRemovedTrigger",
        input: 0,
        output: 1,
        iconName: "tagRemovedIcon",
      },
      {
        name: "Metrics",
        item: "metricsTrigger",
        input: 0,
        output: 1,
        iconName: "metricsIcon",
      },
      {
        name: "Price Drop",
        item: "priceDropTrigger",
        input: 0,
        output: 1,
        iconName: "priceDropIcon",
      },
      /*{
        name: "Date Attribute",
        item: "dateAttributeTrigger",
        input: 0,
        output: 1,
        iconName: "dateAttributeDropIcon",
      },*/
      {
        name: "Low Stock Alert",
        item: "lowStockAlertTrigger",
        input: 0,
        output: 1,
        iconName: "lowStockDropIcon",
      },
    ]);

    const actionNodes = readonly([
      {
        name: "Send Email",
        item: "sendEmailAction",
        input: 1,
        output: 1,
        iconName: "SendEmailIcon",
      },
      {
        name: "Add To List",
        item: "addToListAction",
        input: 1,
        output: 1,
        iconName: "addToListIcon",
      },
      {
        name: "Remove From List",
        item: "removeFromListAction",
        input: 1,
        output: 1,
        iconName: "removeFromListIcon",
      },
      {
        name: "Tag Assigned",
        item: "tagAssignedAction",
        input: 1,
        output: 1,
        iconName: "tagAssignedIcon",
      },
      {
        name: "Tag Removed",
        item: "tagRemovedAction",
        input: 1,
        output: 1,
        iconName: "tagRemovedIcon",
      },
      {
        name: "Wait Time",
        item: "waitTimeAction",
        input: 1,
        output: 1,
        iconName: "waitTimeIcon",
      },
      {
        name: "Merge Block",
        item: "mergeBlockAction",
        input: 2,
        output: 1,
        iconName: "mergeBlockIcon",
      },
      {
        name: "Profile Update",
        item: "profileUpdateAction",
        input: 1,
        output: 1,
        iconName: "profileUpdateIcon",
      },
      {
        name: "Action Alert",
        item: "actionAlertAction",
        input: 1,
        output: 1,
        iconName: "actionAlertIcon",
      },
      {
        name: "Exit",
        item: "exitFromWorkflowAction",
        input: 1,
        output: 0,
        iconName: "exitFromWorkflowIcon",
      },
    ]);

    const processNodes = readonly([
      {
        name: "Conditional Split",
        item: "conditionalSplitProcess",
        input: 1,
        output: 2,
        iconName: "conditionalSplitIcon",
      },
    ]);

    const listNodes = readonly([
      ...triggerNodes,
      ...actionNodes,
      ...processNodes,
    ]);
    const notifyMessage = ref("");
    const notifyType = ref("");
    const isNotify = ref(false);
    const route = useRoute();
    const store = useStore();

    let flowDetails = ref(null);
    let loading = ref(false);

    const triggerNames = [
      "enterSegmentTrigger",
      "metricsTrigger",
      "tagAssignedTrigger",
      "tagRemovedTrigger",
      "listEntryTrigger",
      "emailActionTrigger",
      "priceDropTrigger",
      "dateAttributeTrigger",
      "lowStockAlertTrigger",
    ];

    const editor = shallowRef({});
    const dialogVisible = ref(false);
    const dialogData = ref({});
    const Vue = { version: 3, h, render };
    const internalInstance = getCurrentInstance();
    internalInstance.appContext.app._context.config.globalProperties.$df =
      editor;

    function exportEditor() {
      dialogData.value = editor.value.export();
      dialogVisible.value = true;
    }

    function exportEditorData() {
      return editor.value.export();
    }

    function zoomIn() {
      editor.value.zoom_in();
    }

    function zoomOut() {
      editor.value.zoom_out();
    }

    function showNotification(message, type) {
      notifyMessage.value = message;
      notifyType.value = type;
      isNotify.value = true;
      setTimeout(
        () => (
          (notifyMessage.value = ""),
          (notifyType.value = "success"),
          (isNotify.value = false)
        ),
        4000
      );
    }

    const drag = (ev) => {
      if (ev.type === "touchstart") {
        mobile_item_selec = ev.target
          .closest(".drag-drawflow")
          ?.getAttribute("data-node");
      } else {
        ev.dataTransfer.setData("node", ev.target?.getAttribute("data-node"));
      }
    };
    const drop = (ev) => {
      if (ev.type === "touchend") {
        var parentdrawflow = document
          .elementFromPoint(
            mobile_last_move.touches[0].clientX,
            mobile_last_move.touches[0].clientY
          )
          .closest("#drawflow");
        if (parentdrawflow != null) {
          addNodeToDrawFlow(
            mobile_item_selec,
            mobile_last_move.touches[0].clientX,
            mobile_last_move.touches[0].clientY
          );
        }
        mobile_item_selec = "";
      } else {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("node");
        addNodeToDrawFlow(data, ev.clientX, ev.clientY);
      }
    };
    const allowDrop = (ev) => {
      ev.preventDefault();
    };

    let mobile_item_selec = "";
    let mobile_last_move = null;
    function positionMobile(ev) {
      mobile_last_move = ev;
    }

    function addNodeToDrawFlow(name, pos_x, pos_y) {
      pos_x =
        pos_x *
          (editor.value.precanvas.clientWidth /
            (editor.value.precanvas.clientWidth * editor.value.zoom)) -
        editor.value.precanvas.getBoundingClientRect().x *
          (editor.value.precanvas.clientWidth /
            (editor.value.precanvas.clientWidth * editor.value.zoom));
      pos_y =
        pos_y *
          (editor.value.precanvas.clientHeight /
            (editor.value.precanvas.clientHeight * editor.value.zoom)) -
        editor.value.precanvas.getBoundingClientRect().y *
          (editor.value.precanvas.clientHeight /
            (editor.value.precanvas.clientHeight * editor.value.zoom));

      const nodeSelected = listNodes.find((ele) => ele.item == name);
      const nodes = editor.value?.drawflow?.drawflow?.Home?.data;
      let nodesArray = [];

      Object.keys(nodes).forEach((key) => {
        nodesArray.push(nodes[key].name);
      });

      if (checkIfTriggerExist(nodesArray, triggerNames)) {
        if (triggerNames.includes(name)) {
          showNotification(
            "You can only add one trigger to the workflow.",
            "warning"
          );
          return;
        } else {
          editor.value.addNode(
            name,
            nodeSelected.input,
            nodeSelected.output,
            pos_x,
            pos_y,
            name,
            {},
            name,
            "vue"
          );
        }
      } else {
        if (triggerNames.includes(name)) {
          editor.value.addNode(
            name,
            nodeSelected.input,
            nodeSelected.output,
            pos_x,
            pos_y,
            name,
            {},
            name,
            "vue"
          );
        } else {
          showNotification(
            "You must add a trigger to the workflow before adding an action.",
            "warning"
          );
        }
      }
      // remove node
      // editor.value.removeNodeId("node-5");
    }

    function checkIfTriggerExist(nodes, triggers) {
      let triggerExist = false;

      triggers.forEach((trigger) => {
        if (nodes.includes(trigger)) {
          triggerExist = true;
        }
      });
      return triggerExist;
    }

    function getFlowDetails(payload) {
      loading.value = true;
      payload.clubId = +route.params.id;
      getFlowDetailsService(payload)
        .then((res) => {
          // ;
          flowDetails.value = res.data.data;
          const editorData = JSON.parse(flowDetails.value?.editorData);
          loading.value = false;
          editor.value.import(editorData);

          store.commit("SET_FLOW_INFO_FOR_SEND_EMAIL", {
            flowName: res.data.data.autoflowName,
            flowType: res.data.data.flowTemplateId,
            flowDetails: res.data.data,
          });
        })
        .catch((err) => {
          loading.value = false;
        });
    }
    onMounted(() => {
      if (route.params.flowId) {
        getFlowDetails({
          id: route.params.flowId,
        });
      }

      var elements = document.getElementsByClassName("drag-drawflow");

      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener("touchend", drop, false);
        elements[i].addEventListener("touchmove", positionMobile, false);
        elements[i].addEventListener("touchstart", drag, false);
      }

      const id = document.getElementById("drawflow");
      editor.value = new Drawflow(
        id,
        Vue,
        internalInstance.appContext.app._context
      );
      // ;
      editor.value.start();

      editor.value.registerNode("sendEmailAction", sendEmailAction, {}, {});
      editor.value.registerNode("addToListAction", addToListAction, {}, {});
      editor.value.registerNode(
        "profileUpdateAction",
        profileUpdateAction,
        {},
        {}
      );
      editor.value.registerNode("actionAlertAction", actionAlertAction, {}, {});
      editor.value.registerNode(
        "removeFromListAction",
        removeFromListAction,
        {},
        {}
      );
      editor.value.registerNode("waitTimeAction", waitTimeAction, {}, {});
      editor.value.registerNode(
        "exitFromWorkflowAction",
        exitFromWorkflowAction,
        {},
        {}
      );
      editor.value.registerNode("tagAssignedAction", tagAssignedAction, {}, {});
      editor.value.registerNode("tagRemovedAction", tagRemovedAction, {}, {});
      editor.value.registerNode("mergeBlockAction", mergeBlockAction, {}, {});
      editor.value.registerNode(
        "conditionalSplitProcess",
        conditionalSplitProcess,
        {},
        {}
      );
      editor.value.registerNode(
        "enterSegmentTrigger",
        enterSegmentTrigger,
        {},
        {}
      );
      editor.value.registerNode(
        "tagAssignedTrigger",
        tagAssignedTrigger,
        {},
        {}
      );
      editor.value.registerNode("tagRemovedTrigger", tagRemovedTrigger, {}, {});
      editor.value.registerNode("listEntryTrigger", listEntryTrigger, {}, {});
      editor.value.registerNode(
        "emailActionTrigger",
        emailActionTrigger,
        {},
        {}
      );
      editor.value.registerNode("metricsTrigger", metricsTrigger, {}, {});
      editor.value.registerNode("priceDropTrigger", priceDropTrigger, {}, {});
      editor.value.registerNode(
        "dateAttributeTrigger",
        dateAttributeTrigger,
        {},
        {}
      );
      editor.value.registerNode(
        "lowStockAlertTrigger",
        lowStockAlertTrigger,
        {},
        {}
      );
      editor.value.useuuid = true;

      // only one connection between nodes at a time logic

      editor.value.on("nodeRemoved", function (id) {
        store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
          drawerName: id,
          drawerStatus: false,
        });
      });

      editor.value.on("connectionCreated", function (data) {
        const nodes = editor.value?.drawflow?.drawflow?.Home?.data;

        if (
          nodes[data.output_id]?.outputs?.[data?.output_class]?.connections
            ?.length > 1
        ) {
          editor.value.removeSingleConnection(
            data?.output_id,
            data.input_id,
            data?.output_class,
            data?.input_class
          );
        }
        if (
          nodes[data.input_id]?.inputs?.[data?.input_class]?.connections
            ?.length > 1
        ) {
          editor.value.removeSingleConnection(
            data?.output_id,
            data.input_id,
            data?.output_class,
            data?.input_class
          );
        }

        if (
          nodes[data.output_id]?.name == "conditionalSplitProcess" &&
          nodes[data.input_id]?.name == "mergeBlockAction"
        ) {
          if (
            nodes[data.output_id]?.outputs?.output_1?.connections[0]?.node ==
              data.input_id &&
            nodes[data.output_id]?.outputs?.output_2?.connections[0]?.node ==
              data.input_id
          ) {
            editor.value.removeSingleConnection(
              data?.output_id,
              data.input_id,
              data?.output_class,
              data?.input_class
            );
          }
        }
      });

      if (route?.query.isFromCampaign) {
        if (sessionStorage.getItem(route?.query.flow_name)) {
          let data = JSON.parse(sessionStorage.getItem(route?.query.flow_name));
          editor.value.import(data?.editorData);
        }
      } else {
        editor.value.import({
          drawflow: {
            Home: {
              data: {},
            },
          },
        });
      }
    });
    return {
      exportEditor,
      exportEditorData,
      zoomIn,
      zoomOut,
      drag,
      drop,
      allowDrop,
      listNodes,
      triggerNodes,
      actionNodes,
      processNodes,
      dialogVisible,
      dialogData,
      flowDetails,
      loading,

      notifyMessage,
      notifyType,
      isNotify,
      showNotification,
    };
  },

  computed: {
    notificationObject() {
      return this.$store.state?.flowAutomation?.notificationObject;
    },
    flowInfoForSendEmail() {
      return this.$store.state?.flowAutomation?.flowInfoForSendEmail;
    },
    flowPageSwitchActionName() {
      return this.$store.state.commonEmailCampaign.flowPageSwitchActionName;
    },
    flowPageSwitchNodeInfo() {
      return this.$store.state.commonEmailCampaign.flowPageSwitchNodeInfo;
    },
    flowBuilderAccordions() {
      return this.$store.state?.flowAutomation?.flowBuilderAccordions;
    },
    flowInfoForSendEmail() {
      return this.$store.state?.flowAutomation?.flowInfoForSendEmail;
    },
    flowAutomationDrawers() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers;
    },
    isMetricsTypeListLoading() {
      return this.$store.state?.flowAutomation?.isMetricsTypeListLoading;
    },
    isTimeZoneLoading() {
      return this.$store.state.commonEmailCampaign.isTimeZoneLoading;
    },
    isEmailCampaignTemplateMacroListsLoading() {
      return this.$store.state.commonEmailCampaign
        .isEmailCampaignTemplateMacroListsLoading;
    },
    isProfileUpdateFieldsLoading() {
      return this.$store.state?.flowAutomation?.isProfileUpdateFieldsLoading;
    },
    isDateAttributeFieldsLoading() {
      return this.$store.state?.flowAutomation?.isDateAttributeFieldsLoading;
    },
  },

  watch: {
    flowDetails: {
      handler: function () {
        this.flowName = this.flowDetails?.autoflowName;
        this.flowNameForEdit = this.flowDetails?.autoflowName;
      },
      deep: true,
    },
    flowPageSwitchActionName() {
      // );
      sessionStorage.setItem(
        this.flowName,
        JSON.stringify({
          editorData: this.exportEditorData(),
          flowName: this.flowName,
          flowType: this.flowType,
          flowIdForEdit: this?.flowId || null,
          nodeId: this.flowPageSwitchNodeInfo?.id,
          accordions: {
            ...this.flowBuilderAccordions,
          },
        })
      );

      this.$router.push({
        name: "flowCreateCampaign",
        query: {
          nodeId: this.flowPageSwitchNodeInfo?.id,
          flow_name: this.flowName,
          flow_type: this.flowType,
        },
      });
    },
  },

  mounted() {
    if (
      this.$route.params.flowId ||
      this.flowIdForEdit ||
      this.$route.query.flowId
    ) {
      if (!this.hasFlowCampaignPutPermission()) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "You do not have access to flow automation.",
          type: "warning",
        });
        this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
      }
    } else {
      if (!this.hasFlowCampaignPostPermission()) {
        this.$store.commit("SHOW_NOTIFICATION", {
          message: "You do not have access to flow automation.",
          type: "warning",
        });
        this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
      }
    }

    const payload = {
      clubId: this.$route.params.id,
      entityType: 2,
      limit: 0,
    };
    this.$store.dispatch("getMetricsTypeList");
    this.$store.dispatch("getTimeZonesList", 0);
    this.$store.dispatch("getLists", {
      ...payload,
      exceptInactiveLists: true,
    });
    this.$store.dispatch("getTags", payload);
    this.$store.dispatch("getsegmentsLists", payload);
    let payloadGet = {
      clubId: this.$route.params.id,
      configGroup: 0,
    };
    this.$store.dispatch("getSegmentConfigConditions", payloadGet);
    this.$store.dispatch("getsegmentsConfig", this.$route.params.id);
    this.$store.dispatch("getBasicEmailCampaignList", {
      clubId: this.$route.params.id,
      limit: -1,
      campaignGroup: 1,
    });
    this.getsegmentListForDropdown();
    this.$store.dispatch("getDateAttributeFields", {
      clubId: this.$route.params.id,
      FieldType: 1,
    });
    this.$store.dispatch("getProfileUpdateFields", {
      clubId: this.$route.params.id,
    });

    this.$store.dispatch("getMacrosList", {
      clubId: this.$route.params.id,
      CustomEventTypeId: 0,
    });

    if (this.$route.query.isFromCampaign) {
      if (sessionStorage.getItem(this.$route.query.flow_name)) {
        let data = JSON.parse(
          sessionStorage.getItem(this.$route.query.flow_name)
        );

        this.flowName = data?.flowName;
        this.flowType = data?.flowType;
        this.flowIdForEdit = data?.flowId;
        this.flowNameForEdit = data?.flowName;
        this.$store.commit("UPDATE_FLOW_BUILDER_ACCORDIONS", data?.accordions);

        // const timeId = setTimeout(() => {
        //   sessionStorage.removeItem(this.$route.query.flow_name);
        //   clearTimeout(timeId);
        // }, 3000);
      }
    } else {
      this.flowName = this.$route.query.flow_name;
      this.flowNameForEdit = this.$route.query.flow_name;
      this.flowType = this.$route.query.flow_type;

      this.$store.commit("SET_FLOW_INFO_FOR_SEND_EMAIL", {
        flowName: this.flowName,
        flowType: this.flowType,
      });
    }
  },

  unmounted() {
    this.$store.commit("UPDATE_FLOW_BUILDER_ACCORDIONS", {
      triggerAccordionVisibility: true,
      actionAccordionVisibility: false,
      processAccordionVisibility: false,
    });
  },
};
</script>
<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  min-height: calc(100vh - 100px);
}

.column.flow-sidebar {
  overflow-y: auto;
  max-height: calc(100vh - 58px);
  background: #ffffff;
  border-right: 1px solid #e4e7ec;
}

.node {
  border-radius: 4px;
  border: 1px solid #e9ebef;
  background: #f8f9fa;
  height: 40px;
  line-height: 22px;
  padding: 8px;
  margin: 12px 0px;
  cursor: move;
  position: relative;
}

.node::before {
  content: "";
  background: rgba(43, 53, 76, 0.12);
  border-radius: 2px;
  height: 24px;
  width: 3px;
  margin-right: 10px;
}

#drawflow {
  width: 100%;
  height: 100%;
  text-align: initial;
  background: #2b2c30;
  background-size: 20px 20px;
  background-image: radial-gradient(#494949 1px, transparent 1px);
}
</style>
