<template>
  <KEditor
    :class="'builder-editor'"
    :tools="ktools"
    :paste-html="onPasteHtml"
    :value="defaultContent"
    @execute="onExecute"
    @loaded="editorStyleOnLoaded"
    @change="onChange"
  >
    <template v-slot:MyFontSizeTool="{ props }">
      <div>
        <fontsize v-bind="props" :style="{ width: '150px', ...props.style }" />
      </div>
    </template>
    <template v-slot:FontNameTool="{ props }">
      <div>
        <fontname v-bind="props" :style="{ width: '150px', ...props.style }" />
      </div>
    </template>
    <template v-slot:MyLineHeightTool="{ props }">
      <MyLineHeightTool :currentHeight="lineHeight" :view="props.view" />
    </template>
    <template v-slot:MyBackColor="{ props }">
      <customColorPicker v-bind="props" :settings="props.settings" />
    </template>
    <template v-slot:MyForeColor="{ props }">
      <customColorPicker v-bind="props" :settings="props.settings" />
    </template>
    <template v-slot:macroDropdownComponent="{ props }">
      <macroDropdownComponent :view="props.view" />
    </template>
  </KEditor>
</template>

<script>
import { Editor, EditorUtils, FontName } from "@progress/kendo-vue-editor";
import kendoTools from "../helpers/kendoTools";
import customColorPicker from "../common-components/customColorPicker.vue";
import MyLineHeightTool from "../common-components/myLineHeightTool.vue";
import MacroDropdownComponent from "../common-components/macroDropdownComponent.vue";
import { onPasteHtmlIntoEditor } from "@/utils/helper";

export default {
  props: {
    lineHeight: String,
    ktools: Array,
    defaultContent: {
      type: String,
      default: "<p></p>",
    },
  },
  components: {
    KEditor: Editor,
    fontsize: FontName,
    fontname: FontName,
    customColorPicker,
    MyLineHeightTool,
    MacroDropdownComponent,
  },
  methods: {
    onPasteHtml(e) {
      return onPasteHtmlIntoEditor(e);
    },
    editorStyleOnLoaded(event) {
      const iframeDocument = event.dom.ownerDocument;
      const style = iframeDocument.createElement("style");
      style.appendChild(
        iframeDocument.createTextNode(`
        p {
            // margin:0;
        }
        .k-content > p {
            // margin: 0;
        }
      `)
      );
      iframeDocument.head.appendChild(style);
    },
    onChange(e) {
      if (e?.html) {
        this.$emit("change", e.html);
      }
    },
    onExecute(event) {
      setTimeout(() => {
        this.$emit("execute", event);
      });
    },
  },
};
</script>

<style scoped></style>
