<template src="./CreateUpdateSegmentModal.html"></template>
<style lang="scss">
@import "./CreateUpdateSegmentModal.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import AppDropdownGroup from "../../common/AppDropdownGroup/AppDropdownGroup.vue";
import AddSegmentModel from "../../../models/segments/addSegmentModel.ts";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import AppLoader from "../../common/AppLoader/AppLoader";
import Breadcrumb from "../../common/AppBreadCrumbs/BreadCrumbs.vue";
import { DropDownTree } from "@progress/kendo-dropdowntree-vue-wrapper";
import modal from "../AppModal/AppModal.vue";
import {
  getSegmentConfigConditions,
  addSegment,
  updateSegment,
  getSegmentDropdownList,
  getSegment,
} from "@/services/clubDetails/segments.service";
import dropdownlistWithGroup from "../../common/dropdownlistWithGroup/dropdownlistWithGroup.vue";

import AppSnackBar from "../../common/AppSnackBar/AppSnackBar.vue";
export default {
  name: "CreateUpdateSegment",
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    "k-input": Input,
    AppDropdownGroup,
    datePicker: DatePicker,
    kdatetime: DateTimePicker,
    AppLoader,
    AppSnackBar,
    Breadcrumb,
    dropdowntree: DropDownTree,
    modal,
    dropdownlistWithGroup,
  },
  props: {
    onConfirm: {
      type: Function,
      default: () => { },
    },
    onCancel: {
      type: Function,
      default: () => { },
    },
    additionalFilterData: {
      type: Object,
      default: () => { },
    },
    typeOfAction: {
      type: String,
      default: "create",
    },
    isFromFlowBuilder: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({

    selectedSegment: null,
    isAnySegmentSelected: false,
    isNameFieldVisible: true,

    sourceAndOr: [
      { id: "AND", text: "All" },
      { id: "OR", text: "Any" },
    ],
    sourceAndOrValue: "",
    mapConfig: [],
    addSegmentsModel: new AddSegmentModel(),
    payloadAddSegmentsModel: new AddSegmentModel(),
    isFirstColumn: false,
    isSecondColumn: false,
    nextCondition: "",
    configGroup: 0,
    isLoading: false,
    isGetSegmentLoading: false,
    index: 0,
    isNotify: false,
    notifyMessage: "Success",
    notifyType: "success",
    segmentChildConditionGroupDropdown: [],
    segmentChildConditionWhereDropdown: [],
    getSegmentConfigConditionsData: {},
    dayHoursWeeksYearDropDown: [
      { id: "Hour", text: "Hours" },
      { id: "Day", text: "Days" },
      { id: "Week", text: "Weeks" },
      { id: "Month", text: "Months" },
      { id: "Year", text: "Years" },
    ],
    dayHoursWeeksYearDropDownValue: [],
    dayHoursWeeksYearDropDownValueChnage: [],
    isWhereFirstValueDp: [],
    isWhereSecondValueDp: [],
    titleStr: "",
    modalTitle: "",
    isModalLoad: false,
  }),

  mounted() {

    this.isAnySegmentSelected = this.$route.query.segmentId ? true : false;
    this.isNameFieldVisible - this.isFromFlowBuilder ? true : false;

    this.getSegmentConfigConditionsFunc();
    this.sourceAndOrValue = this.sourceAndOr[0];
    this.getSegmentConfigs();

    if (this.typeOfAction == "create") {
      this.addNewConditions(0);
      this.titleStr = "Create";
    }
    if (this.typeOfAction == "update") {
      this.getSegment();
      this.titleStr = "Edit";
    }
    this.addSegmentsModel.clubId = this.$route.params.id;
    this.addSegmentsModel.entityType = 2;
    this.addSegmentsModel.conditionType = this.sourceAndOr[0].id;
  },

  computed: {
    segmentLists() {
      return this.$store.state.segments.segmentListForDropdown
    },
    segmentsConfigsData() {
      return this.$store.state.segments.segmentsConfigs;
    },
    getSegmentConfigConditions() {
      return this.$store.state.segments.getSegmentConfigConditions;
    },
    segmentStoreData() {
      return this.$store.state.segments.segmentData;
    },
    isModalOpenForCreateSegment() {
      return this.$store.state.segments.segmentModalOpenClose;
    },
  },

  methods: {
    getsegmentListForDropdown() {
      let payload = {
        clubId: this.$route.params.id,
        entityType: 2,
        limit: 1000,
      };
      this.$store.dispatch("getsegmentListForDropdown", payload);
    },
    onChangeSelectSegment(e) {
      if (e.value?.id != 0) {
        this.selectedSegment = e.value;
        // this.isAnySegmentSelected = true;
        this.getSegment();
      } else {
        this.isAnySegmentSelected = false;
        this.selectedSegment = e.value;

        //reload all value for create state

        this.addSegmentsModel = new AddSegmentModel();
        this.payloadAddSegmentsModel = new AddSegmentModel();
        this.sourceAndOrValue = this.sourceAndOr[0];
        this.getSegmentConfigConditionsFunc();
        this.addNewConditions(0);
        this.addSegmentsModel.clubId = this.$route.params.id;
        this.addSegmentsModel.entityType = 2;
        this.addSegmentsModel.conditionType = this.sourceAndOr[0].id;
        this.titleStr = "Create";
      }
    },
    modalHandler(data) {
      this.$store.commit("SET_SEGMENT_MODALOPENCLOSE", false);
    },
    getSegmentConfigConditionsFunc() {
      this.isLoading = true;
      let payload = {
        clubId:this.$route.params.id,
        configGroup:0
      }
      getSegmentConfigConditions(payload)
        .then((response) => {
          setTimeout(() => {
            this.getSegmentConfigConditionsData = response?.data?.data;
            this.isLoading = false;
            if (this.typeOfAction == "create") {
              this.onChangeMap(
                1,
                this.addSegmentsModel?.segmentGroupList[0]
                  ?.segmentConditionList[0],
                0
              );
            }
          }, 1000);
        })
        .catch((error) => {
          this.isLoading = false;
          this.isGetSegmentLoading = false;
        });
    },

    getSegmentConfigs() {
      this.$store.dispatch("getsegmentsConfig", this.$route.params.id);
    },
    removeSegmentConditionList(event, condition, index, indexNew) {
      this.addSegmentsModel?.segmentGroupList[
        indexNew
      ]?.segmentConditionList.splice(index, 1);
      this.payloadAddSegmentsModel?.segmentGroupList[
        indexNew
      ]?.segmentConditionList.splice(index, 1);
    },
    onChangeSegmentConfigConditionValue(e, condition, index) {
      this.isWhereFirstValueDp[index] = false;
      this.isWhereSecondValueDp[index] = false;
      if (condition?.segmentChildCondition) {
        condition.segmentChildCondition.isWhereFirstColumn = false;
        condition.segmentChildCondition.isWhereSecondColumn = false;
        condition.segmentChildCondition.isGroupFirstColumn = false;
        condition.segmentChildCondition.isGroupSecondColumn = false;
        condition.segmentChildCondition.whereNextCondition = "";
        condition.segmentChildCondition.groupNextCondition = "";
        condition.segmentChildCondition.whereConditionType = "";
        condition.segmentChildCondition.whereSecondValue = "";
        condition.segmentChildCondition.whereFirstValue = "";
        condition.segmentChildCondition.segmentConfigConditionGroupId = 0;
        condition.segmentChildCondition.segmentConfigConditionWhereId = 0;
      }
      condition.firstValue = "";
      condition.secondValue = "";

      this.emailError = "";

      condition.segmentConfigCondition = e.value;

      condition.isFirstColumn = e?.value?.firstColumn;
      condition.isSecondColumn = e?.value?.secondColumn;
      condition.nextCondition = e?.value?.nextCondition;
      condition.configGroup = e?.value?.configGroup;
      condition.segmentConfigurationConditionId = e?.value?.id;
      if (condition?.segmentChildCondition) {
        condition.segmentChildCondition.segmentConfigConditionGroupDDL =
          this.getSegmentConfigConditionsData.filter((x) => x.configGroup == 8);
        condition.segmentChildCondition.segmentConfigConditionWhereDDL =
          this.getSegmentConfigConditionsData.filter((x) => x.configGroup == 9);
      }
    },

    onChangeCampaignWorkFlowDropdown(e, condition, index) {
      condition.segmentChildCondition.segmentConfigConditionGroup = e.value;
      condition.segmentChildCondition.segmentConfigConditionGroupId =
        e.value?.id;
      condition.segmentChildCondition.isGroupFirstColumn =
        e?.value?.firstColumn;
      condition.segmentChildCondition.isGroupSecondColumn =
        e?.value?.secondColumn;
      condition.segmentChildCondition.groupNextCondition =
        e?.value?.nextCondition;
    },

    onChangeCampaignWorkFlowSecondDropdown(e, condition, index) {
      condition.segmentChildCondition.segmentConfigConditionWhere = e.value;
      condition.segmentChildCondition.segmentConfigConditionWhereId =
        e?.value?.id;

      condition.segmentChildCondition.isWhereFirstColumn =
        e?.value?.firstColumn;
      condition.segmentChildCondition.isWhereSecondColumn =
        e?.value?.secondColumn;
      condition.segmentChildCondition.whereNextCondition =
        e?.value?.nextCondition;

      if (e?.value?.firstValue == "X") {
        this.isWhereFirstValueDp[index] = true;
      } else {
        this.isWhereFirstValueDp[index] = false;
      }
      if (e?.value?.lastValue == "X") {
        this.isWhereSecondValueDp[index] = true;
      } else {
        this.isWhereSecondValueDp[index] = false;
      }

      this.dayHoursWeeksYearDropDownValue[index] = {
        id: "Hour",
        text: "Hours",
      };
      if (
        (condition.segmentChildCondition.isWhereSecondColumn &&
          !(e?.value?.firstValue == "X")) ||
        (condition.segmentChildCondition.isWhereFirstColumn &&
          !(e?.value?.lastValue == "X"))
      ) {
        condition.segmentChildCondition.whereConditionType =
          this.dayHoursWeeksYearDropDownValue[index].id;
      }
    },
    onChangeDayHoursWeeksYearDropdown(e, condition, index) {
      condition.segmentChildCondition.whereConditionType = e.value?.id;
      this.dayHoursWeeksYearDropDownValue[index] = e.value;
      this.dayHoursWeeksYearDropDownValueChnage[index] = e.value;
    },

    getDayHoursWeeksYearDropdown(e, condition, index) {
      if (condition?.segmentChildCondition?.whereConditionType) {
        var filterData = this.dayHoursWeeksYearDropDown.filter(
          (x) => x.id == condition.segmentChildCondition.whereConditionType
        );
        this.dayHoursWeeksYearDropDownValue[index] = filterData[0];
      }
      return this.dayHoursWeeksYearDropDownValue[index];
    },

    onChangeGetDropdownListValue(e, condition, index) {
      condition.firstValue = e.join();
      e.forEach((element) => {
        if (element < 0) {
          condition.UserDefinedDropdownList.forEach((firstValueDropdownIdx) => {
            firstValueDropdownIdx.forEach((list) => {
              list.options.forEach((item) => {
                if (item.value < 0) {
                  item.disabled = true;
                }
              });
            });
          });
        }
      });
    },
    getCustomFieldSourceByValue(val) {
      let custom_fields = this.segmentsConfigsData;
      for (let i in custom_fields) {
        let searchHandler = custom_fields[i].options;
        for (let j in searchHandler) {
          if (searchHandler[j].value === val) {
            return searchHandler[j].configGroup;
          }
        }
      }
    },
    onChangeMap(e, condition, index) {
      condition.segmentConfigId = e;
      this.isWhereFirstValueDp[index] = false;
      this.isWhereSecondValueDp[index] = false;
      if (condition?.segmentChildCondition) {
        condition.segmentChildCondition.isWhereFirstColumn = false;
        condition.segmentChildCondition.isWhereSecondColumn = false;
        condition.segmentChildCondition.isGroupFirstColumn = false;
        condition.segmentChildCondition.isGroupSecondColumn = false;
        condition.segmentChildCondition.whereNextCondition = "";
        condition.segmentChildCondition.groupNextCondition = "";
        condition.segmentChildCondition.whereSecondValue = "";
        condition.segmentChildCondition.whereFirstValue = "";
        condition.segmentChildCondition.segmentConfigConditionGroupId = 0;
        condition.segmentChildCondition.segmentConfigConditionWhereId = 0;
        condition.segmentChildCondition.whereConditionType = "";
      }
      condition.firstValue = "";
      condition.secondValue = "";
      this.emailError = "";

      let source = this.getCustomFieldSourceByValue(e);
      this.mapConfig = this.mapConfig.filter((x) => x.column != item);
      if (e != "Select Column") {
        condition.segmentConfigConditionDDL =
          this.getSegmentConfigConditionsData.filter(
            (x) => x.configGroup == source
          );

        if (condition.segmentConfigConditionDDL?.length > 0) {
          condition.segmentConfigCondition =
            condition.segmentConfigConditionDDL[0];

          condition.isFirstColumn =
            condition.segmentConfigConditionDDL[0]?.firstColumn;
          condition.isSecondColumn =
            condition.segmentConfigConditionDDL[0]?.secondColumn;
          condition.nextCondition =
            condition.segmentConfigConditionDDL[0]?.nextCondition;
          condition.configGroup =
            condition.segmentConfigConditionDDL[0]?.configGroup;
          condition.segmentConfigurationConditionId =
            condition.segmentConfigConditionDDL[0]?.id;
        }
        if (condition?.segmentChildCondition) {
          condition.segmentChildCondition.segmentConfigConditionGroupDDL =
            this.getSegmentConfigConditionsData.filter(
              (x) => x.configGroup == 8
            );
        }

        if (
          condition?.segmentChildCondition?.segmentConfigConditionGroupDDL
            .length > 0
        ) {
          condition.segmentChildCondition.segmentConfigConditionGroup =
            condition?.segmentChildCondition?.segmentConfigConditionGroupDDL[0];
          if (
            condition.isFirstColumn &&
            (condition.configGroup == 5 ||
              condition.configGroup == 6 ||
              condition.configGroup == 7)
          )
            condition.segmentChildCondition.segmentConfigConditionGroupId =
              condition?.segmentChildCondition?.segmentConfigConditionGroupDDL[0]?.id;
          condition.isGroupFirstColumn =
            condition?.segmentChildCondition?.segmentConfigConditionGroupDDL[0]?.firstColumn;
          condition.isGroupSecondColumn =
            condition?.segmentChildCondition?.segmentConfigConditionGroupDDL[0]?.secondColumn;
          condition.groupNextCondition =
            condition?.segmentChildCondition?.segmentConfigConditionGroupDDL[0]?.nextCondition;
        }
        if (condition?.segmentChildCondition) {
          condition.segmentChildCondition.segmentConfigConditionWhereDDL =
            this.getSegmentConfigConditionsData.filter(
              (x) => x.configGroup == 9
            );
        }

        if (
          condition?.segmentChildCondition?.segmentConfigConditionWhereDDL
            .length > 0
        ) {
          condition.segmentChildCondition.whereFirstValue = "";
          condition.segmentChildCondition.segmentConfigConditionWhere =
            condition?.segmentChildCondition?.segmentConfigConditionWhereDDL[0];
          if (
            condition.isFirstColumn &&
            (condition.configGroup == 5 ||
              condition.configGroup == 6 ||
              condition.configGroup == 7)
          )
            condition.segmentChildCondition.segmentConfigConditionWhereId =
              condition?.segmentChildCondition?.segmentConfigConditionWhereDDL[0]?.id;

          condition.isWhereFirstColumn =
            condition?.segmentChildCondition?.segmentConfigConditionWhereDDL[0].firstColumn;
          condition.isWhereSecondColumn =
            condition?.segmentChildCondition?.segmentConfigConditionWhereDDL[0].secondColumn;
          condition.whereNextCondition =
            condition?.segmentChildCondition?.segmentConfigConditionWhereDDL[0].nextCondition;

          if (
            condition?.segmentChildCondition?.segmentConfigConditionWhereDDL[0]
              .firstValue == "X"
          ) {
            this.isWhereFirstValueDp[0] = true;
          } else {
            this.isWhereFirstValueDp[0] = false;
          }
          if (
            condition?.segmentChildCondition?.segmentConfigConditionWhereDDL[0]
              .lastValue == "X"
          ) {
            this.isWhereSecondValueDp[0] = true;
          } else {
            this.isWhereSecondValueDp[0] = false;
          }

          this.dayHoursWeeksYearDropDownValue[index] = {
            id: "Hour",
            text: "Hours",
          };
          if (
            (condition?.segmentChildCondition?.isWhereSecondColumn &&
              !(condition?.segmentChildCondition?.whereSecondValue == "X")) ||
            (condition?.segmentChildCondition?.isWhereFirstColumn &&
              !(condition?.segmentChildCondition?.whereFirstValue == "X"))
          ) {
            condition.segmentChildCondition.whereConditionType =
              this.dayHoursWeeksYearDropDownValue[index].id;
          }
        }

        var model = {
          id: e,
          clubId: this.$route.params.id,
          entityType: 2,
        };
        getSegmentDropdownList(model)
          .then((response) => {
            condition.userDefinedDropdownList = response.data.data;
          })
          .catch((error) => { });
      }
      if (this.mapConfig.filter((x) => x.column == "email").length) {
        this.canSave = true;
      }
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },

    getSegment() {
      this.isLoading = true;
      this.isGetSegmentLoading = true;
      let payload = {
        clubId: this.$route.params.id,
        id: this.additionalFilterData?.segmentId || this.selectedSegment?.id,
        searchQuery: "",
        entityType: 2,
      };

      getSegment(payload)
        .then((response) => {
          this.isLoading = false;
          this.addSegmentsModel = response.data.data;
          if (this.addSegmentsModel?.conditionType == "AND") {
            this.sourceAndOrValue = { id: "AND", text: "All" };
          }
          if (this.addSegmentsModel?.conditionType == "OR") {
            this.sourceAndOrValue = { id: "OR", text: "Any" };
          }

          this.isGetSegmentLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.isGetSegmentLoading = false;
        });
    },

    segmentGroupListAndCondition(conditionNew, indexNew) {
      conditionNew.conditionType = "And";
    },
    segmentGroupListOrCondition(conditionNew, indexNew) {
      conditionNew.conditionType = "Or";
    },

    segmentConditionListAndCondition(condition, index, conditionNew) {
      conditionNew?.segmentConditionList.forEach((element) => {
        element.conditionType = "And";
      });
    },

    segmentConditionListOrCondition(condition, index, conditionNew) {
      conditionNew?.segmentConditionList.forEach((element) => {
        element.conditionType = "Or";
      });
    },

    removeSegmentGroupConditionList($event, conditionNew, indexNew) {
      this.addSegmentsModel?.segmentGroupList?.splice(indexNew, 1);
      this.payloadAddSegmentsModel?.segmentGroupList?.splice(indexNew, 1);
    },

    addNewConditions(indexNew) {
      this.addSegmentsModel?.segmentGroupList.push({
        id: 0,
        conditionType: "And",
        segmentConditionList: [],
      });
      this.addConditions(indexNew);
    },

    addConditions(indexNew) {
      this.addSegmentsModel?.segmentGroupList[
        indexNew
      ]?.segmentConditionList.push({
        id: 0,
        segmentId: 0,
        segmentConfigId: 0,
        segmentConfigurationConditionId: 0,
        condition: "",
        conditionType: this.addSegmentsModel?.segmentGroupList[indexNew]
          ?.segmentConditionList[1]
          ? this.addSegmentsModel?.segmentGroupList[indexNew]
            ?.segmentConditionList[1].conditionType
          : "And",
        nextCondition: "",
        firstValue: "",
        secondValue: "",
        segmentChildCondition: {
          id: 0,
          segmentConditionId: 0,
          segmentConfigConditionGroupId: 0,
          groupCondition: "",
          groupNextCondition: "",
          groupFirstValue: "",
          groupSecondValue: "",
          segmentConfigConditionWhereId: 0,
          whereCondition: "",
          whereNextCondition: "",
          whereFirstValue: "",
          whereSecondValue: "",
          whereConditionType: "",
          segmentConfigConditionGroupDDL: [],
          segmentConfigConditionGroup: [],
          segmentConfigConditionWhereDDL: [],
          segmentConfigConditionWhere: [],
          isGroupFirstColumn: false,
          isGroupSecondColumn: false,
          isWhereFirstColumn: false,
          isWhereSecondColumn: false,
        },
      });
    },

    payloadGroupModelInit(indexNew) {
      this.payloadAddSegmentsModel?.segmentGroupList.push({
        id: 0,
        conditionType: "And",
        segmentConditionList: [],
      });
    },

    payloadModelInit(indexNew) {
      this.payloadAddSegmentsModel?.segmentGroupList[
        indexNew
      ]?.segmentConditionList.push({
        id: 0,
        segmentId: 0,
        segmentConfigId: 0,
        segmentConfigurationConditionId: 0,
        condition: "",
        conditionType: this.payloadAddSegmentsModel?.segmentGroupList[indexNew]
          ?.segmentConditionList[1]
          ? this.payloadAddSegmentsModel?.segmentGroupList[indexNew]
            ?.segmentConditionList[1].conditionType
          : "And",
        nextCondition: "",
        firstValue: "",
        secondValue: "",
        segmentChildCondition: {
          id: 0,
          segmentConditionId: 0,
          segmentConfigConditionGroupId: 0,
          groupCondition: "",
          groupNextCondition: "",
          groupFirstValue: "",
          groupSecondValue: "",
          segmentConfigConditionWhereId: 0,
          whereCondition: "",
          whereNextCondition: "",
          whereFirstValue: "",
          whereSecondValue: "",
          whereConditionType: "",
          isGroupFirstColumn: false,
          isGroupSecondColumn: false,
          isWhereFirstColumn: false,
          isWhereSecondColumn: false,
        },
      });
    },

    onChangeAndOr(event) {
      this.sourceAndOrValue = event.value;
      this.addSegmentsModel.conditionType = event.value.id;
    },

    cancelSegment() {
      this.$router.push(
        `/club-details/${this.$route.params.id}/segments?type=${this.$route.query.type
        }`
      );
    },

    onChangeDatePickerFirstValue(e, condition, index) {
      condition.firstValue = e.target.value;
    },

    onChangeDatePickerSecondValue(e, condition, index) {
      condition.secondValue = e.target.value;
    },

    onChangeDatePickerWhereFirstValue(e, condition, index) {
      this.isWhereFirstValueDp[index] = true;
      condition.segmentChildCondition.whereFirstValue = e.target.value;
    },

    onChangeDatePickerWhereSecondValue(e, condition, index) {
      condition.segmentChildCondition.whereSecondValue = e.target.value;
    },

    firstValueTextFieldOpen(event, condition, index) {
      return (
        condition?.isFirstColumn &&
        condition?.configGroup != 4 &&
        !(condition?.configGroup == 8 || condition?.configGroup == 3) &&
        !(
          condition?.configGroup == 2 ||
          condition?.configGroup == 5 ||
          condition?.configGroup == 6 ||
          condition?.configGroup == 7
        )
      );
    },

    firstValueTextFieldNumberOpen(event, condition, index) {
      return (
        condition?.isFirstColumn &&
        condition?.configGroup != 4 &&
        (condition?.configGroup == 8 || condition.configGroup == 3) &&
        !(
          condition?.configGroup == 2 ||
          condition?.configGroup == 5 ||
          condition?.configGroup == 6 ||
          condition?.configGroup == 7
        )
      );
    },

    firstValueMultiSelectFieldOpen(event, condition, index) {
      return (
        condition.isFirstColumn &&
        (condition.configGroup == 2 ||
          condition.configGroup == 5 ||
          condition.configGroup == 6 ||
          condition.configGroup == 7)
      );
    },

    firstValueDatePickerFieldOpen(event, condition, index) {
      if (
        condition.isFirstColumn &&
        condition.configGroup == 4 &&
        !(
          condition.configGroup == 2 ||
          condition.configGroup == 5 ||
          condition.configGroup == 6 ||
          condition.configGroup == 7
        )
      )
        condition.firstValue = condition.firstValue
          ? condition.firstValue
          : '';
      return (
        condition.isFirstColumn &&
        condition.configGroup == 4 &&
        !(
          condition.configGroup == 2 ||
          condition.configGroup == 5 ||
          condition.configGroup == 6 ||
          condition.configGroup == 7
        )
      );
    },

    SecondColumnValueTextFieldOpen(event, condition, index) {
      return (
        condition.isSecondColumn &&
        condition.configGroup != 4 &&
        condition.configGroup != 2 &&
        !(condition.configGroup == 8 || condition.configGroup == 3)
      );
    },

    SecondColumnValueNumberFieldOpen(event, condition, index) {
      return (
        condition.isSecondColumn &&
        condition.configGroup != 4 &&
        condition.configGroup != 2 &&
        (condition.configGroup == 8 || condition.configGroup == 3)
      );
    },

    secondValueDatePickerFieldOpen(event, condition, index) {
      if (
        condition.isSecondColumn &&
        condition.configGroup == 4 &&
        condition.configGroup != 2
      )
        condition.secondValue = condition.secondValue
          ? condition.secondValue
          : '';
      return (
        condition.isSecondColumn &&
        condition.configGroup == 4 &&
        condition.configGroup != 2
      );
    },

    segmentChildConditionDropdownOpen($event, condition, index) {
      return (
        condition.isFirstColumn &&
        (condition.configGroup == 5 ||
          condition.configGroup == 6 ||
          condition.configGroup == 7)
      );
    },

    segmentChildConditionDropdownWhereDDLOpen($event, condition, index) {
      return (
        condition.isFirstColumn &&
        (condition.configGroup == 5 ||
          condition.configGroup == 6 ||
          condition.configGroup == 7)
      );
    },

    segmentChildConditionDropdownWhereFirstValueOpen($event, condition, index) {
      if (
        condition?.segmentChildCondition?.isWhereFirstColumn &&
        (this.isWhereFirstValueDp[index] ||
          condition.segmentChildCondition?.segmentConfigConditionWhere
            ?.firstValue == "X")
      )
        condition.segmentChildCondition.whereFirstValue = condition
          .segmentChildCondition.whereFirstValue
          ? condition.segmentChildCondition.whereFirstValue
          : '';
      return (
        condition?.segmentChildCondition?.isWhereFirstColumn &&
        (this.isWhereFirstValueDp[index] ||
          condition.segmentChildCondition?.segmentConfigConditionWhere
            ?.firstValue == "X")
      );
    },

    segmentChildConditionDropdownWhereSecondValueOpen(
      $event,
      condition,
      index
    ) {
      if (
        condition?.segmentChildCondition?.isWhereSecondColumn &&
        (this.isWhereSecondValueDp[index] ||
          condition.segmentChildCondition?.segmentConfigConditionWhere
            ?.lastValue == "X")
      )
        condition.segmentChildCondition.whereSecondValue = condition
          .segmentChildCondition.whereSecondValue
          ? condition.segmentChildCondition.whereSecondValue
          : '';

      return (
        condition?.segmentChildCondition?.isWhereSecondColumn &&
        (this.isWhereSecondValueDp[index] ||
          condition.segmentChildCondition?.segmentConfigConditionWhere
            ?.lastValue == "X")
      );
    },

    payloadModify() {
      this.payloadAddSegmentsModel.id = this.addSegmentsModel.id;
      this.payloadAddSegmentsModel.clubId = this.addSegmentsModel.clubId;
      this.payloadAddSegmentsModel.entityType =
        this.addSegmentsModel.entityType;
      this.payloadAddSegmentsModel.segmentName =
        this.addSegmentsModel.segmentName;
      this.payloadAddSegmentsModel.conditionType =
        this.addSegmentsModel.conditionType;
      this.payloadAddSegmentsModel.finalSQL = this.addSegmentsModel.finalSQL;
      this.addSegmentsModel?.segmentGroupList.forEach((elementNew, index) => {
        this.payloadGroupModelInit(index);
        this.payloadAddSegmentsModel.segmentGroupList[index].id =
          elementNew?.id;
        this.payloadAddSegmentsModel.segmentGroupList[index].conditionType =
          elementNew?.conditionType;
        for (var i = 0; i < elementNew.segmentConditionList?.length; i++) {
          this.payloadModelInit(index);
          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].id =
            elementNew?.segmentConditionList[i]?.id;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentId =
            elementNew?.segmentConditionList[i]?.segmentId;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentConfigId =
            elementNew?.segmentConditionList[i]?.segmentConfigId;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentConfigurationConditionId =
            elementNew?.segmentConditionList[
              i
            ]?.segmentConfigurationConditionId;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].condition =
            elementNew?.segmentConditionList[i]?.condition;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].conditionType =
            elementNew?.segmentConditionList[i]?.conditionType;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].nextCondition =
            elementNew?.segmentConditionList[i]?.nextCondition;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].firstValue =
            elementNew?.segmentConditionList[i]?.firstValue;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].secondValue =
            elementNew?.segmentConditionList[i]?.secondValue;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].configGroup =
            elementNew?.segmentConditionList[i]?.configGroup;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].isFirstColumn =
            elementNew?.segmentConditionList[i]?.isFirstColumn;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].isSecondColumn =
            elementNew?.segmentConditionList[i]?.isSecondColumn;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.id =
            elementNew?.segmentConditionList[i]?.segmentChildCondition?.id;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.segmentConditionId =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.segmentConditionId;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[
            i
          ].segmentChildCondition.segmentConfigConditionGroupId =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.segmentConfigConditionGroupId;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.groupCondition =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.groupCondition;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.groupNextCondition =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.groupNextCondition;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.groupFirstValue =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.groupFirstValue;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.groupSecondValue =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.groupSecondValue;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[
            i
          ].segmentChildCondition.segmentConfigConditionWhereId =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.segmentConfigConditionWhereId;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.whereCondition =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.whereCondition;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.whereNextCondition =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.whereNextCondition;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.whereFirstValue =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.whereFirstValue;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.whereSecondValue =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.whereSecondValue;

          this.payloadAddSegmentsModel.segmentGroupList[
            index
          ].segmentConditionList[i].segmentChildCondition.whereConditionType =
            elementNew?.segmentConditionList[
              i
            ]?.segmentChildCondition?.whereConditionType;
        }
      });
      return this.payloadAddSegmentsModel;
    },

    validation() {
      let isValid = true;
      this.addSegmentsModel?.segmentGroupList.forEach((elementNew) => {
        if (elementNew.conditionType == "") {
          this.showNotification(
            "conditionGroup Type missing for one of your conditions.",
            "warning"
          );
          isValid = false;
        }
        elementNew?.segmentConditionList.forEach((element) => {
          if (element.segmentConfigId == 0) {
            this.showNotification("segmentConfigId", "warning");
            isValid = false;
          }
          if (element.segmentConfigurationConditionId == 0) {
            this.showNotification(
              "Value missing for one of your conditions.",
              "warning"
            );
            isValid = false;
          }
          if (element.conditionType == "") {
            this.showNotification(
              "condition Type missing for one of your conditions.",
              "warning"
            );
            isValid = false;
          }

          if (
            element.isFirstColumn &&
            element.configGroup != 4 &&
            !(
              element.configGroup == 2 ||
              element.configGroup == 5 ||
              element.configGroup == 6 ||
              element.configGroup == 7
            )
          ) {
            if (element.firstValue == "") {
              this.showNotification(
                "Value missing for one of your conditions.",
                "warning"
              );
              isValid = false;
            }
          }

          if (element.secondValue == "" && element.isSecondColumn) {
            this.showNotification(
              "Value missing for one of your conditions.",
              "warning"
            );
            isValid = false;
          }

          if (element.configGroup == 2 && element.firstValue == "") {
            this.showNotification(
              "Value missing for one of your conditions.",
              "warning"
            );
            isValid = false;
          }

          if (element.configGroup == 5 && element.firstValue == "") {
            this.showNotification(
              "Value missing for one of your conditions.",
              "warning"
            );
            isValid = false;
          }

          if (
            element.isFirstColumn &&
            element.configGroup == 4 &&
            !(
              element.configGroup == 2 ||
              element.configGroup == 5 ||
              element.configGroup == 6 ||
              element.configGroup == 7
            ) &&
            element.isSecondColumn &&
            element.configGroup == 4 &&
            element.configGroup != 2
          ) {
            if (element.firstValue && element.secondValue) {
              if (
                new Date(element.firstValue) > new Date(element.secondValue)
              ) {
                this.showNotification(
                  "Please input valid time range.",
                  "warning"
                );
                isValid = false;
              }
            }
          }
        });
      });
      if (this.addSegmentsModel.segmentName.trim() == "") {
        this.showNotification("Segments Name is Required.", "warning");
        isValid = false;
      }
      if (this.addSegmentsModel.segmentName.trim().length > 75) {
        this.showNotification(
          "Segment name should not exceed 75 characters.",
          "warning"
        );
        isValid = false;
      }
      return isValid;
    },

    addSegment() {
      if (!this.validation()) return;

      if (this.typeOfAction == "create") {

        if (this.selectedSegment?.id) {
          this.onConfirm({
            segmentId: this.selectedSegment?.id,
            segmentName: this.selectedSegment?.segmentName,
          });
          return;
        }

        this.isLoading = true;
        addSegment(this.payloadModify())
          .then((response) => {
            if (response) {
              // this.isLoading = false;
              if (response?.data?.data?.status == "error") {
                this.showNotification(
                  "Segments name already exists.",
                  "warning"
                );
                this.payloadAddSegmentsModel?.segmentGroupList.splice(
                  0,
                  this.payloadAddSegmentsModel?.segmentGroupList.length
                );
                this.isLoading = false;
                return;
              }
              this.getsegmentListForDropdown();
              this.showNotification("Segment created successfully.", "success");
              this.onConfirm({
                segmentId: response.data.data.id,
                segmentName: response.data.data.segmentName,
              });

              setTimeout(() => {
                this.isLoading = false;
                this.modalHandler();
              }, 500);
            }
          })
          .catch((error) => {
            this.showNotification("Something went wrong.", "error");
            this.payloadAddSegmentsModel?.segmentGroupList.splice(
              0,
              this.payloadAddSegmentsModel?.segmentGroupList.length
            );
            this.isLoading = false;
          });
      }
      if (this.typeOfAction == "update") {
        this.isLoading = true;
        updateSegment(this.payloadModify())
          .then((response) => {
            if (response) {
              this.getsegmentListForDropdown();
              this.isLoading = false;
              this.showNotification("Segments Updated.", "success");
              this.onConfirm({
                segmentId: response.data.data.id,
                segmentName: response.data.data.segmentName,
              });

              this.isLoading = false;
              this.modalHandler();
            }
          })
          .catch((error) => {
            this.showNotification("Something went wrong.", "error");
            this.payloadAddSegmentsModel?.segmentGroupList.splice(
              0,
              this.payloadAddSegmentsModel?.segmentGroupList.length
            );
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style></style>
