<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'triggerBoxIcon'"></component>
      </span>
      <span class="me-auto">Low Stock Alert</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p v-html="lowStockAlertMessage"></p>
    </div>
    <teleport to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            Low Stock Alert
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <div class="pb-1">
              <p class="font-12 l-height-24 fw-medium m-0">Inventory Level</p>
              <dropdownlist
                :class="'h-38 drop-icon-2 mb-10'"
                :style="{ width: '100%' }"
                :data-items="inventoryTypeStatus"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="lowStockAlertConfiguration.inventoryType"
                @change="handleDropdownChange($event.value, 'inventoryType')"
              >
              </dropdownlist>
            </div>
            <div class="border-bottom-normal-1 mx--15"></div>
            <div class="py-16">
              <div class="mb-16">
                <label class="font-13 l-height-24 f-weight-500 mb-1"
                  >Minimum Inventory Level</label
                >
                <div class="d-flex justify-content-between align-items-center">
                  <k-input
                    type="number"
                    placeholder="Enter a number"
                    onkeydown="return event.keyCode !== 69"
                    v-model="lowStockAlertConfiguration.inventoryUnitsValue"
                    :class="'max-w-100-40 h-30 mb-0 font-13'"
                  ></k-input>
                  <span class="font-14 l-height-24">units</span>
                </div>
              </div>
              <div class="pb-14">
                <div class="d-flex mb-10">
                  <div class="w-42">
                    <k-switch
                      :class="'w-34 h-18 switch-34-18'"
                      :size="'small'"
                      v-model="
                        lowStockAlertConfiguration.exlude_customers_who_purchased
                      "
                    />
                  </div>
                  <p class="font-14 font-w-500 m-0">
                    Exclude customers who purchased
                  </p>
                </div>
                <div
                  class="d-flex justify-content-start align-items-center gap-2 pl-21"
                >
                  <p class="font-14 m-0">In last</p>
                  <k-input
                    type="number"
                    placeholder="30"
                    aria-required="true"
                    :class="'w-50 h-30 m-0 radius-4'"
                    onkeydown="return event.keyCode !== 69"
                    v-model="lowStockAlertConfiguration.exclusdeTimeInDays"
                  >
                  </k-input>
                  <p class="font-14 m-0">Days period</p>
                </div>
              </div>
              <div class="border-bottom-normal-1"></div>
              <p class="font-13 l-height-24 font-w-500 mx-0 mt-0 mb-10 pt-20">
                Target customers who interacted
              </p>
              <div class="d-flex justify-content-between mb-14">
                <radiogroup
                  :data-items="dataItems"
                  v-model="lowStockAlertConfiguration.targetCustomersType"
                />
              </div>
              <div
                class="d-flex justify-content-start align-items-center gap-2 pl-21"
              >
                <p class="font-14 m-0">For</p>
                <k-input
                  type="number"
                  placeholder="30"
                  aria-required="true"
                  :class="'w-50 h-30 m-0 radius-4'"
                  onkeydown="return event.keyCode !== 69"
                  v-model="lowStockAlertConfiguration.targetCustomersTimePeriod"
                >
                </k-input>
                <p class="font-14 m-0">Days period</p>
              </div>
            </div>
            <!-- <div class="border-bottom-normal-1 pb-1">
              <p class="font-12 l-height-24 fw-medium m-0">
                Engagement timeframes
              </p>
              <dropdownlist
                :class="'h-38 drop-icon-2 mb-10'"
                :style="{ width: '100%' }"
                :data-items="engagementTimeFramesTypeStatus"
                :text-field="'text'"
                :data-item-key="'id'"
                :value="lowStockAlertConfiguration.engagementTimeFramesType"
                @change="
                  handleDropdownChange($event.value, 'engagementTimeFramesType')
                "
              >
              </dropdownlist>
            </div> -->

            <div class="border-top-normal-1 mx--15"></div>
            <div class="pt-20">
              <configureFlowFilterComponent
                :onConfirm="onConfirmFlowFilter"
                :additionalFilterData="additionalFilterData"
              />
            </div>
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <!-- <kbutton @click="drawerClose" class="ms-2">Cancel</kbutton> -->
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler"
              class="ms-2"
              theme-color="primary"
              >Apply Trigger
            </kbutton>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button, DropDownButton } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import triggerBoxIcon from "@/components/svg-icons/triggerBoxIcon.vue";
import configureFlowFilterComponent from "../../common/configureFlowFilterComponent.vue";
import { useStore } from "vuex";
import { filterBy } from "@progress/kendo-data-query";
import NoDataRender from "@/components/common/NoDataRender/NoDataRender.vue";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import { RadioGroup } from "@progress/kendo-vue-inputs";
import { Switch } from "@progress/kendo-vue-inputs";
import browseProductModal from "@/components/emailTemplateEditor/widgets/product/browseProductModal/browseProductModal.vue";

export default {
  components: {
    "k-input": Input,
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    triggerBoxIcon,
    configureFlowFilterComponent,
    custom: NoDataRender,
    multiselect: MultiSelect,
    radiogroup: RadioGroup,
    "k-switch": Switch,
    DropDownButton,
    browseProductModal,
  },
  data() {
    return {
      addProductModalVisibility: false,
      lowStockAlertConfiguration: {
        inventoryType: { text: "Product Level", id: 1 },
        targetCustomersType: "viewed_the_product",
        targetCustomersTimePeriod: 1,
        inventoryUnitsValue: 0,

        exlude_customers_who_purchased: false,
        exclusdeTimeInDays: 1,
      },

      dataItems: [
        {
          label: "Started checkout with the item",
          value: "started_checkout_with_the_item",
        },
        {
          label: "Viewed the Product",
          value: "viewed_the_product",
        },
        {
          label: "Abandoned Cart",
          value: "abandoned_cart",
        },
      ],
      inventoryTypeStatus: [
        { text: "Product Level", id: 1 },
        // { text: "Product Variant Level", id: 2 },
      ],
      engagementTimeFramesTypeStatus: [
        { text: "1 day", id: 0 },
        { text: "7 days", id: 1 },
        { text: "30 days", id: 2 },
        { text: "90 days", id: 3 },
      ],

      selectedTriggerValue: {},
      additionalFilterData: null,
    };
  },
  methods: {
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    onConfirmFlowFilter(data) {
      this.additionalFilterData = data;
      this.updateDataHandler();
    },
    handleDropdownChange(value, field) {
      this.lowStockAlertConfiguration[field] = value;
    },
    onConfirmDrawerHandler() {
      this.updateDataHandler();
      this.drawerCloseHandler();
    },
    updateDataHandler() {
      const payload = {
        id: this.nodeId,
        selectedTriggerValue: { id: 1, text: "Low Stock Alert" },
        lowStockAlertConfiguration: this.lowStockAlertConfiguration,
        additionalFilterData: this.additionalFilterData,
      };
      this.updateNodeData(payload);
    },
    beforeCloseHandler() {
      this.onConfirmDrawerHandler();
    },
    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        this.selectedTriggerValue = nodeData.selectedTriggerValue;
        this.additionalFilterData = nodeData.additionalFilterData;
        this.lowStockAlertConfiguration = nodeData.lowStockAlertConfiguration;
      }
    },
  },
  computed: {
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
    lowStockAlertMessage() {
      const { inventoryUnitsValue } = this.lowStockAlertConfiguration;

      const isConfigured =
        inventoryUnitsValue == null || inventoryUnitsValue == 0;

      if (isConfigured) {
        return "Trigger when inventory falls below a specified threshold for a product or variant. Set your product and stock threshold.";
      }
      const threshold = inventoryUnitsValue || 0;

      return `Triggered when inventory level falls below <strong>${threshold}</strong>.`;
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  mounted() {
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
      this.updateDataHandler();
    }, 100);
  },

  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
