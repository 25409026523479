<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8439_39061)">
    <path d="M3.36056 5.26031H7.03688C7.23124 5.26031 7.38865 5.10276 7.38865 4.90841C7.38865 4.71418 7.23123 4.55664 7.03688 4.55664H3.36056C3.1662 4.55664 3.00879 4.71418 3.00879 4.90841C3.00879 5.10276 3.16621 5.26031 3.36056 5.26031Z" fill="white"/>
    <path d="M3.36056 7.7221H7.03688C7.23124 7.7221 7.38865 7.56455 7.38865 7.37032C7.38865 7.17597 7.23123 7.01855 7.03688 7.01855H3.36056C3.1662 7.01855 3.00879 7.17598 3.00879 7.37032C3.00879 7.56456 3.16621 7.7221 3.36056 7.7221Z" fill="white"/>
    <path d="M7.03688 9.48145H3.36056C3.1662 9.48145 3.00879 9.63899 3.00879 9.83322C3.00879 10.0276 3.16621 10.185 3.36056 10.185H7.03688C7.23124 10.185 7.38865 10.0276 7.38865 9.83322C7.38865 9.63898 7.23123 9.48145 7.03688 9.48145Z" fill="white"/>
    <path d="M12.7313 12.0723H9.35883C9.18054 12.0723 9.03613 12.2298 9.03613 12.424C9.03613 12.6184 9.18054 12.7758 9.35883 12.7758H12.7313C12.9096 12.7758 13.054 12.6184 13.054 12.424C13.054 12.2298 12.9096 12.0723 12.7313 12.0723Z" fill="white"/>
    <path d="M12.2578 8.81206L12.2331 4.72771H12.2332C12.2358 4.70317 12.2358 4.67838 12.2332 4.65384C12.226 4.62562 12.2154 4.59838 12.2016 4.57286L12.191 4.55532C12.175 4.52881 12.1553 4.50452 12.133 4.48317L8.87694 1.44012H8.86811C8.84823 1.42245 8.82639 1.40711 8.80296 1.39435L8.78713 1.38552C8.75977 1.37251 8.73081 1.36307 8.70099 1.35742H3.11081C2.55107 1.35742 2.01413 1.57975 1.61829 1.97571C1.22236 2.37155 1 2.90846 1 3.46823V12.6151C1 13.1749 1.22233 13.7119 1.61829 14.1077C2.01413 14.5035 2.55104 14.726 3.11081 14.726H8.05362C8.77264 15.7465 9.94207 16.3547 11.1904 16.3574C12.4387 16.3601 13.6108 15.7568 14.334 14.7394C15.0572 13.722 15.2418 12.4168 14.8292 11.2386C14.4164 10.0605 13.4577 9.15582 12.2576 8.81215L12.2578 8.81206ZM8.98791 2.50602L10.9932 4.37767H9.33968C9.14532 4.37767 8.98791 4.22012 8.98791 4.0259V2.50602ZM3.11094 14.0099C2.73769 14.0099 2.37979 13.8616 2.11597 13.5978C1.85205 13.3339 1.7037 12.9759 1.7037 12.6027V3.45585C1.7037 3.0826 1.85205 2.7247 2.11597 2.46076C2.37977 2.19683 2.73769 2.04862 3.11094 2.04862H8.28431V4.02577C8.28431 4.30565 8.39559 4.57411 8.59351 4.77203C8.79142 4.96994 9.0599 5.08111 9.33977 5.08111H11.5368L11.5543 8.67827C11.4417 8.66772 11.3275 8.66245 11.2131 8.66245C9.92321 8.6633 8.71939 9.31017 8.00702 10.3856C7.29463 11.4609 7.16826 12.8217 7.67058 14.0098L3.11094 14.0099ZM11.2024 15.6598C10.3695 15.6542 9.57281 15.3185 8.98719 14.7262C8.40153 14.1341 8.07467 13.3336 8.07834 12.5007C8.08215 11.6678 8.41601 10.8704 9.00691 10.2834C9.59794 9.6964 10.3976 9.36784 11.2305 9.36966C12.0633 9.3715 12.8615 9.70366 13.4498 10.2933C14.0381 10.8829 14.3685 11.6818 14.3686 12.5147C14.3676 13.3498 14.035 14.1502 13.4438 14.7401C12.8527 15.3299 12.0514 15.6608 11.2164 15.6598L11.2024 15.6598Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_8439_39061">
    <rect width="16" height="16" fill="white" transform="translate(0 0.857422)"/>
    </clipPath>
    </defs>
    </svg>
</template>
<script>
    export default {
        name: "removeFromListBoxIcon"
    }
</script>
<style scoped></style>