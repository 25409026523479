<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="me-2">
        <component :is="'sendEmailBoxIcon'"></component>
      </span>
      <span class="me-auto">Send Email</span>
      <button
        v-if="this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft"
        :disabled="['Draft','Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742" stroke="white" stroke-width="1.16667" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </span>
        Analytics
      </button>
      <button
          v-if="this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft"

        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p v-if="!subjectFieldValue" class="mb-2">Email is not configured. </p>
      <p v-if="subjectFieldValue" class="mb-2 bg-gray-2 p-10 radius-4 text-center break-word"> Send email <strong>"{{
        subjectFieldValue
      }}"</strong> </p>
      <nodeStatusDropdown :actionStatus="actionStatus" :onConfirm="onConfirmActionStatus" />
    </div>
    <teleport v-if="isLoaded" to="#teleportContainer">
      <div :class="nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'">
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1">
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">Send Email</h4>
          <kbutton :class="'p-0 hover-none-btn'" :fill-mode="'flat'" @click="onConfirmDrawerHandler">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A" />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <div class="mb-24">
              <p class="font-16 mb-8 base-color">From</p>
              <p class="font-16 mb-0 text-color break-word">{{ fromFieldValue || `Organisation <demo@gmail.com>` }}</p>
            </div>
            <div class="mb-24">
              <p class="font-16 mb-8 base-color">Subject</p>
              <p class="font-16 mb-0 text-color break-word">{{ subjectFieldValue || `Email Subject` }}</p>
            </div>
            <kbutton v-if="!selectedCampaignId" @click="sendEmailHandler('create')" class="mb-16 w-100 font-16">
              Configure Email Content</kbutton>
            <div class="d-flex justify-content-between">
              <kbutton v-if="selectedCampaignId" @click="sendEmailHandler('edit')" class="mb-16 font-16 w-100 mr-8">Edit
                Email</kbutton>
              <!-- <kbutton  v-if="selectedCampaignId" @click="previewEmailHandler" class="mb-16 font-16 w-50 ml-8">Preview Email</kbutton> -->
            </div>
            <hr class="mt-0 mb-16 border-color-1">
            <additionalFilter :onConfirm="onConfirmAdditionalFilter" :additionalFilterData="additionalFilterData" />
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <!-- <kbutton @click="drawerClose" class="ms-2">Cancel</kbutton> -->
            <kbutton type="primary" @click="onConfirmDrawerHandler" class="ms-2" theme-color="primary">Done</kbutton>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import CreateUpdateSegmentModal from "@/components/common/CreateUpdateSegmentModal/CreateUpdateSegmentModal.vue";
import additionalFilter from "@/components/workflowAutomation/common/additionalFilter.vue";
import { Popup } from "@progress/kendo-vue-popup";
import nodeStatusDropdown from "../../common/nodeStatusDropdown.vue";
import sendEmailBoxIcon from "@/components/svg-icons/sendEmailBoxIcon.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    CreateUpdateSegmentModal,
    additionalFilter,
    Popup,
    nodeStatusDropdown,
    sendEmailBoxIcon,
  },
  data() {
    return {
      selectedDropdownvalue: { id: 0, text: "Select List" },
      additionalFilterData: null,
      optionsModalVisibility: false,

      actionStatus: "Draft",
      fromFieldValue: "",
      subjectFieldValue: "",
      selectedCampaignId: null,
    };
  },
  methods: {
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    sendEmailHandler(type) {
      this.updateDataHandler();
      sessionStorage.setItem(
        this.flowInfoForSendEmail?.flowName,
        JSON.stringify({
          editorData: this.globalExport(),
          flowName: this.flowInfoForSendEmail?.flowName,
          flowType: this.flowInfoForSendEmail?.flowType,
          ...(this.$route.params.flowId && {
            flowId: this.$route.params.flowId,
          }),
          nodeId: this.generateNodeId(),
          accordions: {
            ...this.flowBuilderAccordions,
          },
        })
      );

      if (type === "create") {
        this.$router.push({
          name: "flowCreateCampaign",
          query: {
            nodeId: this.generateNodeId(),
            flow_name: this.flowInfoForSendEmail?.flowName,
            flow_type: this.flowInfoForSendEmail?.flowType,
            ...(this.$route.params.flowId && {
              flowId: this.$route.params.flowId,
            }),
            ...(this.$route.query.flowId && {
              flowId: this.$route.query.flowId,
            }),
          },
        });
      } else {
        this.$router.push({
          name: "flowEditCampaign",
          query: {
            nodeId: this.generateNodeId(),
            flow_name: this.flowInfoForSendEmail?.flowName,
            flow_type: this.flowInfoForSendEmail?.flowType,
            ...(this.$route.params.flowId && {
              flowId: this.$route.params.flowId,
            }),
            ...(this.$route.query.flowId && {
              flowId: this.$route.query.flowId,
            }),
          },
          params: {
            campaignId: this.selectedCampaignId,
          },
        });
      }
    },
    onConfirmActionStatus(data) {
      this.actionStatus = data;
      this.updateDataHandler();
    },
    onConfirmAdditionalFilter(data) {
      this.additionalFilterData = data;
      this.$store.commit("SET_SEGMENT_MODALOPENCLOSE", false);
    },
    listItemsModifier() {
      const dropdownlist = [{ id: 0, text: "Select List" }];
      for (const i in this.listData.items) {
        let object = {
          id: this.listData.items[i].id,
          text: this.listData.items[i].name,
        };
        dropdownlist.push(object);
      }
      return dropdownlist;
    },
    handledropdownSelection(e) {
      this.selectedDropdownvalue = e.value;
    },
    reloadToDefaultState() {
      this.selectedDropdownvalue = { id: 0, text: "Select Segment" };
    },
    drawerClose() {
      this.cancelClick();
      this.reloadToDefaultState();
    },
    onConfirmDrawerHandler() {
      this.updateDataHandler();
      this.drawerCloseHandler();
    },

    updateDataHandler() {
      this.updateNodeData({
        id: 1,
        selectedDropdownvalue: this.selectedDropdownvalue,
        additionalFilterData: this.additionalFilterData,
        actionStatus: this.actionStatus,
        fromFieldValue: this.fromFieldValue,
        subjectFieldValue: this.subjectFieldValue,
        selectedCampaignId: this.selectedCampaignId,
      });
    },

    beforeCloseHandler() {
      this.onConfirmDrawerHandler();
    },

    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        this.selectedDropdownvalue = nodeData.selectedDropdownvalue;
        this.additionalFilterData = nodeData.additionalFilterData;
        this.actionStatus = nodeData.actionStatus;
        this.fromFieldValue = nodeData.fromFieldValue;
        this.subjectFieldValue = nodeData.subjectFieldValue;
        this.selectedCampaignId = nodeData.selectedCampaignId;
      }
    },
  },
  computed: {
    listData() {
      return this.$store.state.list.lists;
    },
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
    flowBuilderAccordions() {
      return this.$store.state?.flowAutomation?.flowBuilderAccordions;
    },
    flowInfoForSendEmail() {
      return this.$store.state?.flowAutomation?.flowInfoForSendEmail;
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  mounted() {
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
      this.updateDataHandler();

      if (
        this.$route?.query?.isFromCampaign &&
        this.$route?.query?.nodeId == this.generateNodeId()
      ) {
        this.selectedCampaignId = this.$route.query.campaign_id;
        if (!this.$route.query?.isFromBackAction) {
          this.subjectFieldValue = this.$route.query.subject;
          this.fromFieldValue = this.$route.query.from;
        }
      }
    }, 200);

    if (localStorage.getItem("user")) {
      let userData = JSON.parse(localStorage.getItem("user"));
      this.fromFieldValue = userData.userProfileEmail;
    }
  },
  setup() {
    const isLoaded = ref(false);
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const route = useRoute();
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const globalExport = () => {
      return df.export();
    };

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    const onlyUpdateDataNode = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
    };

    onMounted(async () => {
      await nextTick();
      isLoaded.value = true;

      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);

      setTimeout(() => {
        // ;
        if (nodeId.value == route.query.nodeId) {
          store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
            drawerName: route.query.nodeId,
            drawerStatus: true,
          });
        }
      }, 150);
    });

    const generateNodeId = () => {
      return nodeId.value;
    };

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      isLoaded,
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      onlyUpdateDataNode,
      cancelClick,
      generateNodeData,
      generateElementData,
      generateNodeId,
      globalExport,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
