<template>
  <div ref="el" class="">
    <div
      class="box-header d-flex justify-content-between align-items-center pl-26 pr-15 radius-8"
    >
      <span class="me-2">
        <component :is="'exitBoxIcon'"></component>
      </span>
      <span class="me-auto">Exit</span>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import exitBoxIcon from "@/components/svg-icons/exitBoxIcon.vue";

export default {
  components: {
    kbutton: Button,
    exitBoxIcon,
  },
  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("rtl");

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateSelect = (value) => {
      dataNode.value.data.script = value;
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    return {
      el,
      drawer,
      direction,
      textarea,
      updateSelect,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
