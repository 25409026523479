<template>
  <div>
    <DropDownList
      :data-items="filteredData"
      :value="selectedValue"
      @change="onChange"
      :text-field="'label'"
      :data-item-key="'id'"
      :placeholder="placeholder"
      :group-field="'groupLabel'"
      :style="{}"
      :filterable="true"
      @filterchange="onFilterChange"
    />
  </div>
</template>

<script>
import { DropDownList } from "@progress/kendo-vue-dropdowns";

export default {
  name: "dropdownlistWithGroup",
  components: {
    DropDownList,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    className: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Object,
      default: {},
    },
    placeholder: {
      type: String,
      default: "Select an option",
    },
  },
  created() {
    this.groupedData = [];
    this.generateGroupedData();
  },
  watch: {
    options: {
      handler() {
        this.generateGroupedData();
      },
      deep: true,
    },
    modelValue: {
      handler() {
        this.generateGroupedData();
      },
      deep: true,
    },
  },
  data() {
    return {
      groupedData: [],
      selectedValue: null,
      filter: "",
    };
  },
  computed: {
    filteredData() {
      if (!this.filter) {
        return this.groupedData;
      }
      return this.groupedData.filter(item =>
        item.label.toLowerCase().includes(this.filter.toLowerCase())
      );
    },
  },
  methods: {
    generateGroupedData() {
      this.groupedData = [];
      this.options.map((option) => {
        if (option.label) {
          option.options.map((opt) => {
            opt.groupLabel = option.label;
            this.groupedData.push({ ...opt });
          });
        }
      });

      let item = this.groupedData.find((data) => data?.id == this.modelValue);
      if (item) {
        this.selectedValue = item;
      }
    },
    onChange(event) {
      let value = event.target.value;
      this.selectedValue = value;
      this.$emit("change", value?.id);
      this.$emit("update:modelValue", value?.id);
    },
    onFilterChange(event) {
      this.filter = event.filter.value;
    },
  },
};
</script>
