<template>
  <div class="line-height-input" :title="'Line Height'">
    <dropdownlist
      :class="'w-100-vsm'"
      :style="{ width: '150px' }"
      :data-items="lineHeightValues"
      :text-field="'text'"
      :data-item-key="'id'"
      :value="selectedeValue"
      @change="handleLineHeightValue"
    >
    </dropdownlist>
  </div>
</template>
<script>
import { EditorUtils } from "@progress/kendo-vue-editor";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
export default {
  components: {
    dropdownlist: DropDownList,
    "k-input": Input,
    NumericTextBox,
  },
  emits: {
    change: null,
  },
  props: {
    view: Object,
    currentHeight: Number,
  },
  watch: {
    // watch currentHeight value and set it to the editor
    currentHeight: {
      handler: function (val) {
        // );
        if (val) {
          if (val == "100%") {
            this.selectedeValue = {
              id: 0,
              text: "100%",
              value: "100%",
            };
          } else {
            this.selectedeValue = this.lineHeightValues.find(
              (x) => x.value === `${val}`
            ) || {
              id: 0,
              text: "Line Height",
              value: "100%",
            };
          }
        } else {
          this.selectedeValue = {
            id: 0,
            text: "Line Height",
            value: "100%",
          };
        }
      },
      immediate: false,
    },
  },
  data() {
    return {
      value: "",
      defaultValue: 100,
      selectedeValue: {
        id: 0,
        text: "Line Height",
        value: "100%",
      },
      lineHeightValues: [
        {
          id: 0,
          text: "Line Height",
          value: "100%",
        },
        {
          id: 1,
          text: "100%",
          value: "100%",
        },
        {
          id: 2,
          text: "110%",
          value: "110%",
        },
        {
          id: 3,
          text: "120%",
          value: "120%",
        },
        {
          id: 4,
          text: "130%",
          value: "130%",
        },
        {
          id: 5,
          text: "140%",
          value: "140%",
        },
        {
          id: 6,
          text: "150%",
          value: "150%",
        },
        {
          id: 7,
          text: "160%",
          value: "160%",
        },
        {
          id: 8,
          text: "170%",
          value: "170%",
        },
        {
          id: 9,
          text: "180%",
          value: "180%",
        },
        {
          id: 10,
          text: "190%",
          value: "190%",
        },
        {
          id: 11,
          text: "200%",
          value: "200%",
        },
      ],
    };
  },
  methods: {
    handleLineHeightValue(event) {
      this.selectedeValue = event.value;
      EditorUtils.applyInlineStyle(this.$props.view, {
        style: "line-height",
        value: `${event.value.value}`,
      });
    },
  },
  // mounted() {
  //     ;
  //     if (this.$props.currentHeight) {
  //         this.selectedeValue = this.lineHeightValues.find(x => x.value === `${this.$props.currentHeight}%`) || {
  //             id: 0,
  //             text: 'Line Height',
  //             value: '100%',
  //         };
  //     }
  // },
  computed: {
    fontWeightValue() {
      return this.$props.currentHeight
        ? this.$props.currentHeight
        : this.defaultValue;
    },
  },
};
</script>
