<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 3V1H15V3H9ZM11 14H13V8H11V14ZM12 22C10.7667 22 9.604 21.7627 8.512 21.288C7.42067 20.8127 6.46667 20.1667 5.65 19.35C4.83333 18.5333 4.18733 17.5793 3.712 16.488C3.23733 15.396 3 14.2333 3 13C3 11.7667 3.23733 10.604 3.712 9.512C4.18733 8.42067 4.83333 7.46667 5.65 6.65C6.46667 5.83333 7.42067 5.18767 8.512 4.713C9.604 4.23767 10.7667 4 12 4C13.0333 4 14.025 4.16667 14.975 4.5C15.925 4.83333 16.8167 5.31667 17.65 5.95L19.05 4.55L20.45 5.95L19.05 7.35C19.6833 8.18333 20.1667 9.075 20.5 10.025C20.8333 10.975 21 11.9667 21 13C21 14.2333 20.7627 15.396 20.288 16.488C19.8127 17.5793 19.1667 18.5333 18.35 19.35C17.5333 20.1667 16.5793 20.8127 15.488 21.288C14.396 21.7627 13.2333 22 12 22ZM12 20C13.9333 20 15.5833 19.3167 16.95 17.95C18.3167 16.5833 19 14.9333 19 13C19 11.0667 18.3167 9.41667 16.95 8.05C15.5833 6.68333 13.9333 6 12 6C10.0667 6 8.41667 6.68333 7.05 8.05C5.68333 9.41667 5 11.0667 5 13C5 14.9333 5.68333 16.5833 7.05 17.95C8.41667 19.3167 10.0667 20 12 20Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "waitTimeIcon"
    }
</script>
<style scoped></style>