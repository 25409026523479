<template>
  <h5 class="text-uppercase color-gray font-w-400 font-14 mb-6">
    Configure Conditional Split
  </h5>

  <div v-if="!additionalFilterData?.segmentId">
    <p class="mb-3 font-12 l-height-18 mb-16">
      Conditional Split Divide your flow into branches based on recipient
      characteristics or actions to personalize the user experience.
      <br /><br />
      Create a split and apply conditions to direct recipients to specific
      branches, such as directing those who meet certain engagement or purchase
      criteria to the "YES" branch.
    </p>
    <kbutton @click="openAdditionalFilter" class="mb-3 w-100 font-16"
      >Configure Conditional Split</kbutton
    >
  </div>

  <div
    v-if="additionalFilterData?.segmentId"
    class="additional-filters-dynamic"
  >
    <div class="p-3 border-normal-1 radius-4">
      <div class="d-flex justify-content-between align-items-center">
        <h5 class="font-16 font-w-400 m-0">Configure Split Data</h5>
        <!-- <kbutton :class="'p-0 btn-clear'" :icon="'more-vertical'"></kbutton> -->
        <AppPopUp
          :voucher="card"
          :items="moreOptions"
          @dropId="getDropId"
          :icon="'more-vertical'"
          :popUpSettings="popupSettings"
        >
        </AppPopUp>
      </div>
      <hr class="border-top-normal-1" />
      <p class="font-14 text-black-2">
        {{ additionalFilterData?.segmentName }}
      </p>
    </div>
  </div>

  <CreateUpdateSegmentModal
    :onConfirm="onConfirmSegmentModal"
    :onCancel="onCancelSegmentModal"
    :additionalFilterData="additionalFilterData"
    :typeOfAction="typeOfAction"
    :isFromFlowBuilder="true"
    v-if="additionalFilterModalVisibility"
  />
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import CreateUpdateSegmentModal from "@/components/common/CreateUpdateSegmentModal/CreateUpdateSegmentModal.vue";
import AppPopUp from "@/components/common/AppPopUp/AppPopUp.vue";

export default {
  components: {
    kbutton: Button,
    CreateUpdateSegmentModal,
    AppPopUp,
  },
  props: {
    onConfirm: {
      type: Function,
      default: () => {},
    },
    additionalFilterData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      additionalFilterModalVisibility: false,
      typeOfAction: "create",
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      moreOptions: [
        {
          dropId: 1,
          text: "Edit",
        },
        {
          dropId: 2,
          text: "Delete",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    onConfirmSegmentModal(data) {
      this.additionalFilterModalVisibility = false;
      this.onConfirm(data);
    },
    onCancelSegmentModal() {
      this.additionalFilterModalVisibility = false;
    },
    getDropId: function (e, item) {
      if (e == 1) {
        this.openAdditionalFilter();
      }
      if (e == 2) {
        this.onConfirm(null);
      }
    },
    openAdditionalFilter() {
      if (this.additionalFilterData?.segmentId) {
        this.typeOfAction = "update";
      } else {
        this.typeOfAction = "create";
      }
      setTimeout(() => {
        this.additionalFilterModalVisibility = true;
      }, 300);
    },
  },
  computed: {
    isModalOpenForCreateSegment() {
      return this.$store.state.segments.segmentModalOpenClose;
    },
  },
};
</script>
<style scoped></style>
