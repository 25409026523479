<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 18V16H21V18H9ZM9 13V11H21V13H9ZM3 8V6H21V8H3Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "enterSegmentIcon"
    }
</script>
<style scoped></style>