<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.23145 14.0859H7.92373V15.4706H7.23145V14.0859Z" fill="white"/>
    <path d="M9.37793 13.7971L10.0176 13.5322L10.5474 14.8116L9.90771 15.0764L9.37793 13.7971Z" fill="white"/>
    <path d="M11.25 12.7083L11.7396 12.2188L12.7186 13.1978L12.229 13.6874L11.25 12.7083Z" fill="white"/>
    <path d="M12.5625 10.9872L12.8276 10.3477L14.1067 10.8777L13.8416 11.5172L12.5625 10.9872Z" fill="white"/>
    <path d="M13.1162 8.20215H14.5009V8.89444H13.1162V8.20215Z" fill="white"/>
    <path d="M1.04883 10.8777L2.32792 10.3477L2.59299 10.9872L1.3139 11.5172L1.04883 10.8777Z" fill="white"/>
    <path d="M12.5625 6.10802L13.8418 5.57812L14.1068 6.21781L12.8275 6.7477L12.5625 6.10802Z" fill="white"/>
    <path d="M2.4375 13.1988L3.41653 12.2197L3.9061 12.7093L2.92707 13.6883L2.4375 13.1988Z" fill="white"/>
    <path d="M11.249 4.38614L12.2283 3.40723L12.7177 3.8968L11.7385 4.87571L11.249 4.38614Z" fill="white"/>
    <path d="M4.60742 14.8123L5.13744 13.5332L5.777 13.7983L5.24698 15.0774L4.60742 14.8123Z" fill="white"/>
    <path d="M9.37793 3.29789L9.90771 2.01855L10.5474 2.28339L10.0176 3.56273L9.37793 3.29789Z" fill="white"/>
    <path d="M1.69253 8.54768C1.69253 5.30402 4.33373 2.66299 7.57721 2.66299V1.9707C3.94932 1.9707 1 4.91996 1 8.54791L1.69253 8.54768Z" fill="white"/>
    <path d="M7.57748 8.2016H5.1543V8.89389H8.26977V3.35547H7.57748V8.2016Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        name: "removeFromListWaitBoxIcon"
    }
</script>
<style scoped></style>