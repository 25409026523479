<template>
  <DropDownList
    :data-items="copyMacros"
    @open="onDropdownOpenHandler"
    :text-field="'Text'"
    :data-item-key="'Id'"
    :value="macroDefaultValue"
    @change="onChange"
    :filterable="true"
    @filterchange="filterChange"
  />
</template>
<script>
import { EditorUtils } from "@progress/kendo-vue-editor";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

export default {
  inject: ["draggable_editor_data"],
  name: "macroDropdownComponent",
  components: {
    DropDownList,
  },
  props: {
    view: Object,
  },
  data() {
    return {
      macroDefaultValue: { Text: "Insert Tag", Value: "", Id: 0 },
      copyMacros: [],
    };
  },
  computed: {
    macros() {
      return this.$store.state.commonEmailCampaign
        .emailCampaignTemplateMacroLists;
    },
  },
  methods: {
    onDropdownOpenHandler() {
      this.copyMacros = structuredClone(this.macros);
    },
    filterChange(event) {
      this.copyMacros = this.filterData(event.filter);
      // this.$store.commit("SET_EMAIL_CAMPAIGN_TEMPLATE_MACRO_LISTS", this.filterData(event.filter));
    },
    filterData(filter) {
      const data = this.macros.slice();
      return filterBy(data, filter);
    },
    onChange(event) {
      if (this?.$props?.view && event?.event?.type === "click") {
        const state = this.$props.view.state;
        const { schema } = state;
        const { view } = this.$props;
        const textNode = schema.text(event?.value?.Value, []);
        EditorUtils?.insertNode(view, textNode);
      }
    },
  },
};
</script>
