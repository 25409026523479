<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 10C2 8.53333 2.296 7.146 2.888 5.838C3.47933 4.52933 4.325 3.4 5.425 2.45L6.85 3.85C5.95 4.63333 5.25 5.554 4.75 6.612C4.25 7.67067 4 8.8 4 10H2ZM20 10C20 8.8 19.75 7.67067 19.25 6.612C18.75 5.554 18.05 4.63333 17.15 3.85L18.575 2.45C19.675 3.4 20.521 4.52933 21.113 5.838C21.7043 7.146 22 8.53333 22 10H20ZM4 19V17H6V10C6 8.61667 6.41667 7.38733 7.25 6.312C8.08333 5.23733 9.16667 4.53333 10.5 4.2V3.5C10.5 3.08333 10.646 2.72933 10.938 2.438C11.2293 2.146 11.5833 2 12 2C12.4167 2 12.7707 2.146 13.062 2.438C13.354 2.72933 13.5 3.08333 13.5 3.5V4.2C14.8333 4.53333 15.9167 5.23733 16.75 6.312C17.5833 7.38733 18 8.61667 18 10V17H20V19H4ZM12 22C11.45 22 10.9793 21.8043 10.588 21.413C10.196 21.021 10 20.55 10 20H14C14 20.55 13.8043 21.021 13.413 21.413C13.021 21.8043 12.55 22 12 22ZM8 17H16V10C16 8.9 15.6083 7.95833 14.825 7.175C14.0417 6.39167 13.1 6 12 6C10.9 6 9.95833 6.39167 9.175 7.175C8.39167 7.95833 8 8.9 8 10V17Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "internalNotificationEmailIcon"
    }
</script>
<style scoped></style>