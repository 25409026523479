import { EditorToolsSettings } from "@progress/kendo-vue-editor";
import fontList from "./fontList";

const fontSizeToolSettings = {
  ...EditorToolsSettings.fontSize,
  items: [
    {
      text: "8px",
      value: "8px",
    },
    {
      text: "10px",
      value: "10px",
    },
    {
      text: "12px",
      value: "12px",
    },
    {
      text: "13px",
      value: "13px",
    },
    {
      text: "14px",
      value: "14px",
    },
    {
      text: "15px",
      value: "15px",
    },
    {
      text: "16px",
      value: "16px",
    },
    {
      text: "17px",
      value: "17px",
    },
    {
      text: "18px",
      value: "18px",
    },
    {
      text: "20px",
      value: "20px",
    },
    {
      text: "22px",
      value: "22px",
    },
    {
      text: "24px",
      value: "24px",
    },
    {
      text: "26px",
      value: "26px",
    },
    {
      text: "28px",
      value: "28px",
    },
    {
      text: "30px",
      value: "30px",
    },
    {
      text: "32px",
      value: "32px",
    },
    {
      text: "34px",
      value: "34px",
    },
    {
      text: "36px",
      value: "36px",
    },
    {
      text: "42px",
      value: "42px",
    },
    {
      text: "46px",
      value: "46px",
    },
    {
      text: "48px",
      value: "48px",
    },
    {
      text: "52px",
      value: "52px",
    },
    {
      text: "56px",
      value: "56px",
    },
    {
      text: "60px",
      value: "60px",
    },
    {
      text: "72px",
      value: "72px",
    },
    {
      text: "90px",
      value: "90px",
    },
  ],
};

const applyBackColorSettings = {
  ...EditorToolsSettings.backColor,
};

const applyForeColorSettings = {
  ...EditorToolsSettings.foreColor,
};
const applyFontNameTool = {
  ...EditorToolsSettings.fontName,
  items: [...fontList],
};

const kendoTools = [
  ["Bold", "Italic", "Underline"],
  ["Subscript", "Superscript"],
  { render: "MyForeColor", props: applyForeColorSettings },
  { render: "MyBackColor", props: applyBackColorSettings },
  ["AlignLeft", "AlignCenter", "AlignRight", "AlignJustify"],
  ["Indent", "Outdent"],
  { render: "MyFontSizeTool", props: fontSizeToolSettings },
  { render: "MyFontWeightTool", props: {} },
  { render: "MyLineHeightTool", props: {} },
  { render: "FontNameTool", props: applyFontNameTool },
  "FormatBlock",
  ["SelectAll"],
  ["Undo", "Redo"],
  ["Link", "Unlink"],
  ["OrderedList", "UnorderedList"],
];
export default kendoTools;
