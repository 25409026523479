<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'actionAlertBoxIcon'"></component>
      </span>
      <span class="me-auto">Action Alert</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p
        v-if="
          !actionAlertConfiguration.emailSendTo &&
          !actionAlertConfiguration.emailSubject
        "
        class="mb-2"
      >
        Send an internal notification to your team. Configure the recipients,
        subject, and message.
      </p>
      <p v-else class="mb-2 bg-gray-2 p-10 radius-4 text-center break-word">
        Configured to send a notification to
        <strong>
          {{ formattedRecipients().split(",").length }}
          {{
            formattedRecipients().split(",").length > 1
              ? "recipient(s)"
              : "recipient"
          }}
        </strong>
        with the subject: <strong>{{ emailSubject }}</strong>
      </p>
      <nodeStatusDropdown
        :actionStatus="actionStatus"
        :onConfirm="onConfirmActionStatus"
      />
    </div>

    <teleport to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            Action Alert
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <div class="mb-24">
              <p class="font-14 l-height-21 mb-20">
                Alert up to four individuals when this action occurs in the
                flow. Recipients will receive an email notification. Please
                input recipient emails separated by commas.
              </p>
              <div class="mb-16">
                <label :class="'mb-1 font-13 l-height-24 font-w-500'"
                  >Send to</label
                >
                <k-input
                  type="text"
                  :class="'mb-0 w-100 h-38'"
                  placeholder="Enter email"
                  v-model="actionAlertConfiguration.emailSendTo"
                ></k-input>
                <span class="error-msg">{{
                  validateErrorMsg?.emailSendTo
                }}</span>
                <span class="error-msg">{{
                  validateErrorMsg?.emailCount
                }}</span>
              </div>
              <div class="mb-16">
                <label :class="'mb-1 font-13 l-height-24 font-w-500'"
                  >Subject *</label
                >
                <k-input
                  type="text"
                  :class="'mb-0 w-100 h-38'"
                  placeholder="Internal alert #1 Subject"
                  v-model="actionAlertConfiguration.emailSubject"
                ></k-input>
                <span class="error-msg">{{
                  validateErrorMsg?.emailSubject
                }}</span>
              </div>
              <div>
                <TextEditorComponent
                  :defaultContent="actionAlertConfiguration.editorValue"
                  :ktools="ktools"
                  @change="onEditorChange"
                />
              </div>
            </div>
            <additionalFilter
              :onConfirm="onConfirmAdditionalFilter"
              :additionalFilterData="additionalFilterData"
            />
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <!-- <kbutton @click="drawerClose" class="ms-2">Cancel</kbutton> -->
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler(true)"
              class="ms-2"
              theme-color="primary"
              >Done
            </kbutton>
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Input } from "@progress/kendo-vue-inputs";
import CreateUpdateSegmentModal from "@/components/common/CreateUpdateSegmentModal/CreateUpdateSegmentModal.vue";
import additionalFilter from "@/components/workflowAutomation/common/additionalFilter.vue";
import { Popup } from "@progress/kendo-vue-popup";
import nodeStatusDropdown from "../../common/nodeStatusDropdown.vue";
import actionAlertBoxIcon from "@/components/svg-icons/actionAlertBoxIcon.vue";
import { filterBy } from "@progress/kendo-data-query";
import { useStore } from "vuex";
import NoDataRender from "@/components/common/NoDataRender/NoDataRender.vue";
import TextEditorComponent from "@/components/emailTemplateEditor/common-components/TextEditorComponent.vue";
import kendoTools from "@/components/emailTemplateEditor/helpers/kendoTools";
// import { Editor } from "@progress/kendo-vue-editor";
// import { onPasteHtmlIntoEditor } from "@/utils/helper";

export default {
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    "k-input": Input,
    CreateUpdateSegmentModal,
    additionalFilter,
    Popup,
    nodeStatusDropdown,
    actionAlertBoxIcon,
    custom: NoDataRender,
    // Editor,
    TextEditorComponent,
  },
  data() {
    return {
      listNoDataRender: "noDataFoundTemplate",
      additionalFilterData: null,
      optionsModalVisibility: false,

      actionStatus: "Draft",
      dropdownListData: [],
      actionAlertConfiguration: {
        editorValue: "",
        emailSendTo: "",
        emailSubject: "",
      },
      validateErrorMsg: {},
      emailCount: [],
      ktools: [...kendoTools, { render: "macroDropdownComponent" }],
    };
  },
  methods: {
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    onPasteHtml(e) {
      return onPasteHtmlIntoEditor(e);
    },
    onDropdownOpenHandler() {
      this.dropdownListData = this.listItemsModifier();
    },
    onConfirmActionStatus(data) {
      this.actionStatus = data;
      this.updateDataHandler();
    },
    onConfirmAdditionalFilter(data) {
      this.additionalFilterData = data;
      this.$store.commit("SET_SEGMENT_MODALOPENCLOSE", false);
    },
    listItemsModifier() {
      const dropdownlist = [{ id: 0, text: "Select List" }];
      if (this.listData.items.length === 0) return [];
      for (const i in this.listData.items) {
        let object = {
          id: this.listData.items[i].id,
          text: this.listData.items[i].name,
        };
        dropdownlist.push(object);
      }
      return dropdownlist;
    },
    reloadToDefaultState() {
      // this.additionalFilterData = null;
      // this.actionStatus = "Draft";
      // this.actionAlertConfiguration = {
      //   editorValue: "",
      //   emailSendTo: "",
      //   emailSubject: "",
      // };
    },
    drawerClose() {
      this.cancelClick();
      this.reloadToDefaultState();
    },
    validate() {
      const errors = {};
      this.validateErrorMsg = {};
      const fieldErrorMessages = {
        emailSendTo: "Recipient email is required.",
        emailSubject: "Email Subject is required.",
        emailCount: "Recipient email cannot exceed 6.",
      };

      const item_data = this.actionAlertConfiguration;
      const requiredFieldsStep = ["emailSendTo", "emailSubject"];

      requiredFieldsStep.forEach((field) => {
        if (!item_data[field]) {
          errors[field] = fieldErrorMessages[field];
        }
      });

      if (item_data.emailSendTo) {
        const emails = item_data.emailSendTo
          .split(",")
          .map((email) => email.trim())
          .filter((email) => email);
        if (emails.length > 6) {
          errors.emailCount = fieldErrorMessages.emailCount;
        }
      }

      this.validateErrorMsg = errors;
      return Object.keys(errors).length === 0;
    },
    onConfirmDrawerHandler(isFromDone = false) {
      // const isValid = isFromDone ? this.validate() : true;
      // if (isValid) {
      this.updateDataHandler();
      this.drawerCloseHandler();
      // }
    },
    updateDataHandler() {
      this.updateNodeData({
        id: 1,
        additionalFilterData: this.additionalFilterData,
        actionStatus: this.actionStatus,
        actionAlertConfiguration: this.actionAlertConfiguration,
      });
    },

    beforeCloseHandler() {
      this.onConfirmDrawerHandler();
    },

    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        this.additionalFilterData = nodeData.additionalFilterData;
        this.actionStatus = nodeData.actionStatus;
        this.actionAlertConfiguration = nodeData.actionAlertConfiguration;
      }
    },
    formattedRecipients() {
      const { emailSendTo } = this.actionAlertConfiguration;
      if (!emailSendTo) {
        return "No recipients specified";
      }

      const emails = emailSendTo
        .split(",")
        .map((email) => email.trim())
        .filter((email) => email);

      return emails.slice(0, 6).join(", ");
    },
    onEditorChange(content) {
      if (typeof content === "string") {
        this.actionAlertConfiguration.editorValue = content;
      } else {
        console.warn("Received invalid content from editor:", content);
      }
    },
  },
  computed: {
    listData() {
      return this.$store.state.list.lists;
    },

    emailSubject() {
      return this.actionAlertConfiguration?.emailSubject || "No Subject";
    },
    editorBody() {
      return this.actionAlertConfiguration?.editorValue || "";
    },
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
    formattedRecipientsCountMsg() {
      // return formattedRecipients().split(',').length > 1 ? 'recipient(s)' : 'recipient'
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  mounted() {
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
      this.onConfirmDrawerHandler();
    }, 100);
  },
  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    const generateNodeId = () => {
      return nodeId.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      generateNodeId,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
