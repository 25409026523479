<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.00018 19.9998V9.00004H7.67518V19.9998H4.00018ZM10.1748 19.9998V4.00024H13.8252V19.9998H10.1748ZM16.3248 19.9998V13.0002H19.9998V19.9998H16.3248Z" fill="#6C707D"/>
    </svg>
</template>
<script>
    export default {
        name: "metricsIcon"
    }
</script>
<style scoped></style>