<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.9083 4.05417C14.8063 3.87917 14.6604 3.74792 14.4854 3.64583C14.325 3.55833 14.1354 3.5 13.9313 3.5H1.66667C1.4625 3.5 1.2875 3.54375 1.1125 3.64583C0.9375 3.73333 0.791667 3.87917 0.689583 4.03958C0.558333 4.21458 0.5 4.43333 0.5 4.66667V12.8333C0.5 13.0521 0.558333 13.2562 0.660417 13.4312C0.7625 13.6062 0.89375 13.7375 1.06875 13.8396C1.24375 13.9417 1.44792 14 1.66667 14H13.9167C14.1354 14 14.3396 13.9417 14.5146 13.8396C14.6896 13.7375 14.8208 13.6062 14.9229 13.4312C15.025 13.2562 15.0833 13.0521 15.0833 12.8333V4.66667C15.0833 4.43333 15.025 4.22917 14.9083 4.05417ZM13.9167 4.08333C13.9313 4.08333 13.9458 4.08333 13.9604 4.08333L8.62292 8.75C8.24375 9.07083 7.61667 9.08542 7.22292 8.76458L1.65208 4.08333H13.9167ZM10.9417 9.45L10.5333 9.85833L14.0625 13.3875C14.0188 13.4021 13.9604 13.4167 13.9167 13.4167H1.66667C1.60833 13.4167 1.56458 13.4167 1.52083 13.3875L5.06458 9.85833L4.65625 9.45L1.1125 12.9792C1.08333 12.9354 1.08333 12.8917 1.08333 12.8333V4.66667C1.08333 4.57917 1.1125 4.49167 1.14167 4.41875L6.85833 9.21667C7.15 9.46458 7.55833 9.625 7.9375 9.625C8.33125 9.625 8.71042 9.45 9.01667 9.1875L14.4563 4.43333C14.4854 4.50625 14.5 4.57917 14.5 4.66667V12.8333C14.5 12.8917 14.5 12.9354 14.4708 12.9792L10.9417 9.45Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        name: "sendEmailBoxIcon"
    }
</script>
<style scoped></style>