<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6 ms-2">
        <component :is="'mergeBoxIcon'"></component>
      </span>
      <span class="me-auto">Merge</span>
    </div>
    <div class="box-content">
      <p>Recombine paths in your flow. Select the paths to merge into one.</p>
    </div>
  </div>
</template>

<script>
import mergeBoxIcon from "@/components/svg-icons/mergeBoxIcon.vue";

export default {
  components: {
    mergeBoxIcon,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
