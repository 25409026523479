<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8277_34160)">
    <path d="M11.9442 6.93087C11.8554 6.75322 11.6779 6.64669 11.4647 6.64669H8.72979L9.60004 0.999164C9.63559 0.750537 9.4935 0.501898 9.26258 0.4131C9.03166 0.324277 8.76531 0.4131 8.62323 0.626176L3.08227 9.29281C2.97573 9.45262 2.97573 9.66583 3.06455 9.84333C3.15338 10.021 3.3309 10.1275 3.52627 10.1275H6.20792L4.6983 15.704C4.62732 15.9526 4.75157 16.219 4.98248 16.3256C5.05347 16.3612 5.14229 16.3789 5.21339 16.3789C5.3732 16.3789 5.55086 16.3079 5.63965 16.1658L11.9088 7.48143C12.0153 7.32163 12.0331 7.10842 11.9443 6.93092L11.9442 6.93087ZM6.49206 13.1645L7.41559 9.71911C7.45114 9.5593 7.4333 9.38165 7.32676 9.25739C7.22023 9.13302 7.07814 9.04432 6.9005 9.04432H4.50304L8.16149 3.32576L7.57543 7.09077C7.55771 7.25058 7.59314 7.39267 7.69981 7.51703C7.80634 7.64141 7.94843 7.69468 8.10824 7.69468H10.4347L6.49206 13.1645Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_8277_34160">
    <rect width="16" height="16" fill="white" transform="translate(0 0.378906)"/>
    </clipPath>
    </defs>
    </svg>
</template>
<script>
    export default {
        name: "triggerBoxIcon"
    }
</script>
<style scoped></style>