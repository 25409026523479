<template>
  <div ref="el" class="">
    <div class="box-header d-flex justify-content-between align-items-center">
      <span class="mr-6">
        <component :is="'conditionalSplitBoxIcon'"></component>
      </span>
      <span class="me-auto">Conditional Split</span>
      <button
        v-if="
          this.$route.name == 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        :disabled="['Draft', 'Pause'].includes(actionStatus)"
        type="info"
        size="small"
        @click="
          () =>
            this.$flowAnalyticsModalHandler(true, this.generateElementData())
        "
        class="p-0 w-auto text-white border-0 h-28 px-8 radius-5"
      >
        <span class="me-1">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4163 4.35742L7.87467 9.89909L4.95801 6.98242L0.583008 11.3574M13.4163 4.35742H9.91634M13.4163 4.35742V7.85742"
              stroke="white"
              stroke-width="1.16667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        Analytics
      </button>
      <button
        v-if="
          this.$route.name != 'viewFlowDetails' && !this.$route.query?.isDraft
        "
        type="info"
        size="small"
        @click="openDrawerHandler"
        class="p-0 bg-transparent w-auto text-white border-0"
      >
        Edit
      </button>
    </div>
    <div class="box-content">
      <p v-if="!additionalFilterData?.segmentName" class="mb-2">
        Configure your split
      </p>
      <p v-if="additionalFilterData?.segmentName" class="mb-2">
        Split contacts based on segment
        <strong>{{ `${additionalFilterData?.segmentName}` }}.</strong>
      </p>
    </div>
    <div class="yes-no-conditional">
      <span class="yes">Yes</span>
      <span class="no">No</span>
    </div>
    <teleport to="#teleportContainer">
      <div
        :class="
          nodeDrawerStatus ? 'flow-option-sidebar show' : 'flow-option-sidebar'
        "
      >
        <div
          class="head h-45 d-flex justify-content-between align-items-center bg-gray-2 py-12 px-12 border-bottom-normal-1"
        >
          <h4 class="font-12 l-height-20 text-uppercase font-w-600 mb-0">
            Conditional Split
          </h4>
          <kbutton
            :class="'p-0 hover-none-btn'"
            :fill-mode="'flat'"
            @click="onConfirmDrawerHandler"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 4.91562L9.41563 8.5L13 12.0844L11.5844 13.5L8 9.91563L4.41562 13.5L3 12.0844L6.58437 8.5L3 4.91562L4.41562 3.5L8 7.08437L11.5844 3.5L13 4.91562Z"
                fill="#24243A"
              />
            </svg>
          </kbutton>
        </div>
        <div class="body overflow-y-auto p-15">
          <div>
            <configureSplitDataComponent
              :onConfirm="onConfirmSplitData"
              :additionalFilterData="additionalFilterData"
            />
          </div>
        </div>
        <div class="footer h-55 border-top-normal-1 p-8">
          <div class="d-flex justify-content-end">
            <kbutton
              type="primary"
              @click="onConfirmDrawerHandler"
              class="ms-2"
              theme-color="primary"
              >Done</kbutton
            >
          </div>
        </div>
      </div>
    </teleport>
  </div>
</template>

<script>
import { ref, getCurrentInstance, nextTick, onMounted, onUnmounted } from "vue";
import { Button } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Checkbox } from "@progress/kendo-vue-inputs";
import CreateUpdateSegmentModal from "@/components/common/CreateUpdateSegmentModal/CreateUpdateSegmentModal.vue";
import additionalFilter from "@/components/workflowAutomation/common/additionalFilter.vue";
import { Popup } from "@progress/kendo-vue-popup";
import nodeStatusDropdown from "../../common/nodeStatusDropdown.vue";
import conditionalSplitBoxIcon from "@/components/svg-icons/conditionalSplitBoxIcon.vue";
import configureSplitDataComponent from "../../common/configureSplitDataComponent.vue";
import { useStore } from "vuex";

export default {
  components: {
    kbutton: Button,
    dropdownlist: DropDownList,
    checkbox: Checkbox,
    CreateUpdateSegmentModal,
    additionalFilter,
    Popup,
    nodeStatusDropdown,
    conditionalSplitBoxIcon,
    configureSplitDataComponent,
  },
  data() {
    return {
      selectedDropdownvalue: { id: 0, text: "Select List" },
      additionalFilterData: null,
      optionsModalVisibility: false,
    };
  },
  methods: {
    openDrawerHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: true,
      });
    },
    drawerCloseHandler() {
      this.$store.commit("UPDATE_FLOW_AUTOMATION_DRAWER", {
        drawerName: this.nodeId,
        drawerStatus: false,
      });
    },
    onConfirmSplitData(data) {
      this.additionalFilterData = data;
      this.$store.commit("SET_SEGMENT_MODALOPENCLOSE", false);
    },
    onConfirmDrawerHandler() {
      this.updateDataHandler();
      this.drawerCloseHandler();
    },

    updateDataHandler() {
      this.updateNodeData({
        id: 1,
        selectedDropdownvalue: this.selectedDropdownvalue,
        additionalFilterData: this.additionalFilterData,
        actionStatus: this.actionStatus,
      });
    },

    beforeCloseHandler() {
      this.onConfirmDrawerHandler();
    },

    stateReloaderFunction(nodeData) {
      if (nodeData?.id) {
        this.additionalFilterData = nodeData.additionalFilterData;
      }
    },
  },
  computed: {
    listData() {
      return this.$store.state.list.lists;
    },
    nodeDrawerStatus() {
      return this.$store.state?.flowAutomation?.flowAutomationDrawers?.[
        this.nodeId
      ];
    },
  },
  watch: {
    nodeDrawerStatus: {
      handler: function (val) {
        const timer = setTimeout(() => {
          this.updateDataHandler();
          clearTimeout(timer);
        }, 2000);
      },
      immediate: false,
    },
  },
  mounted() {
    setTimeout(() => {
      const nodeData = this.generateNodeData();
      this.stateReloaderFunction(nodeData);
    }, 1000);
  },
  unmounted() {
    this.cancelClick();
  },
  setup() {
    const el = ref(null);
    const textarea = ref("");
    let df = null;
    const nodeId = ref(0);
    const dataNode = ref({});
    const drawer = ref(false);
    const direction = ref("ltr");
    let nodeData = {};
    const store = useStore();

    function cancelClick() {
      drawer.value = false;
    }

    const handleClose = (done) => {
      done();
    };

    df = getCurrentInstance().appContext.config.globalProperties.$df.value;

    const updateNodeData = (dataObject) => {
      dataNode.value.data = {
        ...dataObject,
      };
      df.updateNodeDataFromId(nodeId.value, dataNode.value);
      drawer.value = false;
    };

    onMounted(async () => {
      await nextTick();
      nodeId.value = el.value.parentElement.parentElement.id.slice(5);
      dataNode.value = df.getNodeFromId(nodeId.value);
      textarea.value = dataNode.value.data.script;
    });

    const generateNodeData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value?.data?.data;
    };

    const generateElementData = () => {
      nodeId.value = el?.value?.parentElement?.parentElement?.id?.slice?.(5);
      dataNode.value = df?.getNodeFromId(nodeId?.value);
      return dataNode.value;
    };

    store.commit("ADD_FLOW_AUTOMATION_DRAWER", {
      drawerName: nodeId.value,
      drawerStatus: false,
    });

    return {
      el,
      drawer,
      direction,
      handleClose,
      textarea,
      updateNodeData,
      cancelClick,
      generateNodeData,
      generateElementData,
      nodeId,
    };
  },
};
</script>
<style scoped>
p {
  margin: 5px;
  margin-bottom: 10px;
}
</style>
