<template>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 1.96973H4.5V15.9697H1.5V1.96973ZM6.5 9.96973V7.96973H10.5V4.96973L15.5 8.96973L10.5 12.9697V9.96973H6.5Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        name: "exitBoxIcon"
    }
</script>
<style scoped></style>