<template>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6 4.4707L1 7.4707V1.4707L6 4.4707ZM6 12.4707L1 9.4707V15.4707L6 12.4707ZM6 2.4707H15V3.4707H6V2.4707ZM15 5.4707H6V6.4707H15V5.4707ZM6 10.4707H15V11.4707H6V10.4707ZM15 13.4707H6V14.4707H15V13.4707Z" fill="white"/>
    </svg>
</template>
<script>
    export default {
        name: "conditionalSplitBoxIcon"
    }
</script>
<style scoped></style>